import { styled } from "styled-components";
export const TableViewBase = styled.div`
	min-width: 676px; // Prevent controls from splitting (forces horizontal scroll instead)
  padding: 0 33px 0 38px; // off center to balance the Sidebar shadow
  width: 100%;
`;
export const FiltersBarRow = styled.div<{
  $marginTop?: number;
}>`
  display: flex;
  justify-content: end;
  margin: ${props => props.$marginTop ?? 25}px 0 13px 0;
  padding: 0 20px;
  width: 100%;
`;