import { colors } from "@cerebruminc/cerebellum";
import { OrderScoreEnum } from "src/generated/graphql";

// These are the real filters for the app (getting consumed by the working app), but should be configged by organization eventually
export const adjudicationOptions = [{
  id: OrderScoreEnum.NoAlerts,
  label: "No Alerts",
  value: OrderScoreEnum.NoAlerts,
  color: colors.AQUA_100
}, {
  id: OrderScoreEnum.AlertsFound,
  label: "Alerts Found",
  value: OrderScoreEnum.AlertsFound,
  color: colors.ORANGE_25
}, {
  id: OrderScoreEnum.Withdrawn,
  label: "Withdrawn",
  value: OrderScoreEnum.Withdrawn,
  color: colors.BLUE_100
}, {
  id: OrderScoreEnum.OnHold,
  label: "On Hold",
  value: OrderScoreEnum.OnHold,
  color: colors.YELLOW_50
}, {
  id: OrderScoreEnum.Pending,
  label: "Pending",
  value: OrderScoreEnum.Pending,
  color: colors.COOL_GREY_65
}, {
  id: OrderScoreEnum.AdverseAction,
  label: "Adverse Action",
  value: OrderScoreEnum.AdverseAction,
  color: colors.RED_100
}];