import { FormFieldTypeEnum, SingleOrMultipleFieldType } from "@cerebruminc/cerebellum";
import * as yup from "yup";
import dayjs from "dayjs";

import { getAddressFields } from "./getAddressFields";
import { getSexData } from "./getSexData";
import { DocumentTypeEnum, ScanResult } from "./../types";

const extractCountryName = (countryCode: string): string => {
  const cleanCountryCode = countryCode.replace("COUNTRY_", "");
  return cleanCountryCode.replace(/_/g, " ");
};

export const getManualIdFields = ({
  userData,
  width,
  alternateId,
}: {
  userData: ScanResult | null;
  width: number;
  alternateId?: boolean;
}): Array<SingleOrMultipleFieldType> => {
  const documentNumber = userData?.number || userData?.passportNumber || "";
  const isPassportBook = userData?.documentType === DocumentTypeEnum.PASSPORT;
  const isPassportCard = userData?.documentType === DocumentTypeEnum.PASSPORT_CARD;
  let fields: Array<SingleOrMultipleFieldType> = [
    {
      header: "ID Card Information",
      type: FormFieldTypeEnum.SectionHeader,
      separator: true,
      separatorPosition: "bottom",
      separatorMarginString: "-10px 0 0 0",
      separatorOverflow: 127,
      nextGap: 28,
      themeOverride: {
        sectionHeaderFontSize: 22,
        sectionHeaderFontWeight: 700,
      },
    },
    {
      fieldLabel: "ID Card Number",
      initialValue: documentNumber,
      name: "number",
      type: FormFieldTypeEnum.Text,
      disabled: Boolean((isPassportBook || isPassportCard) && documentNumber),
      required: true,
      nextGap: 10,
    },
    {
      type: FormFieldTypeEnum.SplitLine,
      inlineFields: [
        {
          fieldLabel: "Issue Date",
          initialValue: userData?.issueDate || "",
          name: "issueDate",
          splitMinWidth: 185,
          type: FormFieldTypeEnum.DatePicker,
          disabled: Boolean((isPassportBook || isPassportCard) && userData?.issueDate),
          required: true,
          nextGap: 10,
          validationFunction: yup
            .date()
            .transform((value, originalValue, context) => {
              const dateAlreadyParsed = context.isType(value);
              if (dateAlreadyParsed) {
                return value;
              }
              const newDate = new Date(originalValue * 1000);
              return newDate;
            })
            .required("Please enter a valid date"),
          options: {
            dateFormat: "MM / dd / yyyy",
            yearOptionBegin: 1900,
            yearOptionEnd: Number(dayjs().format("YYYY")),
            placeholder: "MM / DD / YYYY",
            showIcon: false,
            noPicker: true,
          },
        },
        {
          fieldLabel: "Expiration Date",
          initialValue: userData?.expiryDate || "",
          name: "expiryDate",
          splitMinWidth: 185,
          type: FormFieldTypeEnum.DatePicker,
          disabled: Boolean((isPassportBook || isPassportCard) && userData?.expiryDate),
          required: true,
          nextGap: 80,
          validationFunction: yup
            .date()
            .transform((value, originalValue, context) => {
              const dateAlreadyParsed = context.isType(value);
              if (dateAlreadyParsed) {
                return value;
              }
              const newDate = new Date(originalValue * 1000);
              return newDate;
            })
            .required("Please enter a valid date"),
          options: {
            dateFormat: "MM / dd / yyyy",
            yearOptionBegin: 2000,
            yearOptionEnd: Number(dayjs().format("YYYY")) + 50,
            placeholder: "MM / DD / YYYY",
            showIcon: false,
            noPicker: true,
          },
        },
      ],
    },
    {
      header: "Identity Information",
      type: FormFieldTypeEnum.SectionHeader,
      separator: true,
      separatorPosition: "bottom",
      separatorMarginString: "-10px 0 0 0",
      separatorOverflow: 127,
      nextGap: 28,
      themeOverride: {
        sectionHeaderFontSize: 22,
        sectionHeaderFontWeight: 700,
      },
    },
    {
      fieldLabel: "First Name",
      initialValue: userData?.name?.firstName || "",
      name: "firstName",
      type: FormFieldTypeEnum.Text,
      nextGap: 10,
      disabled: Boolean((isPassportBook || isPassportCard) && userData?.name?.firstName),
      required: true,
    },
    {
      fieldLabel: "Middle Name",
      initialValue: userData?.name?.middleName || "",
      name: "middleName",
      nextGap: 10,
      type: FormFieldTypeEnum.Text,
      disabled: Boolean((isPassportBook || isPassportCard) && userData?.name?.middleName),
    },
    {
      fieldLabel: "Last Name",
      initialValue: userData?.name?.lastName || "",
      name: "lastName",
      nextGap: 10,
      type: FormFieldTypeEnum.Text,
      disabled: Boolean((isPassportBook || isPassportCard) && userData?.name?.lastName),
      required: true,
    },
    {
      fieldLabel: "Date of Birth",
      initialValue: userData?.dateOfBirth || "",
      name: "dateOfBirth",
      nextGap: 10,
      type: FormFieldTypeEnum.DatePicker,
      disabled: Boolean((isPassportBook || isPassportCard) && userData?.dateOfBirth),
      required: true,
      validationFunction: yup
        .date()
        .transform((value, originalValue, context) => {
          const dateAlreadyParsed = context.isType(value);
          if (dateAlreadyParsed) {
            return value;
          }
          const newDate = new Date(originalValue * 1000);
          return newDate;
        })
        .test("beforeToday", "Date must be before today", (value, testContext) => {
          // This is the only way to solve this, but Formik doesn't export this type
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { originalValue } = testContext;
          const isDateString = typeof originalValue === "string";
          const dateInSeconds = dayjs(originalValue).valueOf() * (isDateString ? 0 : 1000);
          const nowInSeconds = dayjs().valueOf();
          return dateInSeconds < nowInSeconds;
        })
        .required("Please enter a valid birth date"),
      options: {
        dateFormat: "MM / dd / yyyy",
        yearOptionBegin: 1900,
        yearOptionEnd: Number(dayjs().format("YYYY")),
        placeholder: "MM / DD / YYYY",
        showIcon: false,
        noPicker: true,
      },
    },
  ];

  if (alternateId || isPassportBook || isPassportCard) {
    // TODO: this can be replaced with a Country selecto
    fields.push({
      fieldLabel: "Place of birth",
      initialValue: userData?.placeOfBirth ? extractCountryName(userData.placeOfBirth) : "",
      name: "placeOfBirth",
      type: FormFieldTypeEnum.Text,
      disabled: !!userData?.placeOfBirth,
      required: true,
      nextGap: 10,
    });
    fields.push({
      fieldLabel: "Nationality",
      initialValue: userData?.nationality || "",
      name: "nationality",
      type: FormFieldTypeEnum.Text,
      disabled: !!userData?.nationality,
      required: true,
      nextGap: 10,
    });
  }

  fields.push({
    fieldLabel: "Gender",
    initialValue: getSexData(userData?.gender),
    name: "gender",
    type: FormFieldTypeEnum.RadioGroup,
    disabled: Boolean((isPassportBook || isPassportCard) && userData?.gender),
    required: true,
    options: {
      radios: [
        { value: "M", label: "Male" },
        { value: "F", label: "Female" },
      ],
    },
  });

  fields = [...fields, ...getAddressFields(userData)];
  return fields;
};
