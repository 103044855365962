import { UiNode } from "@ory/kratos-client";
import { GroupType } from "./types";

/**
 * function to filter nodes based on given group
 * @param nodes this will be all nodes returned from api
 * @param group group for which we want to display nodes
 * @returns filtered nodes
 */
export const getGroupNodes = (nodes: UiNode[] = [], group?: GroupType) => {
  if (!group) return nodes;
  return nodes.filter((node) => node.group === group || node.group === "default");
};
