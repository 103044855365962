import * as React from "react";
const VidLogo = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>{"B7B048E5-417B-4E16-B279-7A602ADC9DBA"}</title>
    <defs>
      <linearGradient id="a" x1="-12%" x2="118.169%" y1="47.219%" y2="53.368%">
        <stop offset=".421%" stopColor="#F9A18E" />
        <stop offset="50.18%" stopColor="#A48AFF" />
        <stop offset="100%" stopColor="#61E9FE" />
      </linearGradient>
    </defs>
    <path fill="url(#a)" fillRule="nonzero" d="M52.106 0 27.228 33.862H34L59.154 0h-7.048ZM87.35 4.976C83.618 1.796 78.504 0 73.252 0h-6.081L42.016 33.862h31.236c11.057 0 20.04-7.602 20.04-16.862-.138-4.561-2.21-8.846-5.942-12.024Zm-14.098 23.91-20.317-.276L70.488 5.114h2.764c3.732 0 7.325 1.244 9.951 3.455C85.83 10.78 87.35 13.821 87.35 17c0 6.496-6.358 11.886-14.098 11.886ZM37.593 0 15.894 28.886 7.05 17.553H0L12.577 34h6.496l25.57-34h-7.05Z" />
  </svg>;
export default VidLogo;