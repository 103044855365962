import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";

const useGetUploadUrl = () => {
  return useMutation({
    mutationKey: ["get-upload-signed-url"],
    async mutationFn(fileId: string) {
      const res = await neuronSdk.createUploadSignedUrl(fileId);
      return res.data;
    },
  });
};

export const useUploadLogo = () => {
  const { mutateAsync: getUploadUrl } = useGetUploadUrl();

  const uploadFile = async ({
    fileId,
    data,
  }: {
    fileId: string;
    data: File;
  }) => {
    const res = await getUploadUrl(fileId);
    const url = res?.url;

    await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };

  return useMutation({
    mutationKey: ["get-upload-signed-url"],
    mutationFn: uploadFile,
  });
};
