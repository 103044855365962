import { ButtonColorFamilyEnum, ConfirmModal, HeadingH2 } from "@cerebruminc/cerebellum";
import { useAtomValue, useSetAtom } from "jotai";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { acceptInviteModalVisibleAtom, noOrgDataModalVisibleAtom } from "../state";
function NoOrgDataModal() {
  const visible = useAtomValue(noOrgDataModalVisibleAtom);
  const setInviteModalVisible = useSetAtom(acceptInviteModalVisibleAtom);
  const router = useRouter();

  // Hide all other modals, when No-Org data modal is visible
  useEffect(() => {
    if (visible) {
      setInviteModalVisible(false);
    }
  }, [visible, setInviteModalVisible]);
  return <ConfirmModal themeOverride={{
    headerFontSize: 24,
    modalWidth: 525,
    modalPaddingSide: 65,
    modalPaddingTop: 72,
    modalPaddingBottom: 68
  }} show={visible} onSecondaryClick={() => {
    router.push("mailto:support@cerebrum.com");
  }} onPrimaryClick={() => {
    window.location.reload();
  }} primaryButtonText="Try Again" secondaryButtonText="Contact Us" secondaryAsText={true} secondaryColorFamily={ButtonColorFamilyEnum.Blue} header={<HeadingH2 $lineHeight={30} as="div">
          We&apos;re sorry!
        </HeadingH2>} text={"We are unable to load this organization at this time."} />;
}
export default NoOrgDataModal;