import { ConnotationColorEnum, DialogHandler, SnackTypeEnum } from "@cerebruminc/cerebellum";

/**
 * method to show success toast message
 * @param text message string
 */
export const showSuccess = (text: string) => {
  DialogHandler.show({
    id: `success-toast-${new Date().getTime()}`,
    colorFamily: ConnotationColorEnum.Positive,
    text,
    snackType: SnackTypeEnum.Toast,
  });
};
