import { Scroller, colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import { modalHeight } from "./FiltersModal";
import type { ButtonGroupProps, SleeveProps, TabContentProps, TabFormProps } from "./types";
export const TAB_PADDING_LEFT = 33;
export const TAB_PADDING_RIGHT = 33;
export const TAB_PADDING_BOTTOM = 105;
const horizontalMenuBreakpoint = 600;
export const TabForm = styled.div<TabFormProps>`
  height: ${({
  $menuHeight
}) => $menuHeight || "100%"};
  max-width: 100%;
  min-height: ${({
  $minHeight
}) => $minHeight || 400}px;
  padding-bottom: ${TAB_PADDING_BOTTOM}px;
  padding-top: ${({
  $horizontalMenu
}) => $horizontalMenu ? 0 : 50}px;
  padding-left: ${TAB_PADDING_LEFT}px;
  padding-right: ${TAB_PADDING_RIGHT}px;
  position: relative;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 6px;
`;
export const HeaderButton = styled.div`
  margin-right: -3px;
`;
export const TabContent = styled(Scroller)<TabContentProps>`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 0 5px;
  max-height: ${({
  $menuHeight
}) => $menuHeight || "100%"};
  width: calc(100% + ${TAB_PADDING_RIGHT}px);
`;
export const ButtonGroup = styled.div<ButtonGroupProps>`
  background-color: ${colors.WHITE};
  bottom: ${({
  $outsideSleeve
}) => $outsideSleeve ? -10 : 0}px;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  left: ${({
  $horizontalMenu
}) => $horizontalMenu ? 0 : "auto"};
  margin-left: ${({
  $horizontalMenu,
  $outsideSleeve
}) => $horizontalMenu || $outsideSleeve ? 5 : -28}px;
  padding: 30px 30px ${({
  $horizontalMenu
}) => $horizontalMenu ? 0 : 34}px 20px;
  position: absolute;
  right: ${({
  $horizontalMenu
}) => $horizontalMenu ? 0 : "auto"};
  text-align: right;
  width: 296px;
  z-index: 1;
  &:after {
    content: "";
    border-top: ${`solid 1px ${colors.COOL_GREY_20}`};
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 0px;
  }
  @media all and (max-width: ${horizontalMenuBreakpoint}px) {
    width: 100%;
  }
`;
export const ButtonBox = styled.div`
  margin: auto 0;
`;

// --- Dates only ----
export const TabParent = styled.div`
  padding-bottom: ${TAB_PADDING_BOTTOM}px;
  position: relative;
  @media all and (max-width: ${horizontalMenuBreakpoint}px) {
    padding-bottom: 41px;
  }
`;
export const Sleeve = styled(Scroller)<SleeveProps>`
  max-height: ${({
  $horizontalMenu
}) => `calc(${modalHeight}px - ${$horizontalMenu ? 0 : TAB_PADDING_BOTTOM}px)`};
  overflow: auto;
`;
export const DateSpacer = styled.div`
  height: 3px;
`;
export const CustomDates = styled.div`
  padding: 15px 5px 20px 35px;
  @media all and (max-width: ${horizontalMenuBreakpoint}px) {
    padding: 15px 0 50px 35px;
  }
`;