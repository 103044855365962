import { useAtom, useSetAtom } from "jotai";
import { deepCloneObject } from "src/helpers";
import { draftFromDateAtom, draftToDateAtom, filterModalFiltersAtom } from "./../atom";
import type { CallClearTabFiltersType } from "./../types";

export const useClearTabFilters = () => {
  const [filterModalFilters, setFilterModalFilters] = useAtom(filterModalFiltersAtom);
  const setDraftFromDate = useSetAtom(draftFromDateAtom);
  const setDraftToDate = useSetAtom(draftToDateAtom);

  function callClearTabFilter({ tabId }: CallClearTabFiltersType) {
    const clonedFilterModalFilters = deepCloneObject(filterModalFilters);
    if (clonedFilterModalFilters[tabId as keyof typeof filterModalFilters] === undefined) return;
    delete clonedFilterModalFilters[tabId as keyof typeof filterModalFilters];

    setFilterModalFilters(clonedFilterModalFilters);
    setDraftFromDate(undefined);
    setDraftToDate(undefined);
  }

  return callClearTabFilter;
};
