import { CreateOrgRelationDto } from "@cerebruminc/neuron-sdk";
import { useMutation } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";

export const useCreateOrganizationRelationMutation = () => {
  return useMutation({
    async mutationFn(data: CreateOrgRelationDto) {
      const res = await neuronSdk.createOrganizationRelation(data);
      return res.data;
    },
  });
};

export const useDeleteOrganizationRelationMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const res = await neuronSdk.deleteOrganizationRelation(id);
      return res.data;
    },
  });
};
