import { useCallback, useMemo } from "react";
import { validateReturnToUrl } from "./ory";
import { atom, useAtom } from "jotai";

const returnToAtom = atom("");

// Create a custom hook
export const useReturnToStore = () => {
  const [state, setState] = useAtom(returnToAtom);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const setReturnTo = useCallback((url: string) => {
    if (validateReturnToUrl(url)) {
      setState(url);
    }
  }, []);

  return useMemo(() => {
    return {
      returnTo: state,
      setReturnTo,
    };
  }, [state, setReturnTo]);
};
