interface CookieOptions {
  deleteCookie?: boolean;
  name: string;
  sameSite?: "Lax" | "Strict" | "None";
  seconds?: number;
  secure?: boolean;
  value: string;
}

// if you don't supply a `seconds` parameter, the cookie will be a session cookie, and expire when the user closes their browser.
export function setCookie({
  name,
  value,
  seconds,
  sameSite = "Lax",
  secure = false,
  deleteCookie = false,
}: CookieOptions): void {
  let cookieString = `${name}=${value}; path=/; domain=.cerebrum.com`;

  if (deleteCookie) {
    cookieString += `; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  } else if (seconds) {
    const expires = new Date();
    expires.setTime(expires.getTime() + seconds * 1000);
    cookieString += `; expires=${expires.toUTCString()}`;
  }

  cookieString += `; SameSite=${sameSite}`;

  if (secure) {
    cookieString += "; Secure";
  }

  document.cookie = cookieString;
}

export function getCookie(cookieName: string): string | null {
  // Prefixes the document.cookie string with a semicolon and a space for easier parsing.
  const value = `; ${document.cookie}`;

  // Splits into 2 parts if the cookie is found.
  const parts = value.split(`; ${cookieName}=`);
  const cookieExists = parts.length === 2;

  if (cookieExists) {
    const cookiePart = parts.pop();
    if (cookiePart) {
      const cookieValue = cookiePart.split(";").shift();
      return cookieValue !== undefined ? cookieValue : null;
    }
  }

  return null;
}
