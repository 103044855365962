import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { RoleEnum, roleData } from "src/const";
import { TextVariantEnum, Typography } from "@cerebruminc/cerebellum";
import React from "react";
dayjs.extend(duration);
export const roleDescriptionLib = (role: string) => {
  switch (role) {
    case RoleEnum.SuperAdmin:
      return roleData.superAdmin.description;
    case RoleEnum.OrganizationAdmin:
      return roleData.admin.description;
    case RoleEnum.User:
      return roleData.user.description;
    case RoleEnum.IntegrationAdmin:
      return roleData.integrationAdmin.description;
    case RoleEnum.Processor:
      return roleData.processor.description;
    default:
      return role;
  }
};
export const rolePrettyNameLib = (role: string) => {
  switch (role) {
    case RoleEnum.SuperAdmin:
      return roleData.superAdmin.name;
    case RoleEnum.OrganizationAdmin:
      return roleData.admin.name;
    case RoleEnum.User:
      return roleData.user.name;
    case RoleEnum.IntegrationAdmin:
      return roleData.integrationAdmin.name;
    case RoleEnum.Processor:
      return roleData.processor.name;
    default:
      return role;
  }
};

// NOTE: this was copied from Cognition - we may want to move this to a shared library
const timeSinceThen = (date: string) => {
  const then = dayjs(date);
  const now = dayjs();
  const daysAgo = dayjs.duration(now.diff(then)).asDays();
  if (daysAgo > 2) {
    const flatDays = Math.floor(daysAgo);
    return `${flatDays} days ago`;
  }
  if (daysAgo > 1) {
    return "Yesterday";
  }
  return "Today";
};
export const formatInviteString = (lastInviteSentAt: string): string => {
  return !lastInviteSentAt ? "Invited" : `Invited ${timeSinceThen(lastInviteSentAt)}`;
};
export const getArticle = (word?: string) => {
  if (!word) {
    return "";
  }
  const firstIsVowel = /^[aeiouyAEIOUY]/.test(word);
  const isIrregular = /^[uhxUHX]/.test(word);
  const article = firstIsVowel === isIrregular ? "a" : "an";
  return article;
};
export const roleChangeHeader = "Are you sure you want to change this user’s role?";
export const roleChangeDescription = (userName?: string, newRole?: string) => {
  return <Typography tag="span" variant={TextVariantEnum.BodyMPrimary}>
      You are about to change{" "}
      <Typography tag="span" variant={TextVariantEnum.BodyMEmphasis}>
        {userName}
      </Typography>{" "}
      to {getArticle(newRole)}{" "}
      <Typography tag="span" variant={TextVariantEnum.BodyMEmphasis}>
        {newRole}
      </Typography>
    </Typography>;
};