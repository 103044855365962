import { useSetAtom } from "jotai";
import router from "next/router";
import { Dispatch, ReactNode, SetStateAction, useCallback } from "react";
import { CheckVoucherValidityQuery, GetInviteQuery } from "src/generated/graphql";
import {
  acceptInviteModalVisibleAtom,
  noInvitationModalVisibleAtom,
  processingVoucherAtom,
  vidVoucherAtom,
  voucherIdAtom,
} from "./../../state";
import { analytics } from "src/helpers";
import { paths } from "src/const";

interface UseVerifyInviteAndEmail {
  emailVerified: boolean;
  errorValidatingInvite: unknown;
  inviteValidity?: GetInviteQuery;
  isValidatingInvite: boolean;
  setHelperText: Dispatch<SetStateAction<ReactNode>>;
  voucherId: string;
  voucherValidity?: CheckVoucherValidityQuery;
}

export const useVerifyInviteAndEmail = ({
  emailVerified,
  errorValidatingInvite,
  inviteValidity,
  isValidatingInvite,
  setHelperText,
  voucherId,
  voucherValidity,
}: UseVerifyInviteAndEmail) => {
  const setVoucherId = useSetAtom(voucherIdAtom);
  const setNoInvitationModalVisible = useSetAtom(noInvitationModalVisibleAtom);
  const setProcessingVoucher = useSetAtom(processingVoucherAtom);
  const setVIDVoucher = useSetAtom(vidVoucherAtom);
  const setModalVisible = useSetAtom(acceptInviteModalVisibleAtom);

  const verifyInviteAndEmail = useCallback(async () => {
    const readyToVerifyVoucher = emailVerified && voucherId;
    if (readyToVerifyVoucher) {
      setVoucherId(voucherId as string);
      setNoInvitationModalVisible(false);

      try {
        if (isValidatingInvite) {
          return;
        }
        setProcessingVoucher(false);

        const vIDVoucher = voucherValidity?.checkVoucherValidity;

        if (!vIDVoucher) {
          throw new Error("Voucher not found");
        }
        // The types have been updated to require packageSet, which we don't use on the front end
        setVIDVoucher(vIDVoucher as any);

        // API does not return inviteId if the user is already in an organization
        const userAlreadyInOrganization = !vIDVoucher.inviteId;
        if (userAlreadyInOrganization) {
          setModalVisible(false);
        }

        const redeemedAt = inviteValidity?.getInvite?.redeemedAt;
        const redeemedById = inviteValidity?.getInvite?.redeemedAt;
        const isInviteRedeemed = Boolean(redeemedAt && redeemedById);

        // Voucher scan analytics
        const analyticsData = {
          voucherId,
          packageId: vIDVoucher.packageSetId,
          passTypeId: vIDVoucher.passTypeId,
          inviteId: vIDVoucher.inviteId,
          organizationId: vIDVoucher.organizationId,
        };
        analytics.track("Voucher Scan", analyticsData);
        analytics.registerSuperProperties(analyticsData);

        if (isInviteRedeemed || errorValidatingInvite) {
          return null;
        }
        setModalVisible(true);
      } catch (error: unknown) {
        console.error("Failed to verify invite and email");
        console.error(error);
        if (error instanceof Error) {
          if (error?.message === "voucher is already redeemed") {
            router.push(`${paths.vidSuccess}?voucherRedeem=true`);
            return null;
          }
          if (error?.message === "Record does not exist") {
            setHelperText("Voucher not found. Please contact your organization administrator.");
          }
        } else {
          // I'm not sure how helpful this error message is to the user
          setHelperText("Invite not found: make sure that emails match and that the voucher exists");
        }

        setModalVisible(true);
        return null;
      }
    } else if (router.isReady && !voucherId) {
      setNoInvitationModalVisible(true);
    }
  }, [
    emailVerified,
    errorValidatingInvite,
    inviteValidity,
    isValidatingInvite,
    setHelperText,
    setModalVisible,
    setNoInvitationModalVisible,
    setProcessingVoucher,
    setVIDVoucher,
    setVoucherId,
    voucherId,
    voucherValidity,
  ]);

  return verifyInviteAndEmail;
};
