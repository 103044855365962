import { BodyMEmphasis, BodySTertiary, PrimaryButton, TextButton, Toggle } from "@cerebruminc/cerebellum";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { UiNode, UiNodeImageAttributes, UiNodeInputAttributes, UiNodeTextAttributes } from "@ory/kratos-client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import { Fragment, RefObject, useCallback } from "react";
import { type Control, Controller } from "react-hook-form";
import Turnstile, { BoundTurnstileObject } from "react-turnstile";
import styled from "styled-components";
import { FormInput, PolicyAcceptanceCheckbox } from "src/components";
import { ENABLE_2FA_TOGGLE, ORY_UI_ELEMENTS, TOS_NODE_TYPE, paths } from "src/const";
import { redirectAndPreserveParams } from "./../helpers";
import { getMappedMessage } from "./../utils";
import { inputLabelMapping } from "./const";
import { getGroupNodes } from "./getGroupNodes";
import { GroupType } from "./types";
import { analytics } from "src/helpers";
const CaptchaSpacer = styled.div`
  margin: 20px auto 40px;
	text-align: center;
	& > div {
		display: inline-block;
	}
`;
const TextButtonBox = styled.div`
	margin: -31px 0 25px;
  text-align: right;
`;
const ToggleGroup = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-right: -15px;
`;
type HCaptchaVerifyCallback = (token: string, ekey: string) => void;
type TurnstileVerifyCallback = (token: string, boundTurnstile: BoundTurnstileObject) => void;
type GetFormElements = {
  captcha?: boolean;
  captchaRef?: RefObject<HCaptcha>;
  compactCaptcha?: boolean;
  control: Control<{
    [key: string]: string;
  }, Record<string, unknown>>;
  forgotPasswordButton?: boolean;
  isFormDirty?: boolean;
  nodes: UiNode[];
  // onCaptchaFail is only called if using Turnstile
  onCaptchaFail?: (error?: any, boundTurnstile?: BoundTurnstileObject | undefined) => void;
  onCaptchaVerify?: HCaptchaVerifyCallback | TurnstileVerifyCallback;
  renderDigitalInput?: boolean;
  renderOnlyGroup?: GroupType;
  submitting?: boolean;
  onSubmit?: () => void;
};
const ORY_UI_ELEMENTS_RENAME = {
  [ORY_UI_ELEMENTS.ID]: "Email",
  [ORY_UI_ELEMENTS.SIGN_IN_BUTTON]: "Login",
  [ORY_UI_ELEMENTS.SIGN_UP_BUTTON]: "Create Account",
  [ORY_UI_ELEMENTS.UNLINK_TOTP_APP]: "Unlink Authenticator App",
  [ORY_UI_ELEMENTS.USE_AUTHENTICATOR]: "Verify Code"
};
const getElementText = (node: UiNode) => {
  const originalText = node?.meta?.label?.text;
  if (!node?.meta?.label?.id) {
    return originalText;
  }
  const text = ORY_UI_ELEMENTS_RENAME[node.meta.label.id];
  return text || originalText;
};

// Returns form elements based on flow nodes
export const getFormElements = ({
  captcha,
  captchaRef,
  compactCaptcha,
  control,
  forgotPasswordButton,
  isFormDirty,
  nodes = [],
  onCaptchaFail,
  onCaptchaVerify,
  renderDigitalInput,
  renderOnlyGroup,
  submitting = false,
  onSubmit
}: GetFormElements) => {
  const {
    cogTurnstile
  } = useFlags();
  const router = useRouter();
  const doms: JSX.Element[] = [];
  const groupNodes = getGroupNodes(nodes, renderOnlyGroup);

  // function to navigate to recovery page
  const goToRecovery = useCallback(() => {
    redirectAndPreserveParams(router, paths.recovery);
  }, [router]);
  for (let index = 0; index < groupNodes.length; index++) {
    const item = groupNodes[index];
    const attributes = (item.attributes as UiNodeInputAttributes);
    if (attributes.node_type === "input" && attributes.type === "submit") {
      doms.push(<div key="submit-button">
          {captcha && <CaptchaSpacer>
              {cogTurnstile ? <Turnstile appearance="always" onError={onCaptchaFail} onVerify={(onCaptchaVerify as TurnstileVerifyCallback)} sitekey="0x4AAAAAAAYLTbYptdfuPWox" theme="light" /> : <HCaptcha onVerify={(onCaptchaVerify as HCaptchaVerifyCallback)} ref={captchaRef} sitekey="679ddf30-131a-4691-9fb1-3f57e2233b81" size={compactCaptcha ? "compact" : "normal"} />}
            </CaptchaSpacer>}
          <div className="mx-auto mt-3 mb-4 text-center" key="submit-button">
            <PrimaryButton text={(getElementText(item) as string)} buttonType="submit" onClick={() => {
            // do nothing as this is handled in form submit action
          }} disabled={!isFormDirty} loading={submitting} />
          </div>
        </div>);
    } else if (attributes.node_type === "input" && attributes.type === "hidden") {
      doms.push(<input type="hidden" name={attributes.name} key={attributes.name} value={attributes.value} />);
    } else if (attributes.node_type === "input") {
      doms.push(<Fragment key={attributes.name}>
          <FormInput control={control}
        // extra error is displayed when we get error after submitting form
        extraError={item.messages.map(msg => getMappedMessage(msg)).join(",")} disabled={attributes.disabled} hidePasswordMeter={!window?.location.href.includes("registration?") && !window?.location.href.includes("settings?")} hoverBorderColor="#B1B8C8" inputBackgroundColor="#FFFFFF" inputLabel={inputLabelMapping[(item.meta.label?.text as string)] || getElementText(item)} name={attributes.name} renderDigitalInput={renderDigitalInput} submitting={submitting} type={attributes.type === "email" ? "text" : attributes.type} onSubmit={onSubmit} />
          {attributes.type === "password" && forgotPasswordButton && <TextButtonBox>
              <TextButton text="Forgot Password?" onClick={goToRecovery} />
            </TextButtonBox>}
        </Fragment>);
    } else if (attributes.node_type === "img") {
      doms.push(<img src={((attributes as unknown) as UiNodeImageAttributes).src} key={attributes.name} alt={getElementText(item)} />);
    } else if (attributes.node_type === "text") {
      doms.push(<div key={attributes.name} className="py-5">
          <BodySTertiary>{getElementText(item)}</BodySTertiary>
          <BodyMEmphasis>{((attributes as unknown) as UiNodeTextAttributes).text.text}</BodyMEmphasis>
        </div>);
    } else if (attributes.node_type === TOS_NODE_TYPE) {
      // terms of service is special usecase which we are using for signup
      doms.push(<PolicyAcceptanceCheckbox control={control} />);
    } else if (attributes.node_type === ENABLE_2FA_TOGGLE) {
      // 2FA opt-in-out toggle
      doms.push(<Controller name={ENABLE_2FA_TOGGLE} control={control} render={({
        field
      }) => {
        const value = Boolean(field.value);
        return <ToggleGroup key="2fa-toggle">
                <div className="font-bold text-[#39393D] text-[16px]">Use 2-Factor Authentication</div>
                <Toggle active={value} onToggle={() => {
            analytics.track("Toggle Use 2-Factor", {
              active: !value
            });
            field.onChange(!value);
          }} />
              </ToggleGroup>;
      }} />);
    }
  }
  return doms;
};