import queryString from "query-string";

// function to create a redirection url taking the return_to parameter
// preserving the params inside the return_to url
// adding email and voucherId if they exist
export const createRedirectionWithParams = (return_to: string): string => {
  const { query: webParams } = queryString.parseUrl(window.location.href);
  const { url, query } = queryString.parseUrl(return_to);
  const updatedQuery = {
    ...query,
    email: webParams?.email ? String(webParams?.email || "")?.toLowerCase() : undefined,
    voucherId: webParams?.voucherId ? webParams?.voucherId : undefined,
  };
  const finalUrl = queryString.stringifyUrl({
    url,
    query: updatedQuery,
  });
  return finalUrl;
};
