import { ConnotationColorEnum, DialogHandler, SnackTypeEnum } from "@cerebruminc/cerebellum";
import type { LoginFlow, Session, UpdateLoginFlowBody } from "@ory/kratos-client";
import { AxiosError } from "axios";
import { identity, pickBy } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { paths } from "src/const";
import ory from "src/helpers/ory/sdk";
import { AxiosErrorType, handleFlowError, createRedirectionWithParams, neuronSdk, noop } from "src/helpers";
import { useGetReturnUrl } from "./useGetReturnUrl";
import { useQueryClient } from "@tanstack/react-query";
interface LoginFlowProps {
  noRedirect?: boolean;
  forceRefresh?: boolean;
}

/**
 * this hook initialize and manage login flow state
 * @returns { loading, flow, submitting, onSubmit, initalValues }
 */
export const useLoginFlow = ({
  noRedirect,
  forceRefresh
}: LoginFlowProps = {}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const returnTo = useGetReturnUrl();
  const {
    flow: flowId,
    // Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
    // of a user.
    refresh,
    // AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
    // to perform two-factor authentication/verification.
    aal,
    email,
    voucherId
  } = router.query;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [flow, setFlow] = useState<LoginFlow>();

  // initialize flow
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    if (!router.isReady || flow) {
      return;
    }

    // initialize login flow or get existing flow
    const init = async (innerFlowId = "") => {
      if (innerFlowId) {
        try {
          setLoading(true);
          const {
            data
          } = await ory.getLoginFlow({
            id: String(innerFlowId)
          });
          // remove messages at the beggining
          setFlow({
            ...data,
            ui: {
              ...data.ui,
              messages: undefined
            }
          });
        } catch (error) {
          // if flow returns error then retry
          init().then(noop, noop);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const {
            data
          } = await ory.createBrowserLoginFlow({
            refresh: forceRefresh ?? refresh === "true",
            aal: aal ? String(aal) : undefined,
            returnTo: returnTo ? String(returnTo) : undefined
          });
          let query: {
            [key: string]: string;
          } = {
            flow: data.id,
            return_to: returnTo,
            aal: (aal as string),
            refresh: ((forceRefresh ?? refresh) as string),
            email: String(email || "").toLowerCase(),
            voucherId: String(voucherId || "")
          };
          // remove all falsy values
          query = pickBy(query, identity);
          const urlQuery = {
            query: {
              ...query
            }
          };
          if (!noRedirect) {
            // update flow id in url
            router.push(paths.login, urlQuery);
          }
          setFlow(data);
        } catch (error) {
          if (!noRedirect && error instanceof AxiosError) {
            // if session already available and we have returnTo url then redirec user to returnTo
            if (error?.response?.data.error?.id === "session_already_available" && returnTo) {
              const finalUrl = createRedirectionWithParams(returnTo);
              window.location.href = (finalUrl as string);
              return;
            }
            if (error?.response?.data?.error?.code === 500) {
              window.location.href = paths.login;
              return;
            }
            handleFlowError(router, paths.login, setFlow)((error as AxiosError<AxiosErrorType>));
          }
        } finally {
          setLoading(false);
        }
      }
    };
    init((flowId as string)).then(noop, noop);
  }, [flowId, router.isReady, returnTo, aal, refresh]);
  const proceedAfterLogin = useCallback(async (_session: Session) => {
    try {
      // if logs in successfully then check kratos user in neuron db
      await neuronSdk.checkKratosUser();
    } catch (error) {
      // if neuron is down or there is some error related to session checking
      // then dont allow user to proceed to `return_to` url ( which causes redirect loop )
      // instead redirect user to login
      // fixes scenario:
      // neuron api has some issues/down
      // if user is not logged in, neuron-web redirect user to auth-web
      // auth-web logs in redirect to neuron-web but neuron-web check neuron whoami route
      // which returns error hense it will redirect to auth-web back and loop continuous
      DialogHandler.show({
        colorFamily: ConnotationColorEnum.Negative,
        id: "something-went-wrong-login",
        snackType: SnackTypeEnum.Toast,
        text: "Something went wrong"
      });
      return router.push(paths.logout);
    }

    // bust the query cache on login, in case the previous session left stale data
    queryClient.resetQueries();
    if (flow?.return_to) {
      const finalUrl = createRedirectionWithParams(flow?.return_to);
      window.location.href = finalUrl;
      return;
    }
    return router.push(paths.home);
  }, [flow, router, queryClient]);

  // call this function when form gets submitted
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const onSubmit = useCallback(async (values: UpdateLoginFlowBody) => {
    try {
      setSubmitting(true);
      if (flow) {
        setFlow({
          ...(flow as LoginFlow),
          ui: {
            ...flow.ui,
            messages: undefined
          }
        });
      }
      if (!noRedirect) {
        try {
          // check whether user is already logged in.
          // this is special case for chrome browser as we have figured out
          // when user logs in first time and redirected to vms/cortex and if user press back button then user will lands on login page
          // and chrome browser is not re-rendering the page so hooks are not getting called.
          // so if we detect valid session then directly redirect user
          const session = await ory.toSession();
          return proceedAfterLogin(session?.data);
        } catch (error) {
          // ignore this error
        }
      }

      // do login api request
      const res = await ory.updateLoginFlow({
        flow: String(flow?.id),
        updateLoginFlowBody: values
      });
      setSubmitting(false);
      if (!noRedirect) {
        // try to get session
        // if 2FA is required then it will throw "session_aal2_required" error and handleFlowError will handle the error and required user to login with aal2 parameter
        // if 2FA is not required then flow will continue as it is
        await ory.toSession();
        return proceedAfterLogin(res?.data?.session);
      }
      return res?.data;
    } catch (error) {
      setSubmitting(false);
      handleFlowError(router, paths.login, setFlow)((error as AxiosError<AxiosErrorType>));
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setFlow(error.response?.data);
        }
        throw error.response?.data || error;
      }
    }
  }, [flow, router, proceedAfterLogin]);
  return useMemo(() => ({
    loading,
    flow,
    submitting,
    onSubmit
  }), [loading, flow, submitting, onSubmit]);
};