import { type RecoveryFlow, UpdateRecoveryFlowWithCodeMethod, UpdateRecoveryFlowWithLinkMethod } from "@ory/kratos-client";
import { AxiosError } from "axios";
import { noop } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { paths } from "src/const";
import { AxiosErrorType, handleFlowError } from "src/helpers";
import ory from "src/helpers/ory/sdk";

/**
 * this hook initialize and manage recovery flow
 * @returns { loading, flow, submitting, onSubmit, initalValues }
 */
export const useRecoveryFlow = () => {
  const router = useRouter();
  const [flow, setFlow] = useState<RecoveryFlow>();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  // Get ?flow=... from the URL
  const {
    flow: flowId
  } = router.query;

  // In this effect we either initiate a new registration flow, or we fetch an existing registration flow.
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (!router.isReady || flow) {
      return;
    }
    const init = async (innerFlowId = "") => {
      // If ?flow=.. was in the URL, we fetch it
      if (innerFlowId) {
        try {
          setLoading(true);
          const {
            data
          } = await ory.getRecoveryFlow({
            id: String(innerFlowId)
          });
          setFlow(data);
        } catch (error) {
          init().then(noop, noop);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const {
            data
          } = await ory.createBrowserRecoveryFlow();
          router.push(`${paths.recovery}/?flow=${data?.id}`, undefined, {
            shallow: true
          });
          setFlow(data);
        } catch (error) {
          handleFlowError(router, paths.recovery, setFlow)((error as AxiosError<AxiosErrorType>));
        } finally {
          setLoading(false);
        }
      }
    };
    init((flowId as string)).then(noop, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, router.isReady]);
  const onSubmit = useCallback(async (values: Parameters<typeof ory.updateRecoveryFlow>[0]["updateRecoveryFlowBody"] & {
    captchaResponse?: string;
  }) => {
    try {
      setSubmitting(true);
      const res = await ory.updateRecoveryFlow({
        flow: String(flow?.id),
        updateRecoveryFlowBody: values
      });
      setFlow(res.data);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      handleFlowError(router, paths.recovery, setFlow)((error as AxiosError<AxiosErrorType>));
      if (error instanceof AxiosError && error.response?.status === 400) {
        setFlow(error.response?.data);
        return;
      }
    }
  }, [flow, router]);
  return useMemo(() => ({
    loading,
    flow,
    submitting,
    onSubmit
  }), [loading, flow, submitting, onSubmit]);
};