import type { FindUsersDto, InviteUserDto, UpdateUserDto } from "@cerebruminc/neuron-sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";
import { useNeuron } from "src/components/NeuronProvider";
import { useAtomValue, useSetAtom } from "jotai";
import { currentOrgIdAtom, userObjectAtom } from "src/atom";

// Associate a number with each role to indicate the level of access
// The higher the number, the more privileged the role
// This is useful for comparing access levels between roles, so we can determine
// the highest and lowest privilege levels a user has across multiple orgs
const ROLE_ACCESS_LEVEL: Record<string, number> = {
  USER: 0,
  PROCESSOR: 0,
  INTEGRATION_ADMIN: 1,
  ORGANIZATION_ADMIN: 2,
  SUPER_ADMIN: 3,
};

export const useWhoAmI = () => {
  const setWhoAmI = useSetAtom(userObjectAtom);
  return useQuery({
    queryKey: ["whoAmI"],
    async queryFn() {
      const res = await neuronSdk.whoAmI();
      setWhoAmI(res.data);
      return res.data;
    },
  });
};

export const useGetUserById = (id: string) => {
  return useQuery({
    queryKey: ["find-by-id", id],
    async queryFn() {
      const res = await neuronSdk.findUser(id, {});
      return res.data;
    },
  });
};

export const useUserOrgRole = () => {
  const { data: user } = useWhoAmI();
  const orgId = useAtomValue(currentOrgIdAtom);

  if (!user) {
    return null;
  }

  // If orgId is null, we want to find the highest access role across all orgs
  // the user belongs to
  if (orgId === null) {
    let highestAccessRole = "USER";

    for (const role of user.roleAssignment) {
      if (role.roleV2.name === "SUPER_ADMIN") {
        return "SUPER_ADMIN";
      }

      if (!highestAccessRole || ROLE_ACCESS_LEVEL[role.roleV2.name] > ROLE_ACCESS_LEVEL[highestAccessRole]) {
        highestAccessRole = role.roleV2.name;
      }
    }

    return highestAccessRole;
  }

  const role = user.roleAssignment?.find((item) => {
    // If they are a super admin, they have access to everything:
    // switching the active org doesn't make a difference
    if (item.roleV2.name === "SUPER_ADMIN") {
      return true;
    }

    return item.organization?.id === orgId;
  });

  return role?.roleV2?.name ?? null;
};

interface UseGetAllUsersProps {
  columnId?: string;
  currentPage?: number;
  rowsPerPage: number;
  sortAsc?: string;
  where?: {
    email?: string;
    organizations?: string[];
  };
}

export const useGetAllUsers = ({ currentPage, columnId, rowsPerPage, sortAsc, where }: UseGetAllUsersProps) => {
  const { sdk } = useNeuron();

  return useQuery({
    queryKey: ["all-users", currentPage, columnId, sortAsc, where],
    async queryFn() {
      const queryParams: FindUsersDto = {
        // TODO: remove casting after update to neuron-sdk v11.1.0
        where: (where as any) ?? undefined,
        include: {
          roleAssignment: {
            include: {
              roleV2: true,
            },
          },
          organizations: true,
        },
      };
      if (currentPage !== undefined) {
        queryParams.limit = rowsPerPage;
        queryParams.skip = currentPage * rowsPerPage;
      }
      if (columnId !== undefined && sortAsc !== undefined) {
        queryParams.orderBy = {
          [columnId]: sortAsc,
        };
      }
      const res = await sdk.findUsers(queryParams);
      return res.data;
    },
    staleTime: 4000,
  });
};

export const useInviteUserMutation = () => {
  return useMutation({
    async mutationFn(data: InviteUserDto) {
      const res = await neuronSdk.inviteUser(data);
      return res.data;
    },
  });
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn(data: UpdateUserDto & { id: string }) {
      const { id, ...rest } = data;
      const res = await neuronSdk.updateUser(id, rest);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["who-am-i"]);
    },
  });
};

export const useUpdateSelectedOrganizationId = () => {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn(data: { id: string; selectedOrganizationId: string | null }) {
      const { id, selectedOrganizationId } = data;
      const res = await neuronSdk.updateUser(id, {
        selectedOrganizationId,
      });
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["who-am-i"]);
    },
  });
};
