import queryString from "query-string";

// function to create a return_to url for local pages
// preserving the params inside the return_to url
// adding email and voucherId if they exist
export const createLocalReturnToWithParams = (localPath: string, extraParams?: any) => {
  const origin = window.location.origin;
  const url = `${origin}${localPath}`;
  const { query: params } = queryString.parseUrl(window.location.href);
  const updatedQuery = {
    ...params,
    ...(extraParams || {}),
  };
  const finalUrl = queryString.stringifyUrl({
    url,
    query: updatedQuery,
  });
  return finalUrl;
};
