import { northAmericanNumberingPlanRegex, validateRegex } from "@cerebruminc/cerebellum";

export interface ValidatePhoneProps {
  phone: string;
  required?: boolean;
}

export type ValidatePhoneResult = { phone: string; phoneError?: string };

// returns just numbers
export const validatePhone = ({ phone, required }: ValidatePhoneProps): ValidatePhoneResult => {
  let phoneError: string | undefined;
  const justNumbers = phone?.replace(/\D/g, "");
  const isInvalid = justNumbers?.length < 10 || justNumbers?.length > 11;

  if (!phone) {
    if (required) {
      phoneError = "Phone number is required";
    }
  } else if (isInvalid) {
    phoneError = "Invalid Phone Number";
  } else if (!validateRegex(phone, northAmericanNumberingPlanRegex)) {
    phoneError = "Phone number must follow the North American Numbering Plan";
  }

  return { phone: justNumbers, phoneError };
};
