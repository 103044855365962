import { NavLayout } from "@cerebruminc/cerebellum";
import { UserControls } from "src/components";
import { useSession } from "src/hooks";
import NavLogo from "./NavLogo";
interface ComponentProps {
  navLogoImageSource: string;
  navLogoAltText: string;
  showUserControls?: boolean;
  // config?: any;
}

// The Nav component is the high level "Assembly component" where all the other components are assembled into a nav bar.
// It also transforms the locally-named props of the other components into globally-named props (e.g. imageSource for NavLogo becomes navLogoImageSource).
export function Nav({
  navLogoImageSource,
  navLogoAltText,
  showUserControls = true
}: Readonly<ComponentProps>) {
  const {
    session
  } = useSession();
  return <div id="header" className="fixed z-10 w-full">
      <NavLayout themeOverride={{}}>
        <NavLogo imageSource={navLogoImageSource} altText={navLogoAltText} imageLink={"/"} />
        {session && showUserControls ? <UserControls /> : <div />}
      </NavLayout>
    </div>;
}