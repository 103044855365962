import { StepType } from "@cerebruminc/cerebellum";
import { vidSteps } from "./../../const";

interface GetVidSteps {
  additionalSteps?: StepType[];
  collectSSN: boolean;
  // This will show the disclosures step, even when the applicant doesn't have to sign them. For packages that always have disclosures, or only location based disclosures.
  showDisclosuresStep: boolean;
  noStateDisclosures?: boolean;
  usState?: string;
}
export const getVidSteps = ({
  additionalSteps,
  collectSSN,
  showDisclosuresStep,
  noStateDisclosures,
  usState,
}: GetVidSteps) => {
  const steps: StepType[] = [...vidSteps];

  if (!collectSSN) {
    steps.splice(2, 1);
  }

  if (additionalSteps && additionalSteps.length > 0 && collectSSN) {
    steps.splice(3, 0, ...additionalSteps);
  } else if (additionalSteps && additionalSteps.length > 0 && !collectSSN) {
    steps.splice(2, 0, ...additionalSteps);
  }

  // Set optional helper text for the disclosures
  steps[steps.length - 1].helperText =
    showDisclosuresStep && noStateDisclosures && usState ? `Not needed for ${usState} residents.` : undefined;

  if (!showDisclosuresStep) {
    // remove Disclosures step
    steps.pop();
  }
  return steps;
};
