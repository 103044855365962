import { type ActiveFiltersType, type FilterType, getObjectById } from "@cerebruminc/cerebellum";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "./helper";
import type { FormatFilterModalFiltersProps, FormatFiltersType, GetValuesProps } from "./types";
import { CUSTOM_RANGE } from "src/mocks/ordersViewData";

export const formatToActiveFilterType = (filters: FilterType[]) => {
  const activeFilterObject: { [filterType: string]: string[] } = {};

  for (let i = 0; i < filters.length; i++) {
    const filterType = filters[i].filterType || "";
    if (!activeFilterObject[filterType]) {
      activeFilterObject[filterType] = [];
    }
    const selectedFilterId = filters[i].id;
    activeFilterObject[filterType].push(selectedFilterId);
  }

  return activeFilterObject;
};

export const formatFilters = ({ availableFilters, filtersObject, fromDate = 0, toDate = 0 }: FormatFiltersType) => {
  const activeKeys = Object.keys(filtersObject);
  const filters = [];

  for (let i = 0; i < activeKeys.length; i++) {
    const key = activeKeys[i];
    const filterArray = availableFilters[key];
    if (typeof filtersObject?.[key] === "string") {
      const filterId = (filtersObject?.[key] as string) || "";
      const filterObj = getObjectById({
        array: filterArray || [],
        id: filterId || "",
      });
      if (filterObj) {
        const title = `${capitalizeFirstLetter(key)}`;
        filterObj.title = title === "PackageSetId" ? "Package" : title;
        filterObj.filterType = key;
        if (key === "range") {
          if (filterObj.id === CUSTOM_RANGE) {
            const fromDateString = dayjs(fromDate * 1000).format("MMM D, YY");
            const toDateString = dayjs(toDate * 1000).format("MMM D, YY");
            filterObj.label = `${fromDateString} - ${toDateString}`;
          }
        }
        filters.push(filterObj);
      }
    } else {
      for (let x = 0; x < filtersObject?.[key]?.length; x++) {
        const filterId = filtersObject?.[key]?.[x];
        const filterObj = getObjectById({
          array: filterArray || [],
          id: filterId || "",
        });
        if (filterObj) {
          const title = `${capitalizeFirstLetter(key)}`;
          filterObj.title = title === "PackageSetId" ? "Package" : title;
          filterObj.filterType = key;
          if (key === "range") {
            if (filterObj.id === CUSTOM_RANGE) {
              const fromDateString = dayjs(fromDate * 1000).format("MMM D, YY");
              const toDateString = dayjs(toDate * 1000).format("MMM D, YY");
              filterObj.label = `${fromDateString} - ${toDateString}`;
            }
          }
          filters.push(filterObj);
        }
      }
    }
  }

  return filters;
};

export const formatFilterModalFilters = ({
  availableFilters,
  filters,
  radioType,
  tabIds,
}: FormatFilterModalFiltersProps) => {
  const newFilters: ActiveFiltersType = {};

  for (let i = 0; i < tabIds.length; i++) {
    const id = tabIds[i];
    const filterIdArray = filters?.[id] || [];
    const activeIds = getValuesFromIds({
      filterIds: filterIdArray,
      filters: availableFilters[id],
    });
    newFilters[id] = id === radioType ? activeIds[0] : activeIds;
  }

  return newFilters;
};

export const getValuesFromIds = ({ filterIds, filters }: GetValuesProps) => {
  const activeLabels = [];
  for (let i = 0; i < filterIds.length; i++) {
    const id = filterIds[i];
    const activeObj = getObjectById({ array: filters, id });

    if (activeObj) {
      activeLabels.push(activeObj.value);
    }
  }
  return activeLabels;
};
