import { BodyMPrimary, BodySEmphasis, HeadingH1, colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
export const FormBase = styled.div`
	max-width: 100%;
	width: 400px;
`;
export const Header = styled(HeadingH1)`
	margin-bottom: 25px;
`;
export const StepText = styled(BodyMPrimary)`
	margin-bottom: 25px;
	&:first-of-type {
		margin-bottom: 31px;
	}
	&:last-of-type {
		margin-bottom: 55px;
	}
`;
export const BackButtonBox = styled.div`
	position: absolute;
	top: 27px;
	left: 28px;
`;
export const SkipButtonBox = styled.div`
	position: absolute;
	top: 27px;
	right: 21px;
`;
export const ButtonBox = styled.div`
	text-align: center;
`;
export const ErrorMessageBox = styled.div<{
  $stepTwo?: boolean;
}>`
	margin-top: ${({
  $stepTwo
}) => $stepTwo ? 30 : 10}px;
	margin-bottom: ${({
  $stepTwo
}) => $stepTwo ? -30 : 30}px;
	text-align: center;
`;

// QR view
export const QRBox = styled.div`
	height: 174px;
	margin: 0 auto;
	padding: 12px;
	border-radius: 10px;
	border: 1px solid ${colors.COOL_GREY_30};
	width: 174px;
`;
export const CodeBox = styled.div`
	align-items: center;
	display: flex;
	gap: 10px;
	height: 55px;
	margin: 31px auto 35px;
	padding: 15px 8px 15px 15px;
	border-radius: 5px;
	border: 1px solid ${colors.COOL_GREY_25};
`;
export const CodeText = styled(BodyMPrimary)`
	max-width: calc(100% - 88px);
	overflow-x: auto;
	-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
	scrollbar-width: none; 
`;

// Authentication code view
export const FinishSleeve = styled.div`
	padding-top: 50px;
`;
export const AuthBox = styled.div`
	margin: 49px auto 40px;
	display: flex;
	justify-content: center;
`;

// Backup Codes view
export const SuccessMessage = styled(BodySEmphasis)`
	color: ${colors.AQUA_100};
	margin-bottom: 11px;
`;
export const CodeGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 10px;
	margin-top: 31px;
	border-radius: 10px;	
	border: 1px solid ${colors.COOL_GREY_30};
	background: ${colors.COOL_GREY_5};
	padding: 14px 22px;
	@media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
`;
export const DownloadButtonBox = styled.div`
	margin: 19px 0 57px;
	text-align: center;
`;