// useCheckForDisclosuresAndSsn will set the atoms that control the UI related to disclosures and SSN collection.
// This exists just to move this logic into a separate module.

import { useEffect } from "react";
import { CheckVoucherValidityQuery, useRetrieveDisclosuresQuery, VidVoucherQuery } from "src/generated/graphql";
import { useAtomValue, useSetAtom } from "jotai";
import {
  collectSSNAtom,
  possibleStateDisclosuresAtom,
  showDisclosuresAtom,
  vidStepsAtom,
  allowSampleIdAtom,
  currentAddressAtom,
} from "./../../state";
import { getVidSteps } from "./../helpers";

interface UseCheckForDisclosuresAndSsn {
  vIdVoucher: VidVoucherQuery["vIDVoucher"] | null;
  voucherValidity?: CheckVoucherValidityQuery;
}

export const useCheckForDisclosuresAndSsn = ({ vIdVoucher, voucherValidity }: UseCheckForDisclosuresAndSsn) => {
  const currentAddress = useAtomValue(currentAddressAtom);
  const { data: disclosures } = useRetrieveDisclosuresQuery(
    {
      packageId: vIdVoucher?.packageSetId ?? "",
      addresses: currentAddress || [], // No addresses at initial load, but positive after refresh
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vIdVoucher?.packageSetId,
      select(data) {
        const stateDisclosureArray = data.retrieveDisclosures ?? [];
        return stateDisclosureArray;
      },
    },
  );
  const setCollectSSN = useSetAtom(collectSSNAtom);
  const setPossibleStateDisclosures = useSetAtom(possibleStateDisclosuresAtom);
  const setShowDisclosures = useSetAtom(showDisclosuresAtom);
  const setVidSteps = useSetAtom(vidStepsAtom);
  const setAllowSampleId = useSetAtom(allowSampleIdAtom);

  const shouldCollectSSN = voucherValidity?.checkVoucherValidity?.packageSet.collectSSN;
  const showDisclosuresStep = Boolean(voucherValidity?.checkVoucherValidity?.packageSet?.showDisclosures);
  const possibleStateDisclosures = showDisclosuresStep && disclosures?.length == 0;
  const allowSampleId = voucherValidity?.checkVoucherValidity?.packageSet.useSampleId;

  useEffect(() => {
    const collectSSN = Boolean(shouldCollectSSN);
    setCollectSSN(collectSSN);
    setShowDisclosures(showDisclosuresStep);
    setAllowSampleId(Boolean(allowSampleId));
    setPossibleStateDisclosures(Boolean(possibleStateDisclosures));
    const updatedSteps = getVidSteps({
      collectSSN,
      showDisclosuresStep,
      noStateDisclosures: disclosures?.length == 0,
      usState: currentAddress?.state,
    });
    setVidSteps(updatedSteps);
  }, [
    allowSampleId,
    currentAddress,
    disclosures,
    possibleStateDisclosures,
    setAllowSampleId,
    setCollectSSN,
    setPossibleStateDisclosures,
    setShowDisclosures,
    setVidSteps,
    shouldCollectSSN,
    showDisclosuresStep,
  ]);
};
