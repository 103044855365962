import { Configuration, NeuronApi } from "@cerebruminc/neuron-sdk";
import React, { createContext, type FC, type ReactNode, useCallback, useContext, useMemo, useEffect } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { cerebellumTheme, ThemeProvider } from "@cerebruminc/cerebellum";
import type { DefaultTheme } from "styled-components";
import { useSetAtom } from "jotai";
import { organizationsAtom } from "src/atom";
import { useGetOrganizationsQuery } from "src/helpers";
interface NeuronProviderProps {
  children: ReactNode;
  neuronUrl: string;
  theme?: DefaultTheme;
}
interface NeuronContextData {
  sdk: NeuronApi;
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000 * 60
    }
  }
});
const NeuronContext = createContext(({} as NeuronContextData));

/**
 * hook to get sdk instance which was initialized in NeuronProvider
 * @returns neuron sdk context
 */
export const useNeuron = () => {
  const context = useContext(NeuronContext);
  if (context === undefined) {
    throw new Error("useNeuron must be used within a component wrapped with NeuronProvider");
  }
  return context;
};
const OrganizationsAtomSetter = () => {
  const setOrganizationsAtom = useSetAtom(organizationsAtom);
  const {
    data: organizations
  } = useGetOrganizationsQuery({
    orderBy: {
      name: "asc"
    },
    include: {
      logo: true
    }
  });
  useEffect(() => {
    if (organizations) {
      setOrganizationsAtom(organizations);
    }
  }, [organizations, setOrganizationsAtom]);
  return null;
};

/**
 * context-provider to keep neuron url and initialize neuron sdk
 * @param param0 url & children to render
 * @returns
 */
export const NeuronProvider: FC<NeuronProviderProps> = ({
  children,
  neuronUrl,
  theme
}) => {
  const getSdk = useCallback((apiUrl: string) => {
    return new NeuronApi(new Configuration({
      basePath: apiUrl,
      baseOptions: {
        withCredentials: true
      }
    }));
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const sdk = useMemo(() => getSdk(neuronUrl), [neuronUrl]);
  return <NeuronContext.Provider value={{
    sdk
  }}>
      <ThemeProvider theme={theme || cerebellumTheme}>
        <QueryClientProvider client={queryClient}>
          <OrganizationsAtomSetter />
          {children}
        </QueryClientProvider>
      </ThemeProvider>
    </NeuronContext.Provider>;
};