// This is an asynchronous hook that polls the API to check the validity of an email

import { Truncate } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { useState, useRef, useCallback, useEffect, Dispatch, ReactNode, SetStateAction } from "react";
import { neuronSdk } from "src/helpers";
import { isOneAndDoneUserAtom } from "./../../state";
import { Black, Blue } from "../VidStyles";
type UseEmailVerification = {
  setHelperText: Dispatch<SetStateAction<ReactNode>>;
};
export const useEmailVerification = ({
  setHelperText
}: UseEmailVerification) => {
  const isOneAndDoneUser = useAtomValue(isOneAndDoneUserAtom);
  const [emailVerified, setEmailVerified] = useState(isOneAndDoneUser);
  const emailVerificationPollTimeout = useRef<NodeJS.Timer>();
  const checkEmailVerified = useCallback(async () => {
    try {
      const {
        data: {
          identity: {
            verifiable_addresses
          }
        }
      } = await neuronSdk.getSession();
      // TODO: do we need to loop through the emails to find if invited email
      // is verified or not?
      const emailIsVerified = verifiable_addresses?.[0].verified ?? false;
      clearTimeout(emailVerificationPollTimeout.current);
      if (emailIsVerified) {
        setEmailVerified(true);
        setHelperText(null);
      } else {
        setHelperText(<Black>
            Please check{" "}
            <Truncate>
              <Blue>{verifiable_addresses?.[0]?.value ?? "your email"}</Blue>
            </Truncate>{" "}
            for a verification email from the vID team
          </Black>);
        emailVerificationPollTimeout.current = setTimeout(async () => {
          checkEmailVerified();
        }, 5000);
      }
    } catch (error) {
      console.error("Error checking email verification status", {
        error
      });
    }
  }, [setHelperText]);
  useEffect(() => {
    if (!emailVerified) {
      checkEmailVerified();
    }
    return () => clearTimeout(emailVerificationPollTimeout.current);
  }, [checkEmailVerified, emailVerified]);
  return emailVerified;
};