import { useAtomValue, useSetAtom, useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useBeamListQuery, SortOrder } from "src/generated/graphql";
import { VidStepsEnum } from "./../../const";
import {
  vidVoucherAtom,
  collectSSNAtom,
  showDisclosuresAtom,
  passTypeBeamsDataAtom,
  vidStepsAtom,
  acceptInviteModalVisibleAtom,
} from "./../../state";
import { getVidSteps } from "./../helpers";

export const useVidGetBeams = () => {
  const vIdVoucher = useAtomValue(vidVoucherAtom);
  const collectSSN = useAtomValue(collectSSNAtom);
  const showDisclosuresStep = useAtomValue(showDisclosuresAtom);
  const setBeams = useSetAtom(passTypeBeamsDataAtom);
  const [vidSteps, setVidSteps] = useAtom(vidStepsAtom);
  const [modalVisible] = useAtom(acceptInviteModalVisibleAtom);

  // get Beams associated with the PassType and the most recent BeamInputType schema
  // request data when  vIdVoucher?.passTypeId is defined
  const { data: beamData, refetch: refetchBeamQuery } = useBeamListQuery(
    {
      where: {
        passTypes: {
          some: {
            id: {
              equals: vIdVoucher?.passTypeId,
            },
          },
        },
      },
      // get the most recent beamInputType for each Beam
      take: 1,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
    },
    // !modalVisible allow us know that the user accept the invite
    // as a consequense is registered in the organization
    // and can query beams that are in the organization
    { enabled: !!vIdVoucher?.passTypeId && !modalVisible },
  );

  useEffect(() => {
    const beamsArray = beamData?.beams;
    // the error can be here
    if (beamsArray && beamsArray.length > 0) {
      // add extra steps
      const additionalSteps = beamData?.beams.map((beam) => ({
        id: `${VidStepsEnum.Five}/${beam.id}`,
        label: beam.name,
      }));
      const updatedSteps = getVidSteps({ additionalSteps, collectSSN, showDisclosuresStep });
      setVidSteps(updatedSteps);
      setBeams(beamsArray);
    }
  }, [beamData, collectSSN, setVidSteps, setBeams, showDisclosuresStep]);

  const handlers = useMemo(
    () => ({
      vidSteps,
      beamData,
      refetchBeamQuery,
    }),
    [vidSteps, beamData, refetchBeamQuery],
  );

  return handlers;
};
