import { colors } from "@cerebruminc/cerebellum";
import styled from "styled-components";
import type { ButtonConcealerProps, SearchBoxProps } from "./types";
export const Header = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 8px;
  padding: 20px 28px 20px 20px;
  top: 75px;
  z-index: 2;
`;
export const TitleBox = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
`;
export const SearchBox = styled.div<SearchBoxProps>`
  align-items: center;
  display: flex;
  gap: 11px;
  position: relative;
`;
export const ButtonConcealer = styled.div<ButtonConcealerProps>`
  opacity: ${({
  $visible
}) => $visible ? 1 : 0};
`;
export const SearchAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;