import type { Session } from "@ory/kratos-client";
import { useEffect, useMemo, useState } from "react";
import ory from "src/helpers/ory/sdk";

/**
 * this hook return session information
 * @returns { loading, session }
 */
export const useSession = () => {
  const [session, setSession] = useState(((null as unknown) as Session));
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    ory.toSession().then(({
      data
    }) => {
      setSession(data);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, []);
  return useMemo(() => ({
    loading,
    session
  }), [loading, session]);
};