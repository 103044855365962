import queryString from "query-string";
import { NextRouter } from "next/router";
import { validateReturnToUrl } from "./validateReturnToUrl";

// function to do redirections to preserve parameters
export const redirectAndPreserveParams = (router: NextRouter, redirection: string, extraParams?: any) => {
  const { query: webParams } = queryString.parseUrl(window.location.href);
  router.push({
    pathname: redirection,
    query: {
      email: webParams?.email ? String(webParams?.email || "").toLowerCase() : undefined,
      voucherId: webParams?.voucherId ? String(webParams?.voucherId || "") : undefined,
      return_to: validateReturnToUrl(webParams?.return_to as string) ? String(webParams?.return_to || "") : "",
      ...(extraParams || {}),
    },
  });
};
