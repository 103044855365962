import { StepType } from "@cerebruminc/cerebellum";

interface FindSubmitButtonText {
  stepNumber: number;
  vidSteps: StepType[];
}
// Note that stepNumber is NOT 0 indexed.
export const findSubmitButtonText = ({ stepNumber, vidSteps }: FindSubmitButtonText): string => {
  // Only the disclosures step, when we're skipping it, has helperText
  const skipLastStep = Boolean(vidSteps[vidSteps.length - 1].helperText);
  const lastStepNumber = vidSteps.length - (skipLastStep ? 1 : 0);
  const isLastStep = stepNumber >= lastStepNumber;

  return isLastStep ? "Submit" : "Next";
};
