import { StringBooleanType } from "src/types";
import { VidStepsEnum } from "../../const";

export const getNextStep = (completedSteps: StringBooleanType): string | null => {
  const completedStepKeys = Object.keys(completedSteps);
  const vidStepsValues = Object.values(VidStepsEnum);

  for (let i = completedStepKeys.length - 1; i >= 0; i--) {
    const key = completedStepKeys[i];
    if (completedSteps[key] === true) {
      const currentIndex = vidStepsValues.indexOf(key as VidStepsEnum);
      const nextIndex = currentIndex + 1;

      if (nextIndex < vidStepsValues.length) {
        return vidStepsValues[nextIndex].replace("vid/", "");
      }
      break;
    }
  }

  return null;
};
