import type { User } from "@cerebruminc/neuron-sdk";
import type { FormikProps } from "formik";
import { atom } from "jotai";
import { createRef } from "react";
import type { SortOrder } from "./generated/graphql";
import type { TableSortState } from "./types";
import type { StepsEnum } from "./views/AddOrderView/const";
import { OrganizationWithIncludedData } from "./hooks/organizations";
import { atomWithStorage } from "jotai/utils";

export interface WindowSizeType {
  height: number;
  width: number;
}

export const windowSizeAtom = atom<WindowSizeType>({ height: 0, width: 0 });

export interface ApplicantFormValues {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  email: string;
  mobile: string;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export const currentOrgIdAtom = atom<string | null>(null);

const applicantFormRef = createRef<FormikProps<ApplicantFormValues>>();

export const ordersTableSortOrderAtom = atom<TableSortState>({
  sortKey: "createdAt",
  // If the enum is used directly here, and we use a custom graphql-codegen fetcher, we get a type error.
  // There is some interaction that means that SWC doesn't compile the enum correctly, and it isn't
  // available at run time in the tests. To work around this, we use the string value of the enum and
  // cast it to the correct type.
  sortValue: "desc" as SortOrder.Desc,
});

export const userObjectAtom = atom<User | null>(null);
export const bannerShownAtom = atom(false);
export const authCheckAtom = atom(false);

export const scrollbarWidthAtom = atom<number | null>(null);

// This is a simple counter used to trigger data fetching when a new org is created
export const orgCreationAtom = atom(0);

// AddApplicant Data Loss Warning Modal
export const addApplicantFormRefAtom = atom(applicantFormRef);
export const showAddApplicantDataLossWarningAtom = atom(false);
export const addApplicantDataLossContinueToStepAtom = atom<StepsEnum | undefined>(undefined);

// All organizations atom
/** This atom was created to prevent a situation in which the UserControls component
 * would keep querying organizations on page changes. This atom requires to have it's state manually updated,
 * so we have to update it on creation/updating/deletion of orgs. Any org refetch should also update this atom.
 */
export const organizationsAtom = atom<OrganizationWithIncludedData[]>([]);

// Atom to store resend code time for 1 minute
export const resendCodeTimeAtom = atomWithStorage<number>("resendCodeTime", 60);
