import { ConfirmModal } from "@cerebruminc/cerebellum";
import { useFlags } from "launchdarkly-react-client-sdk";
import Link from "next/link";
import React from "react";
interface SkipModalProps {
  onSkip: () => void;
  hideModal: () => void;
  modalVisible: boolean;
}
export function SkipModal({
  onSkip,
  hideModal,
  modalVisible
}: SkipModalProps) {
  const {
    neuronWebAllow2FaDisable
  } = useFlags();
  return <ConfirmModal header="2-Factor Authentication has not been enabled." primaryButtonText="Ok" onPrimaryClick={onSkip} show={modalVisible} onXClick={hideModal} themeOverride={{
    modalWidth: 430,
    primaryButtonWidth: 140,
    modalPaddingSide: 70,
    modalPaddingBottom: 50,
    modalPaddingTop: 50
  }}>
      {neuronWebAllow2FaDisable && <p className="text-[16px] leading-6 text-[#00010d]">
          Enable 2FA at anytime by going to the <b>security</b> section in your{" "}
          <span className="underline text-[#5176ec]">
            <Link href="/settings">Profile Settings</Link>
          </span>
          .
        </p>}
    </ConfirmModal>;
}