import { HeadingH1 } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
export const FormBox = styled.div`
  max-width: 100%;
  width: 408px;
`;
export const Title = styled(HeadingH1)``;
export const TitleBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
`;