import Link from "next/link";
import { styled } from "styled-components";
const LogoImage = styled.img`
	cursor: pointer;
	display: flex;
	height: 34px;
	max-height: 100%; 
	overflow: hidden;
	width: 93px;
`;

// https://www.pluralsight.com/guides/defining-props-in-react-function-component-with-typescript
interface ComponentProps {
  imageSource: string;
  altText: string;
  imageLink: string;
}

// This is a pretty simple component. It is the logo of the CRA organization which the user is grouped uner, and it is displayed on the left side of the nav bar.
// It offers imageSource and altText as props.
export default function NavLogo(props: ComponentProps) {
  const {
    imageSource,
    altText,
    imageLink
  } = props;
  return <Link href={imageLink} passHref>
      <LogoImage src={imageSource} alt={altText} />
    </Link>;
}