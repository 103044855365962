import { useRouter } from "next/router";
import { useEffect, useState } from "react";

/**
 * Custom hook to manage state synchronization with URL query parameters.
 * This hook is useful when you want to keep the state of a component in sync with the URL query parameters.
 *
 * @param {T} defaultValue - The default value for the state.
 * @param {string} key - The key of the URL query parameter to sync with.
 * @returns {Array} - An array where the first item is the current state and the second item is a function to update the state.
 */
export const useRouterQueryState = <T extends string>(defaultValue: T, key: string) => {
  const router = useRouter();
  const [value, setValue] = useState<T>((router.query[key] as T) || defaultValue);

  useEffect(() => {
    // If the query string value changes, update the state value
    setValue(router.query[key] as T);
  }, [router.query[key], key]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    if (value) {
      // If the state value is set, update the query string
      router.push({ query: { [key]: value as any } });
    } else {
      // If the state value is empty, remove the query string
      const { [key]: _, ...rest } = router.query;
      router.push({ query: rest });
    }
  }, [value]);

  return [value, setValue] as const;
};
