import type { SettingsFlow, UpdateSettingsFlowBody } from "@ory/kratos-client";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { paths } from "src/const";
import { AxiosErrorType, handleFlowError, noop } from "src/helpers";
import ory from "src/helpers/ory/sdk";
import { useGetReturnUrl } from "./useGetReturnUrl";

/**
 * this hook initialize and manage settings flow
 * @returns { loading, flow, submitting, onSubmit }
 *
 * noRedirect will prevent all redirection, including flow errors.
 */
export const useSettingsFlow = (noRedirect?: boolean) => {
  const router = useRouter();
  const [flow, setFlow] = useState<SettingsFlow>();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  // Get ?flow=... from the URL
  const returnTo = useGetReturnUrl();
  const {
    flow: flowId
  } = router.query;

  // In this effect we either initiate a new registration flow, or we fetch an existing registration flow.
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (!router.isReady || flow) {
      return;
    }
    const init = async (innerFlowId = "") => {
      // If ?flow=.. was in the URL, we fetch it
      if (innerFlowId) {
        try {
          setLoading(true);
          const {
            data
          } = await ory.getSettingsFlow({
            id: String(innerFlowId)
          });
          setFlow(data);
        } catch (error) {
          // if there is error like aal2 required
          // then allow browser to redirect or take any other action
          handleFlowError(router, paths.settings, setFlow)((error as AxiosError<AxiosErrorType>));
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const {
            data
          } = await ory.createBrowserSettingsFlow({
            returnTo: returnTo ? String(returnTo) : undefined
          });
          if (!noRedirect) {
            // update flow id in url
            const urlQuery = {
              query: {
                flow: data?.id,
                return_to: returnTo
              }
            };

            // useSettingsFlow is common hook and can be used at many places like settings, mfa page
            // so here we are using router.pathname to support all pages instead of keeping path.settings
            router.push(router.pathname, urlQuery, {
              shallow: true
            });
          }
          setFlow(data);
        } catch (error) {
          handleFlowError(router, paths.settings, setFlow)((error as AxiosError<AxiosErrorType>));
        } finally {
          setLoading(false);
        }
      }
    };
    init((flowId as string)).then(noop, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, router.isReady, returnTo]);
  const onSubmit = useCallback(async (values: UpdateSettingsFlowBody) => {
    try {
      setSubmitting(true);
      const res = await ory.updateSettingsFlow({
        flow: String(flow?.id),
        updateSettingsFlowBody: values
      });

      // below block is useful when we render settings page in vID app.
      // in vID app we are using webview to render reset password flow
      // in last step of reset password flow, user lands on settings page where they set password
      // after they set the password, we need to tell vID app to close the screen
      // this can be done by calling window.ReactNativeWebView.postMessage
      // @see https://github.com/react-native-webview/react-native-webview/blob/master/docs/Guide.md#the-windowreactnativewebviewpostmessage-method-and-onmessage-prop
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          page: "settings",
          message: "Submitted Successfully",
          status: 1
        }));
      }
      setFlow(res.data);
      setSubmitting(false);
      return res.data;
    } catch (error) {
      setSubmitting(false);
      if (error instanceof AxiosError && error.response?.status === 400) {
        setFlow(error.response?.data);
      }
      throw error;
    }
  }, [flow]);
  return useMemo(() => {
    if (flow) {
      // dont render secondary emails input
      // as we will have a new UI soon that will handle secondary emails
      flow.ui.nodes = flow.ui.nodes.filter(item => (item?.attributes as any)?.name !== "traits.secondary_emails");
    }
    return {
      loading,
      flow,
      submitting,
      onSubmit
    };
  }, [loading, flow, submitting, onSubmit]);
};