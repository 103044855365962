import { BodyLSecondary, BodyMEmphasis, BodyMPrimary, ButtonColorFamilyEnum, Form, HeadingH1, Modal, PrimaryButton, SubheadingMPrimary, TextButton, TextVariantEnum, Typography, colors, isEmptyObject } from "@cerebruminc/cerebellum";
import type { CreateApiKeyDto, FindOneApiKeyResponseDto } from "@cerebruminc/neuron-sdk";
import { type FormikProps, type FormikTouched, setNestedObjectValues } from "formik";
import upperFirst from "lodash/upperFirst";
import { useCallback, useRef, useState } from "react";
import { styled } from "styled-components";
import { showError, showMessage, showSuccess } from "src/helpers";
import { useCreateApiKeyMutation } from "src/hooks";
const PrefixText = styled(BodyMEmphasis)`
	margin-bottom: 8px;
	margin-top: 8px;
`;
const Divider = styled.div`
	background-color: ${colors.COOL_GREY_20};
	height: 1px;
	margin-bottom: 32px;
	margin-top: 32px;
`;
const KeyBox = styled.div`
	align-items: center;
	border-radius: 6px;
	border: solit 1px ${colors.COOL_GREY_25};
	display: flex;
	height: 56px;
	justify-content: center;
	margin-top: 28px;
  margin-bottom: 28px;
`;
const Actions = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
`;
type Props = {
  visible: boolean;
  onSuccess: () => void;
  onClose: () => void;
  apiKey?: FindOneApiKeyResponseDto;
};

/**
 * Modal to render api key creation form & api key details
 */
export const CreateApiKeyModal = (props: Props) => {
  const formRef = useRef<FormikProps<any>>(null);
  const {
    visible,
    onSuccess,
    onClose,
    apiKey
  } = props;
  const [showInfo, setShowInfo] = useState(false);
  const {
    mutateAsync: createApiKeyMutation,
    data,
    isLoading: creating
  } = useCreateApiKeyMutation();

  /**
   * close modal
   * also reset show info boolean so that next time user open create modal it will be in inital stage
   */
  const closeModal = useCallback(() => {
    setShowInfo(false);
    onClose();
  }, [onClose]);

  /**
   * method to create api key
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const createApiKey = useCallback(async (params: CreateApiKeyDto) => {
    try {
      await createApiKeyMutation({
        name: params.name
      });
      onSuccess();
      showSuccess("Api Key created successfully");
      setShowInfo(true);
    } catch (error: any) {
      const message = upperFirst(error?.response?.data?.message?.[0] ?? error?.message ?? "An error occurred");
      showError(message);
    }
  }, [createApiKeyMutation, onSuccess, setShowInfo]);
  const onSubmit = useCallback((values: unknown) => {
    createApiKey(((values as unknown) as CreateApiKeyDto));
  }, [createApiKey]);
  const externalSubmit = async () => {
    if (formRef.current) {
      const errors = await formRef.current.validateForm();
      const noErrors = isEmptyObject(errors);
      if (noErrors) {
        formRef.current.handleSubmit();
      } else {
        formRef.current.setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true));
      }
    }
  };

  /**
   * render api key creation form
   */
  const renderForm = () => {
    return <>
        <Form innerRef={formRef} title={"Add API Key"} fields={[{
        fieldLabel: "Name",
        name: "name",
        initialValue: apiKey?.name || "",
        options: {
          clearButton: true
        },
        required: true
      }]} submit={onSubmit} hideSubmitButton />
        <div className="pt-8 border-t-2 border-gray-300 -mt-[74px]">
          <Typography variant={TextVariantEnum.BodySEmphasis}>Note:</Typography>
          <Typography variant={TextVariantEnum.BodySSecondary}>
            This API key will inherit your current role permissions.
          </Typography>
          <div className="flex flex-col items-center pt-10">
            <PrimaryButton text="Generate API Key" onClick={externalSubmit} colorFamily={ButtonColorFamilyEnum.Blue} loading={creating} shadow />
          </div>
        </div>
      </>;
  };

  /**
   * render api key details once user create api key successfully
   */
  const renderDetails = () => {
    return <>
        <SubheadingMPrimary>Api Key Generated</SubheadingMPrimary>
        <HeadingH1>Save your Api Key</HeadingH1>
        <PrefixText>Prefix Identifier</PrefixText>
        <BodyMPrimary>{data?.prefix}</BodyMPrimary>
        <Divider />
        <BodyMEmphasis>API Key</BodyMEmphasis>
        <BodyLSecondary textColor={colors.RED_80}>
          This will only visible once! <b>Save it now!</b>
        </BodyLSecondary>
        <KeyBox>{data?.apiKey}</KeyBox>
        <Actions>
          <PrimaryButton text="Copy API Key to Clipboard" onClick={() => {
          navigator.clipboard.writeText((data?.apiKey as string));
          showMessage("Copied to Clipboard");
        }} />
          <TextButton text="Close" onClick={closeModal} />
        </Actions>
      </>;
  };
  return (
    // @ts-ignore
    <Modal closeFunction={closeModal} paddingString="64px 66px" show={visible} slideDown minWidth={500}>
      {visible ? !showInfo ? renderForm() : renderDetails() : null}
    </Modal>
  );
};