import { Modal, PrimaryButton, TextVariantEnum, Typography } from "@cerebruminc/cerebellum";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { AcceptButtonBox } from "./LoginTermsModalStyle";
export function LoginTermsModal() {
  const router = useRouter();
  const CRA = "JD Palatine LLC";
  const [showModal, setShowModal] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const {
      query,
      pathname
    } = router;
    const isFromLogin = query.from_login === "true";
    if (isFromLogin) {
      // if user came from login then show login term modal
      setShowModal(isFromLogin);

      // remove from_login from url now
      const params = new URLSearchParams(((query as unknown) as string[][]));
      params.delete("from_login");

      // replace new url without reloading
      router.replace({
        pathname,
        query: params.toString()
      }, undefined, {
        shallow: true
      });
    }
  }, [router?.query, router?.pathname]);
  const onClose = () => {
    setShowModal(false);
  };
  return <Modal paddingString="64px 66px" show={showModal}>
      <Typography variant={TextVariantEnum.HeadingH1}>Terms</Typography>
      <div className="py-6">
        <Typography variant={TextVariantEnum.BodyMPrimary}>
          The accuracy of the data submitted by the requestor will impact the accuracy of the results obtained. While
          the information furnished is from reliable sources, its accuracy is not guaranteed, Use of available data is
          subject to the Federal Credit Reporting Act (FCRA) and other applicable law. For any information used for
          employment purposes, THE REQUESTOR CERTIFIES THE FOLLOWING:
        </Typography>
        <br />
        <Typography variant={TextVariantEnum.BodyMPrimary}>
          {`Clear and conspicuous written disclosure will be made to the consumer, prior to obtaining the report, in a document that consists solely of the disclosure, that a consumer report may be obtained. Requestor will obtain written authorization from the consumer which will be made available to ${CRA} upon request. The information obtained will not be used in violation of any federal or state equal opportunity law or regulation. If requestor intends to take any adverse action based in whole or in part on the consumer report, a copy of the report and a summary of the consumer's rights will be provided to the consumer prior to taking adverse action.`}
        </Typography>
        <br />
        <Typography variant={TextVariantEnum.BodyMPrimary}>
          Section 619 - 1681q. Obtaining Information Under False Pretenses. Any person who knowingly and willfully
          obtains information on a consumer from a consumer reporting agency under false pretenses shall be fined under
          Title 18, United States Code, imprisoned for not more than 2 years, or both.
        </Typography>
      </div>
      <AcceptButtonBox>
        <PrimaryButton onClick={onClose} text={"Accept"} />
      </AcceptButtonBox>
      <br />
    </Modal>;
}