export const roleData = {
  superAdmin: {
    name: "Super Admin",
    description:
      "Super Admins may add, configure, and edit organizations, suborganizations, and users. They may also process orders.",
  },
  admin: {
    name: "Organization Admin",
    description:
      "Organization Admins have limited access to organization-level controls. They may add/edit users and process orders.",
  },
  user: {
    name: "User",
    description: "Users can submit orders.",
  },
  integrationAdmin: {
    name: "Integration Admin",
    description:
      "Integration Admins have the same access controls as Organization Admins, but can view unmasked PII data",
  },
  processor: {
    name: "Processor",
    description: "Processors can view and process orders for affiliate organizations",
  },
};

export enum RoleEnum {
  IntegrationAdmin = "INTEGRATION_ADMIN",
  OrganizationAdmin = "ORGANIZATION_ADMIN",
  Processor = "PROCESSOR",
  SuperAdmin = "SUPER_ADMIN",
  User = "USER",
}
