import { useAtom } from "jotai";
import { filterModalFiltersAtom } from "../atom";
import { updateFilterModalFilters } from "../helpers";
import type { CallUpdateFilterModalFiltersType } from "../types";

export const useUpdateFilterModalFilters = () => {
  const [filterModalFilters, setFilterModalFilters] = useAtom(filterModalFiltersAtom);

  function callUpdateFilterModalFilters({ filterIdArray, newValue, tabId }: CallUpdateFilterModalFiltersType) {
    updateFilterModalFilters({
      filterModalFilters,
      filterIdArray,
      newValue,
      setFilterModalFilters,
      tabId,
    });
  }

  return callUpdateFilterModalFilters;
};
