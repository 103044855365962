import { BodySSemiBold, Scroller, colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";

// ------ AddedApplicants ------
export const AddedApplicantsBase = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 10px;
  grid-row-start: middle;
  grid-row-end: bottom;
  min-height: 317px;
  min-width: 0;
  height: 100%;
  padding: 32px 10px 0px;
`;
export const Header = styled.div`
  color: ${colors.COOL_GREY_60};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 14px;
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
`;
export const TableBox = styled(Scroller)<{
  $heightLimit?: number;
}>`
  height: calc(100% - 46px);
  max-height: ${({
  $heightLimit
}) => $heightLimit ? `${$heightLimit}px` : "inherit"};
  min-height: 217px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const SearchBox = styled.div`
  position: absolute;
  right: 5px;
  top: 0px;
  width: calc(100% - 155px);
  z-index: 20;
  padding: 0 2px;
  overflow: hidden;
`;
export const TableWrapper = styled.div`
  margin: 0 -9px;
  overflow: hidden;
`;
export const ApplicantBox = styled.div`
  position: relative;
`;
export const AlertBox = styled.span`
  display: inline-block;
  line-height: 12px;
  padding-left: 11px;
  position: relative;
  top: 1px;
`;
export const EmptyTable = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 60px;
`;
export const EmptyGraphicBox = styled.div`
  height: 126px;
  margin: 0 auto 27px;
  width: 89px;
`;
export const EmptyText = styled(BodySSemiBold)`
  color: ${colors.COOL_GREY_65};
  text-align: center;
`;