import { styled } from "styled-components";
import { SPINNER_SIZE, Spinner } from "./Spinner";
import { colors } from "@cerebruminc/cerebellum";
const HALF = SPINNER_SIZE / 2;
const Wrapper = styled.div`
  background-color: ${colors.WHITE};
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
`;
const SpinnerPosition = styled.div`
  height: ${SPINNER_SIZE}px;
  left: calc(50% - ${HALF}px);
  position: absolute;
  top: calc(50% - ${HALF}px);
  width: ${SPINNER_SIZE}px;
`;
export const WholePageLoader = () => {
  return <Wrapper id="whole-page-loader">
      <SpinnerPosition>
        <Spinner />
      </SpinnerPosition>
    </Wrapper>;
};