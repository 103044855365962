// This removes all hyphens and capitalizes each word. Useful for making back end statuses more legible
export const noHyphensCapitalize = (string?: string) => {
  if (!string) return "";
  const noHyphens = string.replace("-", " ");
  const eachWordCapitalized = noHyphens
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
  return eachWordCapitalized;
};
