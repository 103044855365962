import { styled } from "styled-components";
import { Nav } from "src/components";
const Wrapper = styled.div`
	height: 100%;
	padding-bottom: 50px;
`;
const MainPageContainer = styled.div`
	align-items: flex-start;
	background-color: #fff;
	display: flex;
	height: 100%;
	justify-content: center;
	padding-top: 94px;
  background-attachment: fixed;
  background-image: url(/static/img/header-fade.png);
  background-position: 17.5% 94px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  @media screen and (max-width: 750px) {
    background-size: auto 90%;
    background-position: 40% 94px;
  }
`;
interface ComponentProps {
  children: React.ReactNode;
  showUserControls?: boolean;
}

/**
 * Main Page Layout component
 * We can pass childrent to this component
 * @returns React Node
 */
export function MainPageLayoutNeuron(props: Readonly<ComponentProps>) {
  const {
    children,
    showUserControls
  } = props;
  return <Wrapper id="page-wrapper">
      <Nav navLogoImageSource={"/static/img/vid_mark.svg"} navLogoAltText={"Cerebrum Logo"} showUserControls={showUserControls} />
      <MainPageContainer id="content-wrapper">{children}</MainPageContainer>
    </Wrapper>;
}