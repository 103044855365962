import type { ActiveFiltersType, FilterType } from "@cerebruminc/cerebellum";
import { atom } from "jotai";
import type { WindowSizeType } from "src/types";
import type { FiltersObjectType } from "../../views/types";
import { type ExtendedFilterType, type SearchTypesEnumValues } from "../../views/OrdersView/types";
import { GenericFilterModalFiltersType } from "./types";

// These atoms needs to be wrapped in a provider with a unique store for each
// instance of the filters modal. This is because atoms are global and we don't
// want the filters modal to share state between instances. Failing to wrap with
// a provider will cause the filters modal to share state between instances and
// will cause unexpected behavior.

// The active filter state - this is displayed in FiltersBar and in the Table. It's what actually filters the data
export const activeFiltersAtom = atom<FilterType[]>([]);
export const activeTableFiltersAtom = atom<ActiveFiltersType>({}); // requires a very different format than the rest of the components

export const isPackagesLoadingAtom = atom<boolean>(false);

// filterModalFilters is an object with the data for the RadioGroups or CheckboxGroups in the FilterModal
// - it gets updated whenever activeFilters does
// - it also gets updated when the FilterModal radios or checkboxes are changed
// - it gets reset to the activeFilter state when FilterModal closes (whether the filters are applied or not)
export const filterModalFiltersAtom = atom<GenericFilterModalFiltersType>({});
export const filterModalCustomFromAtom = atom<number | undefined>(undefined);
export const tempFilterModalCustomFromAtom = atom<number | undefined>(undefined);
export const filterModalCustomToAtom = atom<number | undefined>(undefined);
export const tempFilterModalCustomToAtom = atom<number | undefined>(undefined);

export const draftFromDateAtom = atom<number | undefined>(undefined);
export const draftToDateAtom = atom<number | undefined>(undefined);

export const availableFiltersAtom = atom<FiltersObjectType>({});
export const currentSearchTypeAtom = atom<SearchTypesEnumValues | undefined>(undefined);
export const customDateFilterFromAtom = atom<number | undefined>(undefined);
export const customDateFilterToAtom = atom<number | undefined>(undefined);
export const defaultToDateTabAtom = atom<boolean>(false);
export const filtersModalOpenAtom = atom<boolean>(false);

export const orderTableCurrentPageAtom = atom<number>(0);
export const totalRelevantOrdersAtom = atom<number>(0);
export const ordersViewLoadingAtom = atom<boolean>(false);

export const editSearchModeAtom = atom<boolean>(false);
export const searchInputValueAtom = atom<string>("");
export const searchTagsAtom = atom<ExtendedFilterType[]>([]);

export const showClearAllToastAtom = atom<boolean>(false);
export const stashedFiltersAtom = atom<ActiveFiltersType>({});
export const stashedSearchTagsAtom = atom<ExtendedFilterType[]>([]);
export const windowSizeAtom = atom<WindowSizeType>({ height: 0, width: 0 });

export const draftFiltersChangedAtom = atom((get) => {
  const draftState = get(filterModalFiltersAtom);
  const tableState = get(activeTableFiltersAtom);
  const draftFromDate = get(draftFromDateAtom);
  const draftToDate = get(draftToDateAtom);
  const tableFromDate = get(filterModalCustomFromAtom);
  const tableToDate = get(filterModalCustomToAtom);
  return {
    draftState,
    tableState,
    draftFromDate,
    draftToDate,
    tableFromDate,
    tableToDate,
  };
});
