import { SettingsFlow } from "@ory/kratos-client";
import { useSession } from "./useSession";
import { getGroupNodes } from "src/helpers";
export const useDownloadRecoveryCodes = () => {
  const {
    session
  } = useSession();
  const getSecretCodes = (updatedFlow: SettingsFlow | undefined): string[] => {
    const nodes = getGroupNodes(updatedFlow?.ui.nodes, "lookup_secret");
    // console.log({ nodes });
    const codesNodeAttributes = nodes.find(node => node.attributes && "id" in node.attributes && node.attributes.id === "lookup_secret_codes")?.attributes;
    // console.log({ codesNodeAttributes });

    if (codesNodeAttributes && "text" in codesNodeAttributes) {
      const codes = codesNodeAttributes.text.text.split(",").map(item => item.trim())
      // for already used backup codes it will return "used" word
      // which we don't need to show to user
      .filter(item => item !== "used");
      return codes || [];
    }
    return [];
  };
  const downloadCodes = (flow: SettingsFlow | undefined) => {
    const email = session?.identity?.verifiable_addresses?.find(item => item.via === "email")?.value;
    const backupCodes = getSecretCodes(flow);
    // console.log({ backupCodes });

    const content = `Backup codes for ${email}:\n${backupCodes.join("\n")}`;
    const blob = new Blob([content], {
      type: "text/plain"
    });
    const link = document.createElement("a");
    link.download = "cerebrum_backup_codes.txt";
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return downloadCodes;
};