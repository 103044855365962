// The 'env' constant is defined using an Immediately Invoked Function Expression (IIFE).
// This design pattern allows the creation of a new lexical scope, allowing complex conditional logic to be used to define the 'env' constant.
// The IIFE is executed right after its definition, initializing the 'env' constant with the appropriate environment variables.
// Note that these values are known public values, that can only be used by code running on a whitelisted set of domains, so don't freak out!
export const env = (() => {
  const url = typeof window !== "undefined" ? window.location.origin : process.env.HOSTNAME || "";

  if (url.includes("://cognition.cerebrum.com")) {
    return {
      BRANCH_IO_URL: "usevid.app",
      COGNITION_URL: "https://cognition.cerebrum.com/",
      CORTEX_URL: "https://cortex.cerebrum.com/",
      GOOGLE_MAPS_API_KEY: "AIzaSyBeUbmSUzQ7WIy3uPFvVw7GFwQHu79zSSA",
      LAUNCHDARKLY_API_KEY: "63e16908595c0a1373ff2bb8",
      MIXPANEL_TOKEN: "6b5d9f26a6ce3d171248b78a6e3283dd",
      NEURON_API: "https://neuron-api.api.cerebrum.com",
      PRIVACY_POLICY_URL: "https://www.cerebrum.com/privacy-policy",
      SERVER_URL: "https://api.cerebrum.com",
      TERMS_AND_CONDITIONS_URL: "https://www.cerebrum.com/terms",
      SOCKET_URL: "https://socket.api.cerebrum.com",
    };
  }

  if (url.includes("://cognition.demo.cerebrum.com")) {
    return {
      BRANCH_IO_URL: "usevidapp.com",
      COGNITION_URL: "https://cognition.demo.cerebrum.com/",
      CORTEX_URL: "https://cortex.demo.cerebrum.com/",
      GOOGLE_MAPS_API_KEY: "AIzaSyBPLyM85ba7A-v90OaIAfcN9lrVK3XFuPE",
      LAUNCHDARKLY_API_KEY: "651ed478d7a4a61255b3be1c",
      NEURON_API: "https://neuron-api.api.demo.cerebrum.com",
      PRIVACY_POLICY_URL: "https://www.cerebrum.com/privacy-policy",
      SERVER_URL: "https://api.demo.cerebrum.com",
      TERMS_AND_CONDITIONS_URL: "https://www.cerebrum.com/terms",
      SOCKET_URL: "https://socket.api.demo.cerebrum.com",
    };
  }

  if (url.includes("://cognition.staging.cerebrum.com")) {
    return {
      BRANCH_IO_URL: "usevidapp.com",
      COGNITION_URL: "https://cognition.staging.cerebrum.com/",
      CORTEX_URL: "https://cortex.staging.cerebrum.com/",
      GOOGLE_MAPS_API_KEY: "AIzaSyBWx65P96LSAjR8w9fkbDatb_JUemD24g8",
      LAUNCHDARKLY_API_KEY: "63e253f2b925c71376f34f14",
      MIXPANEL_TOKEN: "a2bcb8af24804ab5d37d86fedf89fd96",
      NEURON_API: "https://neuron-api.api.staging.cerebrum.com",
      PRIVACY_POLICY_URL: "https://www.cerebrum.com/privacy-policy",
      SERVER_URL: "https://api.staging.cerebrum.com",
      TERMS_AND_CONDITIONS_URL: "https://www.cerebrum.com/terms",
      SOCKET_URL: "https://socket.api.staging.cerebrum.com",
    };
  }

  // In local mode we want to route to local domains
  if (url.includes("://cognition.local.cerebrum.com")) {
    return {
      AUTH_SYSTEM_PRODUCTS_URL: "https://neuron.dev.cerebrum.com/products",
      AUTH_SYSTEM_SETTINGS_URL: "https://neuron.dev.cerebrum.com/settings",
      BRANCH_IO_URL: "usevidapp.com",
      COGNITION_URL: "https://cognition.local.cerebrum.com/",
      CORTEX_URL: "https://cortex.local.cerebrum.com/",
      GOOGLE_MAPS_API_KEY: "AIzaSyBPLyM85ba7A-v90OaIAfcN9lrVK3XFuPE",
      LAUNCHDARKLY_API_KEY: "63e16908595c0a1373ff2bb7",
      MIXPANEL_TOKEN: "92b85ee63d27638904055c91004545fa",
      NEURON_API: "https://neuron-api.local.cerebrum.com",
      PRIVACY_POLICY_URL: "https://www.cerebrum.com/privacy-policy",
      SERVER_URL: "https://cortex-api.local.cerebrum.com",
      TERMS_AND_CONDITIONS_URL: "https://www.cerebrum.com/terms",
      SOCKET_URL: "https://socket.local.cerebrum.com",
    };
  }

  // Otherwise default to values for development environment
  return {
    BRANCH_IO_URL: "usevidapp.com",
    COGNITION_URL: "https://cognition.dev.cerebrum.com/",
    CORTEX_URL: "https://cortex.dev.cerebrum.com/",
    GOOGLE_MAPS_API_KEY: "AIzaSyBPLyM85ba7A-v90OaIAfcN9lrVK3XFuPE",
    LAUNCHDARKLY_API_KEY: "63e16908595c0a1373ff2bb7",
    MIXPANEL_TOKEN: "92b85ee63d27638904055c91004545fa",
    // Allow overriding the NEURON_API value with an environment variable for testing
    NEURON_API: process.env.NEXT_PUBLIC_NEURON_API || "https://neuron-api.api.dev.cerebrum.com",
    PRIVACY_POLICY_URL: "https://www.cerebrum.com/privacy-policy",
    SERVER_URL: "https://api.dev.cerebrum.com",
    TERMS_AND_CONDITIONS_URL: "https://www.cerebrum.com/terms",
    SOCKET_URL: "https://socket.api.dev.cerebrum.com",
  };
})();

export const graphqlEndpointUrl = `${env.SERVER_URL}/graphql`;
