import type { User } from "@cerebruminc/neuron-sdk";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { neuronSdk } from "src/helpers/neuron";
let authCache = ({} as {
  data: User;
  time: number;
});
export const useGetUserInfo = () => {
  const router = useRouter();
  const [userData, setUserData] = useState<undefined | User>();
  useEffect(() => {
    (async () => {
      if (!router.isReady) return;
      try {
        const cacheSince = authCache?.time ? new Date().getTime() - authCache?.time : 0;
        const isCacheExpired = cacheSince / (1000 * 60) > 2; // 2 minutes cache expiry time

        // check for cached data
        if (authCache.data && !isCacheExpired) {
          setUserData(authCache.data);
          return;
        }
        const userRes = await neuronSdk.whoAmI();
        authCache = {
          data: userRes.data,
          time: new Date().getTime()
        };
        setUserData(userRes.data);
      } catch (error) {
        // do something here?
      }
    })();
  }, [router]);
  return (userData as User);
};