import { Button, ButtonVariantEnum, Modal, TextVariantEnum, Typography, colors, useMediaQuery } from "@cerebruminc/cerebellum";
import { useAtom } from "jotai";
import React from "react";
import { styled } from "styled-components";
import { ssnInfoModalVisibleAtom } from "../state";
const ModalContents = styled.div`
  max-width: 490px;
  @media all and (max-width: 600px) {
    padding-bottom: 80px;
  }
`;
const Section = styled.div`
  padding-top: 24px;
`;
const ButtonBox = styled.div`
  padding-top: 34px;
  padding-bottom: 22px;
  display: flex;
  justify-content: center;
`;
const TextWrapper = styled.div`
  margin-bottom: 5px;
`;
const VidSsnInfoModal = () => {
  const [modalVisible, setModalVisible] = useAtom(ssnInfoModalVisibleAtom);
  const smallPageBreak = useMediaQuery(550);
  return <Modal minWidth={623} closeFunction={() => setModalVisible(false)} show={modalVisible} paddingString={smallPageBreak ? "64px 35px 30px" : undefined}>
      <ModalContents className="ModalContents">
        <Typography textColor={colors.BLUE_75} variant={TextVariantEnum.BodyLPrimary}>
          vID&apos;s Data Security
        </Typography>
        <Typography variant={TextVariantEnum.HeadingH1}>Keeping you and your data safe.</Typography>
        <Section>
          <TextWrapper>
            <Typography variant={TextVariantEnum.BodyLEmphasis} lineHeight={24}>
              Why do you need my social security number?
            </Typography>
          </TextWrapper>
          <Typography variant={TextVariantEnum.BodyMPrimary}>
            It is of paramount importance that we provide the most comprehensive and accurate identity verification that
            we can. vID uses your social security number to confirm your identity and, if required by a certifying
            organization, run a thorough background check.
          </Typography>
        </Section>
        <Section>
          <TextWrapper>
            <Typography variant={TextVariantEnum.BodyLEmphasis} lineHeight={24}>
              How do you keep my data secure?
            </Typography>
          </TextWrapper>
          <Typography variant={TextVariantEnum.BodyMPrimary}>
            We take data security extremely seriously. In addition to encrypting and protecting all of your sensitive
            information, we have stringent security protocols that ensure your data is never viewed by anyone who is not
            completing an ID or background check on your behalf. Additionally, we keep detailed records of your
            data&apos;s view and use history to ensure complete care and responsibility of your personal information.
          </Typography>
        </Section>
        <ButtonBox>
          <Button shadow={true} onClick={() => setModalVisible(false)} variant={ButtonVariantEnum.Primary} text="Ok" />
        </ButtonBox>
      </ModalContents>
    </Modal>;
};
export default VidSsnInfoModal;