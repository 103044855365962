import { type ColumnType, ConfirmModal, ConnotationColorEnum, DialogHandler, Edit, type OptionClickResponseType, SnackTypeEnum, Table, TableOptionLabel, TextStyleEnum, TablePaginationType, RowType } from "@cerebruminc/cerebellum";
import { type User, UserOrderFirstnameEnum } from "@cerebruminc/neuron-sdk";
import { useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import React, { type FC, useCallback, useState, useEffect } from "react";
import { currentOrgIdAtom } from "src/atom";
import { paths } from "src/const";
import { useGetAllUsers, useUpdateUserMutation, useUserOrgRole, useWhoAmI } from "src/hooks";
import { InviteOrEditModal } from "./../InviteOrEditModal";
import { NameCell, RoleCell } from "./componentWrappers";
import { roleChangeDescription, roleChangeHeader } from "./helpers";
import { UserTableFilterIds, UserTableSortEnum, type UserTableType } from "./types";
import FiltersModal from "src/components/FiltersModal";
import { OrganizationsForm } from "./FilterForms/OrganizationsForm";
import { RoleForm } from "./FilterForms/RoleForm";
import { activeTableFiltersAtom } from "src/components/FiltersModal/atom";
const EDIT_ID = "edit";
export const ROWS_PER_PAGE = 30;
const emailColumn: ColumnType = {
  cellId: UserTableSortEnum.EMAIL,
  label: "Email",
  minWidth: 80,
  textStyle: TextStyleEnum.Tertiary,
  width: 10
};
const nameColumn: ColumnType = {
  cellId: UserTableSortEnum.FIRST_NAME,
  label: "Full Name",
  textStyle: TextStyleEnum.Tertiary,
  width: 10,
  minWidth: 240,
  reverseSort: true,
  ComponentWrapper: NameCell
};
const roleColumn: ColumnType = {
  cellId: "roles",
  disableSort: true,
  fixedWidth: true,
  label: "Role",
  textStyle: TextStyleEnum.Secondary,
  width: 230,
  ComponentWrapper: RoleCell
};
export const UserTable: FC<UserTableType> = ({
  findByEmail
}) => {
  const {
    cogUserPage
  } = useFlags();
  const router = useRouter();
  const {
    mutateAsync: updateUserMutation
  } = useUpdateUserMutation();
  const [currentPage, setCurrentPage] = useState(0);
  const [tableSort, setTableSort] = useState<{
    columnId?: string;
    sortAsc: UserOrderFirstnameEnum;
  }>({
    columnId: (router.query.columndId as UserTableSortEnum) || UserTableSortEnum.FIRST_NAME,
    sortAsc: (router.query.sortAsc as UserOrderFirstnameEnum) || UserOrderFirstnameEnum.Asc
  });
  const [modalData, setModalData] = useState<{
    row?: RowType;
    newRole?: any;
    show?: boolean;
    setActiveId?: (id: any) => void;
  }>({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const tableState = useAtomValue(activeTableFiltersAtom);
  const currentUserRole = useUserOrgRole();
  const {
    data: loggedInUser
  } = useWhoAmI();
  const currentOrgId = useAtomValue(currentOrgIdAtom);
  const allOrgsVisible = currentOrgId === null;
  roleColumn.label = allOrgsVisible ? "Roles" : "Role";
  const columns = [nameColumn, emailColumn, roleColumn];
  let where = {};
  if (findByEmail) {
    where = {
      ...where,
      email: findByEmail
    };
  }
  if (allOrgsVisible && tableState.organization?.length) {
    where = {
      ...where,
      organizations: tableState.organization
    };
  } else {
    where = {
      ...where,
      organizations: currentOrgId ? [currentOrgId] : []
    };
  }
  if (tableState.roles?.length) {
    where = {
      ...where,
      roles: tableState.roles
    };
  }
  const {
    data,
    refetch: refetchUsers,
    isFetching
  } = useGetAllUsers({
    currentPage,
    columnId: tableSort.columnId,
    rowsPerPage: ROWS_PER_PAGE,
    sortAsc: tableSort.sortAsc,
    where
  });
  const {
    data: rows,
    total
  } = data || {};

  // biome-ignore lint/correctness/useExhaustiveDependencies: Refresh when rows change
  const onOptionClick = useCallback(({
    option,
    row
  }: OptionClickResponseType) => {
    const {
      id
    } = option;
    if (id === EDIT_ID) {
      setShowEditModal(true);
      setUserToEdit((row as User));
    }
  }, [rows]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Refresh when rows change
  const onRowClick = useCallback(({
    id
  }: RowType) => {
    router.push(`${paths.user}/${id}`);
  }, [rows]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const onTableSort = useCallback((column: ColumnType, sortAsc: boolean) => {
    if (column.cellId === "firstname" || column.cellId === "email") {
      setTableSort({
        columnId: column.cellId,
        sortAsc: sortAsc ? UserOrderFirstnameEnum.Desc : UserOrderFirstnameEnum.Asc
      });
    }
  }, [setCurrentPage]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const onPagination = useCallback((paginationProps: TablePaginationType) => {
    const newPage = paginationProps.currentPage;
    setCurrentPage(newPage);
  }, [setCurrentPage]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const onRoleChange = useCallback(async (role: RowType) => {
    try {
      if (modalData.row) {
        await updateUserMutation({
          id: (modalData.row.id as string),
          firstName: modalData?.row?.firstname,
          middleName: modalData?.row?.middlename || "",
          lastName: modalData?.row?.lastname,
          roles: [{
            name: role.name,
            organizationId: modalData.row.organization?.id
          }]
        }).then(() => modalData.setActiveId?.(role.value));
        refetchUsers();
        DialogHandler.show({
          text: "Role Changed",
          snackType: SnackTypeEnum.Toast,
          colorFamily: ConnotationColorEnum.Positive
        });
      }
    } catch (error: any) {
      let errorMsg = error?.response?.data?.message ?? error?.message ?? `There was an error changing the role for ${modalData?.row?.firstname || ""} ${modalData?.row?.lastname || ""}`;
      if (errorMsg === "Forbidden") {
        errorMsg = "You do not have permission to change user roles";
      }
      DialogHandler.show({
        text: errorMsg,
        colorFamily: ConnotationColorEnum.Negative,
        snackType: SnackTypeEnum.Bar
      });
    }
  }, [modalData.row, modalData.setActiveId]);

  // Filter modal state
  const [activeTab, setActiveTab] = useState(UserTableFilterIds.Role);
  const onTabChange = (id: string) => {
    setActiveTab((id as UserTableFilterIds));
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: I want this to run whenever allOrgsVisible change
  useEffect(() => {
    setActiveTab(UserTableFilterIds.Role);
  }, [allOrgsVisible]);
  const filtersModalFilters = [{
    id: UserTableFilterIds.Role,
    label: "Role",
    payload: <RoleForm allFilters={UserTableFilterIds} />
  }];
  if (allOrgsVisible) {
    filtersModalFilters.push({
      id: UserTableFilterIds.Organization,
      label: "Organization",
      payload: <OrganizationsForm allFilters={UserTableFilterIds} />
    });
  }

  // Reset the pagination whenever the query changes
  const whereString = JSON.stringify(where);
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this to run whenever whereString changes, but don't actually need to use whereString.
  useEffect(() => {
    setCurrentPage(0);
  }, [whereString]);
  return <>
      <FiltersModal activeTab={activeTab} sidebarWidth={240} filters={filtersModalFilters} onTabChange={onTabChange} />
      <Table clickHeader={onTableSort} clickOption={onOptionClick} clickRow={cogUserPage ? onRowClick : undefined} columns={columns} fetching={isFetching} fetchingRowsNumber={ROWS_PER_PAGE} onPagination={onPagination} dynamicOptions={({
      options,
      row
    }) => row.id === loggedInUser?.id ? undefined : options} options={currentUserRole === "USER" ? [] : [{
      id: EDIT_ID,
      label: <TableOptionLabel Icon={Edit} label="Edit" />
    }]} parentSort paginationStart={currentPage} rows={rows || []} rowGrow rowsPerPage={ROWS_PER_PAGE} totalItems={total} />
      <ConfirmModal header={roleChangeHeader} text={roleChangeDescription((modalData?.row?.firstname || "") + (modalData?.row?.lastname ? ` ${modalData?.row?.lastname}` : ""), modalData?.newRole?.name.toString())} show={modalData.show || false} primaryButtonText={"Confirm"} secondaryButtonText={"Nevermind"} onPrimaryClick={() => {
      onRoleChange((modalData.newRole as RowType));
      setModalData({
        ...modalData,
        show: false
      });
    }} onSecondaryClick={() => setModalData({
      ...modalData,
      show: false
    })} onXClick={() => setModalData({
      ...modalData,
      show: false
    })} themeOverride={{
      primaryButtonWidth: 150,
      secondaryButtonWidth: 150
    }} />
      <InviteOrEditModal onError={error => {
      setShowEditModal(false);
      console.log({
        error
      });
      DialogHandler.show({
        text: "There was an error saving your changes",
        colorFamily: ConnotationColorEnum.Negative,
        snackType: SnackTypeEnum.Bar
      });
    }} onSuccess={() => {
      setShowEditModal(false);
      refetchUsers();
      DialogHandler.show({
        text: "User Information Saved",
        colorFamily: ConnotationColorEnum.Neutral,
        snackType: SnackTypeEnum.Bar
      });
    }} onXClick={() => setShowEditModal(false)} show={showEditModal} submitText="Update User" user={userToEdit} />
    </>;
};