import { Avatar, BodySEmphasis, BodyXSPrimary, colors, type ComponentWrapperType } from "@cerebruminc/cerebellum";
import React, { type FC } from "react";
import { formatInviteString } from "../helpers";
import { NameBase, NamePlate } from "../UserTableStyles";
export const NameCell: FC<ComponentWrapperType> = ({
  row
}) => {
  const isInvited = row.status === "INVITED";
  const name = `${row.firstname || ""} ${row.lastname || ""}`;
  return <NameBase>
      <Avatar backgroundGradient="linear-gradient(180deg, #5377EC 0%, #C0AAFF 56.31%, #FFFFFF 100%)" imageUrl={row.avatarUrl} placeholderInitials={`${row.firstname?.slice(0, 1)}${row.lastname?.slice(0, 1)}`} themeOverride={{
      size: 50
    }} />
      <NamePlate>
        <BodySEmphasis $textColor={isInvited ? colors.COOL_GREY_65 : colors.COOL_GREY_100}>{name}</BodySEmphasis>
        {isInvited && <BodyXSPrimary $textColor={colors.COOL_GREY_65}>{formatInviteString(row.lastInviteSentAt)}</BodyXSPrimary>}
      </NamePlate>
    </NameBase>;
};