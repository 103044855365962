import { colors } from "@cerebruminc/cerebellum";
import styled from "styled-components";

export const Content = styled.div`
    margin-top: 6px;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: -15px;
`;

export const ConfirmationMessage = styled.span`
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.34px;
`;

export const DangerText = styled.span`
    color: ${colors.RED_100};
`;
