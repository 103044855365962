import { FormFieldTypeEnum, SingleOrMultipleFieldType } from "@cerebruminc/cerebellum";
import { ScanResult } from "./../types";

export const getAddressFields = (userData: ScanResult | null): Array<SingleOrMultipleFieldType> => {
  return [
    {
      header: "Current Address",
      type: FormFieldTypeEnum.SectionHeader,
      separator: true,
      separatorPosition: "bottom",
      separatorMarginString: "-10px 0 0 0",
      separatorOverflow: 127,
      nextGap: 28,
      themeOverride: {
        sectionHeaderFontSize: 22,
        sectionHeaderFontWeight: 700,
      },
    },
    {
      fieldLabel: "Address",
      initialValue: userData?.address?.addressLineOne || "",
      name: "addressLineOne",
      type: FormFieldTypeEnum.Address,
      required: true,
      nextGap: 10,
      options: {
        cityFieldName: "city",
        stateAbbrFieldName: "state",
        streetAddressOnly: true,
        zipFieldName: "postalCode",
      },
    },
    {
      fieldLabel: "Suite/Apt/etc",
      initialValue: userData?.address?.addressLineTwo || "",
      name: "addressLineTwo",
      nextGap: 10,
      type: FormFieldTypeEnum.Text,
    },
    {
      type: FormFieldTypeEnum.SplitLine,
      inlineFields: [
        {
          fieldLabel: "City",
          initialValue: userData?.address?.city || "",
          name: "city",
          splitMinWidth: 150,
          type: FormFieldTypeEnum.Text,
          required: true,
          nextGap: 10,
        },
        {
          fieldLabel: "State",
          initialValue: userData?.address?.state || "",
          name: "state",
          splitWidth: 105,
          splitWidthFixed: true,
          type: FormFieldTypeEnum.StateSelect,
          required: true,
          nextGap: 10,
          options: {
            abbreviatedStateNames: true,
          },
        },
        {
          fieldLabel: "Zip Code",
          initialValue: userData?.address?.postalCode || "",
          name: "postalCode",
          splitWidth: 145,
          splitWidthFixed: true,
          type: FormFieldTypeEnum.ZipCode,
          nextGap: 10,
          required: true,
        },
      ],
    },
  ];
};
