import { useEffect, useState } from "react";
import { isBrowser } from "src/helpers";

function getWindowDimensions() {
  const height = isBrowser ? window.innerHeight : 0;
  const width = isBrowser ? window.innerWidth : 0;
  return {
    width,
    height,
  };
}

// This hook shouldn't be used directly in most components. Get the width and height from windowSizeAtom instead to prevent adding multiple event listeners.
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(isBrowser ? getWindowDimensions() : { height: 0, width: 0 });
    }

    window?.addEventListener("resize", handleResize);
    return () => window?.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
