import { useState, useEffect } from "react";
import { BodySTertiary } from "@cerebruminc/cerebellum";
import type { UiText } from "@ory/kratos-client";
import { getMappedMessage } from "src/helpers";
interface MessagesProps {
  messages?: Array<UiText>;
}
interface ExtendedUiText extends UiText {
  context?: {
    privilegedSessionExpiresAt?: string;
  };
}

/*
  This component renders all kinds of ory form related messages
*/
export function OryMessages(props: MessagesProps) {
  const {
    messages = []
  } = props;
  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  if (messages?.length === 0) return null;
  return <>
      {messages.map((message: ExtendedUiText) => {
      // 1060001 is the id of the message that is updating their password
      if (message.id === 1060001) {
        const expiresAt = new Date(message.context?.privilegedSessionExpiresAt || "");
        const minutesRemaining = Math.floor((expiresAt.getTime() - currentTime) / 60000);
        return <BodySTertiary textColor="blue" key={message.id} className="mb-3 last:mb-0">
              You've successfully regained access to your account. Please update your password within the next{" "}
              {minutesRemaining} minute{minutesRemaining !== 1 ? "s" : ""}.
            </BodySTertiary>;
      }

      // 4000007 = error code for account already exists
      if (message.id === 4000007) {
        return <BodySTertiary textColor="red" key={message.id} className="mb-3 last:mb-0">
              An account with this email already exists.
            </BodySTertiary>;
      }

      // 1060003 = message for recovery code sent on email
      if (message.id === 1060003) return null;
      return <BodySTertiary textColor={message.type === "error" ? "red" : "blue"} key={message.id} className="mb-3 last:mb-0">
            {getMappedMessage(message)}
          </BodySTertiary>;
    })}
    </>;
}