import { type FilterType, removeById, removeString } from "@cerebruminc/cerebellum";
import { useAtom, useSetAtom } from "jotai";

import { deepCloneObject } from "src/helpers";
import {
  activeFiltersAtom,
  activeTableFiltersAtom,
  filterModalCustomFromAtom,
  filterModalCustomToAtom,
  filterModalFiltersAtom,
} from "../atom";
import { type FilterModalFiltersType } from "../../../views/OrdersView/types";

export const useClearOneFilter = () => {
  const [activeFilters, setActiveFilters] = useAtom(activeFiltersAtom);
  const [tableFilters, setTableFilters] = useAtom(activeTableFiltersAtom);
  const setFilterModalCustomFrom = useSetAtom(filterModalCustomFromAtom);
  const setFilterModalCustomTo = useSetAtom(filterModalCustomToAtom);
  const [filterModalFilters, setFilterModalFilters] = useAtom(filterModalFiltersAtom);

  const clearOneFilter = (filterObject: FilterType) => {
    const clonedFilters = deepCloneObject(tableFilters);
    const clonedFilterModalFilters = deepCloneObject(filterModalFilters);
    const clonedActiveFilters = deepCloneObject(activeFilters);

    const isTagGroup = !!filterObject.idArray;

    if (!filterObject.filterType || isTagGroup) {
      const filterType: string = filterObject.id.toLowerCase() as any as string;
      // biome-ignore lint/complexity/noForEach: <explanation>
      filterObject.idArray.forEach((element: string) => {
        const filterIdIndex = clonedActiveFilters.findIndex((filter) => filter.id === element);

        if (filterIdIndex > -1) {
          clonedActiveFilters.splice(filterIdIndex, 1);
        }
        const array = Array.isArray(clonedFilters[filterType])
          ? (clonedFilters[filterType] as string[])
          : [clonedFilters[filterType] as unknown as string];
        clonedFilters[filterType] = removeString({
          array: clonedFilters[filterType],
          string: element,
        });
      });
      setActiveFilters(clonedActiveFilters);
      clonedFilterModalFilters[filterType as keyof FilterModalFiltersType] = [];
    } else {
      const filterType = filterObject.filterType;
      setActiveFilters((filters) => removeById({ array: filters || [], id: filterObject.id }));
      const array = Array.isArray(clonedFilters[filterType])
        ? (clonedFilters[filterType] as string[])
        : [clonedFilters[filterType] as unknown as string];

      clonedFilters[filterType] = removeString({
        array,
        string: filterObject.id,
      });

      const isDate = filterType === "range";
      clonedFilterModalFilters[filterType] = isDate
        ? ""
        : removeString({
            array: clonedFilterModalFilters[filterType] as string[],
            string: filterObject.value, // always check based on value instead of label because value is unique and its being used to pass into api
          });

      if (isDate) {
        setFilterModalCustomFrom(undefined);
        setFilterModalCustomTo(undefined);
      }
    }

    setFilterModalFilters(clonedFilterModalFilters);
    setTableFilters(clonedFilters);
  };

  return clearOneFilter;
};
