import { CheckmarkCircle, colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import { AuthBoxFooter } from "./AuthBoxFooter";
import { AuthBoxHeader } from "./AuthBoxHeader";
const AuthBoxWrapper = styled.div`
	width: 100vw;
	max-width: 540px;
	padding-left: 20px;
  padding-right: 20px;
	padding-bottom: 20px;
	margin-top:24px;
	@media (min-width: 768px) {
    margin-top: 80px;
}
`;
const AuthBoxMainBox = styled.div`
	align-items: center;
	background-color: #fff;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	padding: 61px 10% 68px;
  box-shadow: 0 0 30px 5px rgba(65,98,255,0.15);
	@media (min-width: 640px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`;
const AuthBoxIcon = styled.div`
	padding: 35px;
`;
const AuthBoxIconWrapper = styled.div`
	height: 48px;
	width: 48px;
	margin-top: 8px;
  margin-bottom: 8px;
`;
interface ComponentProps {
  children: React.ReactNode;
  headerText: string;
  leftText?: boolean;
  showBackButton: boolean;
  backButtonFunction?: unknown;
  shouldShowFooter?: boolean;
  boxClassName?: string;
  iconUrl?: string;
  successIcon?: boolean;
  id?: string;
}
export function AuthBox(props: ComponentProps) {
  const {
    children,
    headerText,
    leftText,
    showBackButton,
    backButtonFunction,
    iconUrl,
    successIcon,
    shouldShowFooter = true,
    boxClassName,
    id
  } = props;
  return <AuthBoxWrapper className={boxClassName} id={id}>
      <AuthBoxMainBox id="main-box">
        {iconUrl && <AuthBoxIcon id="box-icon">
            <img src={iconUrl} alt="icon" className="w-[100px]" />
          </AuthBoxIcon>}
        {successIcon && <AuthBoxIconWrapper id="checkmark-circle">
            <CheckmarkCircle fill={colors.AQUA_100} />
          </AuthBoxIconWrapper>}
        <AuthBoxHeader backButtonFunction={backButtonFunction} headerText={headerText} leftText={leftText} showBackButton={showBackButton} />
        {children}
      </AuthBoxMainBox>
      {shouldShowFooter && <AuthBoxFooter />}
    </AuthBoxWrapper>;
}