import { useQuery } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";
import type { FindAllAuditLogsDto } from "@cerebruminc/neuron-sdk";

/**
 * hook to fetch audit logs
 * @returns list of audit logs
 */
export const useGetAuditLogs = (data: FindAllAuditLogsDto) => {
  return useQuery({
    queryKey: ["audit-logs", data],
    async queryFn() {
      const res = await neuronSdk.findAuditLogs(data);
      return res.data;
    },
  });
};
