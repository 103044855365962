import { Help, Modal, colors } from "@cerebruminc/cerebellum";
import { useAtom } from "jotai";
import { Fragment, type FC } from "react";
import { ErrorDetailsTitle, ErrorMessage, ErrorName, IconBox, InfoHeader, InfoSection, InfoTitle, Issue, IssueHeader, IssueText } from "./CsvErrorDetailsModalStyles";
import { csvErrorDetailsAtom, showCsvErrorDetailsModalAtom } from "./atom";
import { uniqueId } from "lodash";
interface CsvErrorDetailsModalProps {
  date?: boolean;
  primaryContact?: boolean;
  zipCode?: boolean;
}
export const CsvErrorDetailsModal: FC<CsvErrorDetailsModalProps> = ({
  date,
  primaryContact,
  zipCode
}) => {
  const [errorArray] = useAtom(csvErrorDetailsAtom);
  const [showModal, setShowModal] = useAtom(showCsvErrorDetailsModalAtom);
  return <Modal show={showModal} closeFunction={() => setShowModal(false)} paddingString="64px 66px">
      <ErrorDetailsTitle>Error Details</ErrorDetailsTitle>

      {errorArray?.map((applicantErrorObj, index) => {
      const {
        errors,
        name
      } = applicantErrorObj;
      return <Fragment key={name}>
            {errors?.map((error, i) => {
          return <ErrorMessage key={`error_${name}_${error}_${index}_${uniqueId("error")}`}>
                  <ErrorName as="span">{name}</ErrorName>
                  {error}
                </ErrorMessage>;
        })}
          </Fragment>;
    })}

      <InfoSection>
        <InfoHeader>
          <IconBox>
            <Help fill={colors.PURPLE_100} />
          </IconBox>
          <InfoTitle>Some Common Issues</InfoTitle>
        </InfoHeader>
        {date && <Issue>
            <IssueHeader>Invalid Date Formats</IssueHeader>
            <IssueText>
              Valid Date formats are any of the following: &quot;Month DD, YYYY&quot;, &quot;MM-DD-YY&quot;,
              &quot;MM-DD-YYYY&quot;, &quot;MM/DD/YY&quot;, &quot;YYYY-DD-MM&quot;, &quot;MM DD YY&quot;, &quot;MM DD
              YYYY&quot;, &quot;YYYY MM DD&quot;, and a unix timestamp
            </IssueText>
          </Issue>}
        <Issue>
          <IssueHeader>Invalid Email Formats</IssueHeader>
          <IssueText>Please make sure emails include an @ symbol and the full extension</IssueText>
        </Issue>
        <Issue>
          <IssueHeader>Invalid Suffix Formats</IssueHeader>
          <IssueText>
            Valid Suffix formats are any of the following: &quot;Jr&quot;, &quot;Sr&quot;, &quot;I&quot;,
            &quot;II&quot;, &quot;III&quot;, &quot;IV&quot;, and &quot;V&quot;.
          </IssueText>
        </Issue>
        <Issue>
          <IssueHeader>Invalid Phone Number Formats</IssueHeader>
          <IssueText>
            Phone numbers can be any format, provided they contain 10 or 11 digits. They should also follow the
            directives of the North American Numbering Plan
          </IssueText>
          <IssueText>
            NANP format: <strong>+C (NPA) NXX-XXXX</strong>
          </IssueText>
          <IssueText>Characters other than numbers will be ignored.</IssueText>
        </Issue>
        {primaryContact && <Issue>
            <IssueHeader>Invalid Primary Contact Formats</IssueHeader>
            <IssueText>&quot;Primary Contact&quot; should be either &quot;phone&quot; or &quot;email&quot;.</IssueText>
          </Issue>}
        {zipCode && <Issue>
            <IssueHeader>Invalid Zip Code Formats</IssueHeader>
            <IssueText>
              Valid zip code formats are any of the following: &quot;12345&quot;, &quot;12345-6789&quot;, and
              &quot;123456789&quot;.
            </IssueText>
          </Issue>}
      </InfoSection>
    </Modal>;
};