import { BodySEmphasis, type ComponentWrapperType } from "@cerebruminc/cerebellum";
import React, { type FC } from "react";
import { TruncatedText } from "../UserTableStyles";
export const OrgCell: FC<ComponentWrapperType> = ({
  cell
}) => {
  const orgs = cell.map((org: any) => org.name).join(", ");
  return <BodySEmphasis>
      <TruncatedText>{orgs}</TruncatedText>
    </BodySEmphasis>;
};