import { Modal } from "@cerebruminc/cerebellum";
import { useEffect, useState } from "react";
import { MfaForm, MfaFormProps } from "./MfaForm";
interface MfaModalType extends MfaFormProps {
  showModal: boolean;
  closeModal: () => void;
}
export const MfaModal = ({
  closeModal,
  flow,
  loading,
  onSubmit,
  showModal,
  submitting
}: MfaModalType) => {
  //  We want to mount ChangePasswordForm only once, and only if the modal is opened
  const [renderForm, setRenderForm] = useState(false);
  useEffect(() => {
    if (showModal) {
      setRenderForm(true);
    }
  }, [showModal]);
  return <Modal show={showModal} paddingString="46px 77px 63px" closeFunction={closeModal} mobileBreakpoint={556}>
      {renderForm && <MfaForm closeFunction={() => closeModal()} flow={flow} inModal loading={loading} onSubmit={onSubmit} submitting={submitting} />}
    </Modal>;
};