export const TOS_NODE_TYPE = "terms_of_service";
export const ENABLE_2FA_TOGGLE = "enable_2fa_toggle";

export const ORY_UI_ELEMENTS = {
  ID: 1070004,
  RESEND_CODE_BUTTON: 1070008,
  SIGN_IN_BUTTON: 1010001,
  SIGN_UP_BUTTON: 1040001,
  UNLINK_TOTP_APP: 1050004,
  USE_AUTHENTICATOR: 1010009,
  VERIFICATION_CODE_INPUT: 1070011,
  SUBMIT_BUTTON: 1070005,
};

// message code reference: https://www.ory.sh/docs/kratos/concepts/ui-user-interface#ui-message-codes
export const ORY_MESSAGE_MAPPING = {
  RECOVERY_MESSAGE: 1060001,
  VERIFICATION_SUCCESSFUL: 1080002,
  VERIFICATION_SENT: 1080003,
  REQUEST_ALREADY_COMPLETED: 4070002,
  RECOVERY_MESSAGE_SENT: 1060003,
  PASSWORD_CANT_BE_USED: 4000005,
  USER_ALREADY_EXISTS: 4000007,
} as const;

// Type for the keys of ORY_MESSAGE_MAPPING
type ORYMessageKey = keyof typeof ORY_MESSAGE_MAPPING;

// Type for the values of ORY_MESSAGE_MAPPING
export type ORYMessageCode = (typeof ORY_MESSAGE_MAPPING)[ORYMessageKey];

// Define a type for the messages that can either be a string or an object with specific messages
type ORYMessageTransform =
  | string
  | {
      [key: string]: string;
    };

// Define the type for the ORY_MESSAGE_TRANSFORM_MAPPING object
type ORYMessageTransformMapping = {
  [key in ORYMessageCode]?: ORYMessageTransform;
};

export const ORY_MESSAGE_TRANSFORM_MAPPING: ORYMessageTransformMapping = {
  [ORY_MESSAGE_MAPPING.PASSWORD_CANT_BE_USED]: {
    "the password is too similar to the user identifier": "Too similar to your email. Please try again.",
    "the password has been found in data breaches and must no longer be used": "Password too common. Please try again.",
    "password length must be at least 8 characters but only got": "Minimum of 8 characters required",
  },
  [ORY_MESSAGE_MAPPING.USER_ALREADY_EXISTS]: "User already exists.",
  [ORY_MESSAGE_MAPPING.RECOVERY_MESSAGE_SENT]: "Please check your email for a recovery code.",
  [ORY_MESSAGE_MAPPING.RECOVERY_MESSAGE]: "Your account has been successfully recovered! Please update your password.",
};
