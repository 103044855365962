import { DigitalInput, Input, PasswordInput, useMediaQuery } from "@cerebruminc/cerebellum";
import React, { useEffect, useState } from "react";
import { type Control, useController } from "react-hook-form";
import { styled } from "styled-components";
export const FieldBox = styled.div`
  margin-bottom: 30px;
`;
const DigitalInputSpacer = styled.div`
  display: flex;
	justify-content: center;
	margin-bottom: 20px;
`;
type Props = {
  control: Control<{
    [key: string]: string;
  }, Record<string, unknown>>;
  disabled?: boolean;
  extraError?: string;
  hidePasswordMeter?: boolean;
  hoverBorderColor?: string;
  inputBackgroundColor?: string;
  inputLabel?: string;
  name: string;
  placeholder?: string;
  renderDigitalInput?: boolean;
  submitting?: boolean;
  type?: string;
  onSubmit?: () => void;
};
export function FormInput(props: Props) {
  // extraError is displayed when we get error after submitting form
  const {
    type,
    name,
    extraError,
    control,
    hidePasswordMeter,
    renderDigitalInput,
    submitting,
    onSubmit,
    ...rest
  } = props;
  const {
    field
  } = useController({
    name,
    control
  });
  const [rejectedPassword, setRejectedPassword] = useState("");
  const value = field.value;
  const handleOnChange = field.onChange;
  const handleOnBlur = field.onBlur;
  const error = extraError;
  const shouldShowError = !!extraError && (!value || value === rejectedPassword);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Do not run when value changes, only on error change
  useEffect(() => {
    if (!submitting) {
      setRejectedPassword(value || "");
    }
  }, [error, submitting]);
  if (type === "password") {
    return <FieldBox key={name}>
        <PasswordInput helperText="" hideLock hideMeter={hidePasswordMeter || !value} innerRef={(field.ref as any)} name={field.name} onInputBlur={handleOnBlur} onValueChange={handleOnChange} showValidationMessage={shouldShowError} type={type || "text"} validationMessage={error} value={value} />
      </FieldBox>;
  }
  const tinyScreen = useMediaQuery(360);
  if (type === "text" && renderDigitalInput) {
    return <DigitalInputSpacer>
        <DigitalInput innerHiddenRef={(field.ref as any)} name={field.name} onChange={({
        value
      }) => handleOnChange(value || "")} onInputBlur={handleOnBlur} showValidationMessage={shouldShowError} themeOverride={{
        inputGap: tinyScreen ? 6 : 12
      }} validationMessage={error} value={value || ""} {...rest} onComplete={onSubmit} />
      </DigitalInputSpacer>;
  }
  return <FieldBox key={name}>
      <Input type={type || "text"} name={field.name} onValueChange={handleOnChange} value={value} onInputBlur={handleOnBlur} innerRef={(field.ref as any)} validationMessage={error} showValidationMessage={shouldShowError} {...rest} />
    </FieldBox>;
}