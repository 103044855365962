import type { AdditionalPersonalInformation, Address, Alias, KeyStringType } from "./../types";

export const reformatAdditionalInformation = (obj: KeyStringType): AdditionalPersonalInformation => {
  const result: AdditionalPersonalInformation = {
    aliases: [],
    addresses: [],
  };

  for (const key in obj) {
    // Get the prefix (alias/address), new key, and the index
    // eslint-disable-next-line prefer-const
    const [prefix, newKey, stringIndex] = key.split("_");
    const index = Number(stringIndex) - 1; // zero index

    if (prefix === "alias") {
      if (!result.aliases[index]) {
        result.aliases[index] = {} as Alias;
      }

      // Assign the value to the new key in the corresponding object
      (result.aliases[index] as KeyStringType)[newKey] = obj[key];
    } else if (prefix === "address") {
      if (!result.addresses[index]) {
        result.addresses[index] = {} as Address;
      }

      // Assign the value to the new key in the corresponding object
      (result.addresses[index] as unknown as KeyStringType)[newKey] = obj[key];
    }
  }

  return result;
};
