import { useAtomValue } from "jotai";
import { currentOrgIdAtom } from "src/atom";
import { graphqlEndpointUrl } from "../environment";

// This is a custom fetcher function, used by graphql-codegen to fetch data from the API
// It sets the active organization id in the request header so that the API can use it for processing the request
// See https://the-guild.dev/graphql/codegen/plugins/typescript/typescript-react-query#using-custom-fetcher
export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"],
): ((variables?: TVariables) => Promise<TData>) => {
  const orgId = useAtomValue(currentOrgIdAtom);

  return async (variables?: TVariables): Promise<TData> => {
    const res = await fetch(graphqlEndpointUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "x-cerebrum-org-id": orgId || "",
        ...options,
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
};
