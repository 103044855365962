import { Configuration, NeuronApi } from "@cerebruminc/neuron-sdk";
import { env } from "src/environment";

export const getNeuronSdk = (sessionToken = "", cookie = "", apiKey = "") => {
  const config = new Configuration({
    basePath: env.NEURON_API as string,
    baseOptions: {
      withCredentials: true,
    },
    apiKey: (keyName) => {
      if (keyName === "cookie") return cookie;
      if (keyName === "x-session-token") return sessionToken;
      if (keyName === "x-api-key") return apiKey;
      return "";
    },
  });

  return new NeuronApi(config);
};

export const neuronSdk = getNeuronSdk();
