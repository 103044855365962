import type { CreateApiKeyDto, FindAllApiKeysDto, UpdateApiKeyDto } from "@cerebruminc/neuron-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";

export const useGetApiKeysQuery = (data: FindAllApiKeysDto) => {
  return useQuery({
    queryKey: ["api-keys"],
    async queryFn() {
      const res = await neuronSdk.findApiKeys(data);
      return res.data;
    },
  });
};

export const useGetApiKeyQuery = (id: string) => {
  return useQuery({
    queryKey: ["api-key", id],
    async queryFn() {
      const res = await neuronSdk.findOneApiKey(id);
      return res.data;
    },
  });
};

export const useDeleteApiKeyMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const res = await neuronSdk.deleteApiKey(id);
      return res.data;
    },
  });
};

export const useCreateApiKeyMutation = () => {
  return useMutation({
    async mutationFn(data: CreateApiKeyDto) {
      const res = await neuronSdk.createApiKey(data);
      return res.data;
    },
  });
};

export const useUpdateApiKeyMutation = () => {
  return useMutation({
    async mutationFn(data: UpdateApiKeyDto & { id: string }) {
      const { id, ...rest } = data;
      const res = await neuronSdk.updateApiKey(id, rest);
      return res.data;
    },
  });
};
