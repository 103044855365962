import "../styles/globals.css";
import { GlobalStyle, cerebellumTheme } from "@cerebruminc/cerebellum";
import { Configuration, NeuronApi } from "@cerebruminc/neuron-sdk";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { type ComponentType, useEffect, createContext, useContext, useMemo, useCallback } from "react";
import { bannerShownAtom, scrollbarWidthAtom, userObjectAtom, windowSizeAtom } from "src/atom";
import { LoginTermsModal, NeuronProvider } from "src/components";
import { paths } from "src/const";
import { env } from "src/environment";
import NoOrgDataModal from "src/views/VID/components/NoOrgDataModal";
import VidAcceptInviteModal from "src/views/VID/components/VidAcceptInviteModal";
import VidNoInvitationModal from "src/views/VID/components/VidNoInvitationModal";
import VidSsnInfoModal from "src/views/VID/components/VidSsnInfoModal";
import packageJSON from "./../../package.json";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
const printBanner = () => {
  console.log(`
  
   __________  _______   ____________________  _   __
  / ____/ __ \\/ ____/ | / /  _/_  __/  _/ __ \\/ | / /
 / /   / / / / / __/  |/ // /  / /  / // / / /  |/ / 
/ /___/ /_/ / /_/ / /|  // /  / / _/ // /_/ / /|  /  
\\____/\\____/\\____/_/ |_/___/ /_/ /___/\\____/_/ |_/   

       Welcome to cognition v${packageJSON.version}
  `);
};
interface NeuronContextData {
  sdk: NeuronApi;
}
const NeuronContext = createContext(({} as NeuronContextData));

/**
 * hook to get sdk instance which was initialized in NeuronProvider
 * @returns neuron sdk context
 */
export const useNeuron = () => {
  const context = useContext(NeuronContext);
  if (context === undefined) {
    throw new Error("useNeuron must be used within a component wrapped with NeuronProvider");
  }
  return context;
};
function MyApp({
  Component,
  pageProps
}: AppProps) {
  const router = useRouter();
  const setScrollbarWidth = useSetAtom(scrollbarWidthAtom);
  const user = useAtomValue(userObjectAtom);
  const [bannerShown, setBannerShown] = useAtom(bannerShownAtom);
  // Don't show the modals on [invite] - it's just a loading page
  const isVidPath = router.pathname.startsWith("/vid/") && !router.pathname.includes("/expired") && !router.pathname.includes("/landing") && !router.pathname.includes("/[invite]");
  useEffect(() => {
    const {
      pathname
    } = router;
    document.body.className = pathname === paths.home || pathname === paths.orders || pathname.includes(paths.vid) ? "alwaysScroll" : "";
  });
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    setScrollbarWidth(scrollbarWidth);
  }, [setScrollbarWidth]);

  // Only print the banner once. We have to use a state variable because useEffect fails due to withLDProvider
  // forcibly rerendering `MyApp` multiple times on first load.
  useEffect(() => {
    if (!bannerShown) {
      printBanner();
      setBannerShown(true);
    }
  }, [bannerShown, setBannerShown]);

  // Track window size for the whole app
  const {
    height,
    width
  } = useWindowDimensions();
  const setWindowSize = useSetAtom(windowSizeAtom);
  useEffect(() => {
    setWindowSize({
      height,
      width
    });
  }, [height, setWindowSize, width]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const currentPath = router.pathname;
    const isLoginPath = currentPath === paths.login;
    const isLogoutPath = currentPath === paths.logout;
    if (currentPath === paths.notFound || isVidPath || isLoginPath || isLogoutPath) {
      return;
    }
  }, [user]);

  // Neuron Provider
  const getSdk = useCallback((apiUrl: string) => {
    return new NeuronApi(new Configuration({
      basePath: apiUrl,
      baseOptions: {
        withCredentials: true
      }
    }));
  }, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const sdk = useMemo(() => getSdk(env.NEURON_API || ""), [env.NEURON_API || ""]);
  return <>
      <Sentry.ErrorBoundary showDialog>
        <NeuronProvider theme={cerebellumTheme} neuronUrl={env.NEURON_API || ""}>
          <GlobalStyle />
          <ReactQueryDevtools initialIsOpen={false} />
          <Script src={`https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_MAPS_API_KEY}&libraries=places`} />
          {isVidPath ? <>
              <Component {...pageProps} />
              <VidSsnInfoModal />
              {router.isReady && <VidAcceptInviteModal />}
              <VidNoInvitationModal />
              <NoOrgDataModal />
            </> : <>
              <Component {...pageProps} />
              <LoginTermsModal />
            </>}
        </NeuronProvider>
      </Sentry.ErrorBoundary>
    </>;
}
export default withLDProvider({
  clientSideID: env.LAUNCHDARKLY_API_KEY
})((MyApp as ComponentType<object>));