import { ConnotationColorEnum, DialogHandler, SnackTypeEnum } from "@cerebruminc/cerebellum";
import type { RegistrationFlow, SettingsFlow, UiNode, UiNodeMeta, UiNodeTypeEnum, UiText, UpdateRegistrationFlowBody } from "@ory/kratos-client";
import { AxiosError } from "axios";
import { identity, pickBy } from "lodash";
import { useRouter } from "next/router";
import { type Dispatch, useCallback, useEffect, useMemo, useState, type SetStateAction } from "react";
import { ENABLE_2FA_TOGGLE, TOS_NODE_TYPE, paths } from "src/const";
import ory from "src/helpers/ory/sdk";
import { AxiosErrorType, handleFlowError, createLocalReturnToWithParams, createRedirectionWithParams, getInitialValues, redirectAndPreserveParams, neuronSdk, noop, analytics } from "src/helpers";
import { useGetReturnUrl } from "./useGetReturnUrl";
type ExtendedUiNodeTypeEnum = UiNodeTypeEnum | "enable_2fa_toggle" | "terms_of_service";
type ExtendedUiNode = Omit<UiNode, "type" | "messages" | "meta"> & {
  type: ExtendedUiNodeTypeEnum;
  messages?: Array<UiText>;
  meta?: UiNodeMeta;
};
type ExtendedUiType = Omit<RegistrationFlow["ui"], "nodes"> & {
  nodes: ExtendedUiNode[];
};
type ExtendedRegistrationFlow = Omit<RegistrationFlow, "ui"> & {
  ui: ExtendedUiType;
};

/**
 * this hook initialize and manage register flow state
 * @returns { loading, flow, submitting, onSubmit, initalValues }
 */
export const useRegisterFlow = () => {
  // Renders the registration page
  const router = useRouter();

  // The "flow" represents a registration process and contains
  // information about the form we need to render (e.g. username + password)
  const [flow, setFlow] = useState<ExtendedRegistrationFlow | SettingsFlow>();
  const [initalValues, setInitialValues] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  // Get ?flow=... from the URL
  const {
    flow: flowId,
    email,
    voucherId
  } = router.query;
  const returnTo = useGetReturnUrl();
  const updateFlow = (flowData?: ExtendedRegistrationFlow) => {
    if (!flowData) return;
    const updatedFlow = {
      ...flowData
    };

    // add 2FA toggle
    updatedFlow.ui.nodes.splice(3, 0, {
      type: ENABLE_2FA_TOGGLE,
      group: "default",
      attributes: {
        name: ENABLE_2FA_TOGGLE,
        type: "checkbox",
        value: "",
        required: false,
        disabled: false,
        node_type: (ENABLE_2FA_TOGGLE as any)
      }
    });
    // add TOS checkbox
    updatedFlow.ui.nodes.splice(4, 0, {
      type: TOS_NODE_TYPE,
      group: "default",
      attributes: {
        name: TOS_NODE_TYPE,
        type: "checkbox",
        value: "",
        required: true,
        disabled: false,
        node_type: (TOS_NODE_TYPE as any)
      }
    });

    // hide secondary emails input as we dont need it
    const secondaryEmailsNodeIndex = updatedFlow.ui.nodes.findIndex(item => "name" in item.attributes && item.attributes.name === "traits.secondary_emails");
    if (secondaryEmailsNodeIndex > -1) {
      updatedFlow.ui.nodes.splice(secondaryEmailsNodeIndex, 1);
    }

    // if we have email from url query parameters then we dont allow user to update the email
    const emailNode = updatedFlow.ui.nodes.find(item => "type" in item.attributes && item.attributes.type === "email");
    if (!!emailNode && emailNode?.attributes?.node_type === "input" && !!email) {
      emailNode.attributes.disabled = true;
    }
    setFlow(updatedFlow);
  };

  // In this effect we either initiate a new registration flow, or we fetch an existing registration flow.
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (!router.isReady || flow) {
      return;
    }
    const init = async (innerFlowId = "") => {
      // If ?flow=.. was in the URL, we fetch it
      if (innerFlowId) {
        try {
          setLoading(true);
          const {
            data
          } = await ory.getRegistrationFlow({
            id: String(innerFlowId)
          });
          updateFlow(data);
        } catch (error) {
          init().then(noop, noop);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const {
            data
          } = await ory.createBrowserRegistrationFlow({
            returnTo: returnTo ? String(returnTo) : undefined
          });
          let query: {
            [key: string]: string;
          } = {
            flowId: data?.id,
            return_to: returnTo,
            email: String(email || "").toLowerCase(),
            voucherId: String(voucherId || "")
          };
          // remove all falsy values
          query = pickBy(query, identity);
          // update flow id in url
          const urlQuery = {
            query: {
              ...query
            }
          };
          router.push(paths.register, urlQuery, {
            shallow: true
          });
          updateFlow(data);
        } catch (error) {
          handleFlowError(router, paths.register, (updateFlow as Dispatch<SetStateAction<RegistrationFlow | undefined>>))((error as AxiosError<AxiosErrorType>));
        } finally {
          setLoading(false);
        }
      }
    };
    init((flowId as string)).then(noop, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, router.isReady, returnTo]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const onSubmit = useCallback(async (values: UpdateRegistrationFlowBody | any) => {
    analytics.track("Attempt Create Account");
    if (values[TOS_NODE_TYPE] === false) {
      DialogHandler.show({
        colorFamily: ConnotationColorEnum.Negative,
        id: "agree-to-terms",
        snackType: SnackTypeEnum.Toast,
        text: "Please agree to the Terms and Privacy Policy to continue."
      });
      return;
    }
    try {
      setSubmitting(true);
      const {
        data: registerRes
      } = await ory.updateRegistrationFlow({
        flow: String(flow?.id),
        updateRegistrationFlowBody: values
      });
      // if register successfully then insert kratos user in neuron db
      try {
        await neuronSdk.checkKratosUser();
      } catch (error) {
        // if neuron is down or there is some error related to session checking
        // then dont allow user to proceed to `return_to` url ( which causes redirect loop )
        // instead redirect user to logout
        // fixes scenario:
        // neuron api has some issues/down
        // if user is not logged in, neuron-web redirect user to auth-web
        // auth-web logs in redirect to neuron-web but neuron-web check neuron whoami route
        // which returns error hense it will redirect to auth-web back and loop continuous
        DialogHandler.show({
          colorFamily: ConnotationColorEnum.Negative,
          id: "something-went-wrong",
          snackType: SnackTypeEnum.Toast,
          text: "Something went wrong"
        });
        analytics.track("Create Account Error", {
          error,
          type: "Check Kratos User Error"
        });
        return router.push(paths.logout);
      }
      const enable2fa = values[ENABLE_2FA_TOGGLE];
      let finalReturnToUrl: string;
      // if user has opt-in for 2fa then redirect user to 2fa page
      if (enable2fa) {
        // if we have returnTo available, then after user complete 2fa we need to return user to that url
        if (returnTo) {
          finalReturnToUrl = createLocalReturnToWithParams(paths.mfa, {
            return_to: createRedirectionWithParams(returnTo)
          });
        } else {
          finalReturnToUrl = createLocalReturnToWithParams(paths.mfa);
        }
      } else if (returnTo) {
        finalReturnToUrl = createRedirectionWithParams(returnTo);
      } else {
        finalReturnToUrl = createLocalReturnToWithParams(paths.home);
      }
      analytics.track("Create Account Success");
      const continueWith = registerRes.continue_with?.[0];
      // if we have verification flow id then redirect user to verification page
      // user should verify their email after sign up
      if (continueWith && "flow" in continueWith) {
        const verificationFlowId = continueWith.flow.id;
        return redirectAndPreserveParams(router, paths.verification, {
          flow: verificationFlowId,
          return_to: finalReturnToUrl,
          email: values?.traits?.email
        });
      }
      // else redirect them to "home" page
      return redirectAndPreserveParams(router, paths.home);
    } catch (error) {
      analytics.track("Create Account Error", {
        error,
        type: "Registration Flow Error"
      });
      setSubmitting(false);
      handleFlowError(router, paths.register, (updateFlow as Dispatch<SetStateAction<RegistrationFlow | undefined>>))((error as AxiosError<AxiosErrorType>));
      if (error instanceof AxiosError && error.response?.status === 400) {
        if (!error.response?.data?.ui?.nodes) {
          // Certain errors don't return UI nodes. This should only be in development, and there is nothing the user can do, so we're showing a generic error message.
          DialogHandler.show({
            colorFamily: ConnotationColorEnum.Negative,
            id: "oops-try-again",
            snackType: SnackTypeEnum.Toast,
            text: "Oops! There was an error with your request."
          });
          console.error("No ui nodes returned", error.response?.data);
        } else {
          updateFlow(error.response?.data);
        }
        return;
      }
    }
  }, [flow, router]);
  useEffect(() => {
    const values = getInitialValues((flow?.ui?.nodes as UiNode[]));
    setInitialValues(values);
  }, [flow]);
  return useMemo(() => ({
    loading,
    flow,
    submitting,
    onSubmit,
    initalValues
  }), [loading, flow, submitting, onSubmit, initalValues]);
};