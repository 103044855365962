import { PassStatusEnum } from "src/const";
import { CredentialStatusEnum, PassDetailQuery, PassQuery, PassesQuery } from "src/generated/graphql";

export const getStatusFromInputs = (
  pass: PassDetailQuery["pass"] | PassQuery["pass"] | PassesQuery["passes"][0],
): PassStatusEnum => {
  const { inputs } = pass || {};

  if (!inputs || inputs.length === 0) {
    return PassStatusEnum.Pending;
  }

  const statuses = inputs.map((input) => input?.credential?.status?.value);

  // If all statuses are active, return Active
  if (statuses.every((status) => status === CredentialStatusEnum.Active)) {
    return PassStatusEnum.Active;
  }

  // If a pass has expired or revoked credentials, return Inactive
  if (statuses.some((status) => status === CredentialStatusEnum.Revoked)) {
    return PassStatusEnum.Inactive;
  }

  // TODO: If any statuses are pending (missing), return Pending - This isn't possible right now AFAIK
  return PassStatusEnum.Pending;
};
