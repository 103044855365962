export const paths = {
  addOrder: "/add-order",
  apiKeys: "/api-keys",
  auditLog: "/audit-log",
  backupCodes: "/backup-codes",
  createInvite: "/create-invite",
  declinedInvite: "/declined-invite",
  notFound: "/404",
  home: "/invites", // This is the default route
  invites: "/invites",
  login: "/login",
  logout: "/logout",
  mfa: "/mfa",
  myAccount: "/my-account",
  orders: "/orders",
  organizations: "/organizations",
  pass: "/pass",
  profileSettings: "/profile-settings",
  recovery: "/recovery",
  register: "/registration",
  settings: "/settings",
  suborg: "/suborg",
  user: "/user",
  users: "/users",
  verification: "/verification",
  vid: "/vid",
  vidAdditionalInformation: "/vid/additional-information",
  vidIdUpload: "/vid/id-upload",
  vidPersonalInformation: "/vid/personal-information",
  vidSelfie: "/vid/selfie",
  vidSignDisclosures: "/vid/sign-disclosures",
  vidSSN: "/vid/ssn",
  vidSubmitted: "/vid/submitted",
  vidSuccess: "/vid/success",
  vidWelcome: "/vid/welcome",
};

// These are paths available to all users
export const allowedUserPaths = new Set([
  paths.addOrder,
  paths.backupCodes,
  paths.createInvite,
  paths.declinedInvite,
  paths.home,
  paths.invites,
  paths.login,
  paths.logout,
  paths.mfa,
  paths.myAccount,
  paths.notFound,
  paths.orders,
  paths.organizations,
  paths.pass,
  paths.profileSettings,
  paths.recovery,
  paths.register,
  paths.settings,
  paths.suborg,
  paths.user,
  paths.users,
  paths.verification,
  paths.vid,
  paths.vidAdditionalInformation,
  paths.vidIdUpload,
  paths.vidPersonalInformation,
  paths.vidSelfie,
  paths.vidSignDisclosures,
  paths.vidSSN,
  paths.vidSubmitted,
  paths.vidSuccess,
  paths.vidWelcome,
]);
