import type { FilterType } from "@cerebruminc/cerebellum";
import { OrderDeliveryMethod, ResultSearchType } from "src/generated/graphql";
import type { SearchTermsObject } from "./types";

// The abbreviateViDApp prop is necessary because
// the User Information Card requires an abbreviated order display name if order Type is vID
export const getDisplayableOrderTypeString = (
  deliveryMethod: string | undefined | null,
  abbreviateViDApp?: boolean,
) => {
  switch (deliveryMethod) {
    case OrderDeliveryMethod.Standard:
      return "Standard";
    case OrderDeliveryMethod.RapidRequest:
      return "Rapid Request";
    case OrderDeliveryMethod.VidScreen:
      return abbreviateViDApp ? "vID" : "vID App";
    default:
      return "Standard";
  }
};

export const getDisplayableSearchType = (searchType: ResultSearchType) => {
  switch (searchType) {
    case ResultSearchType.County:
      return "County Search";
    case ResultSearchType.Sexoffender:
      return "Sex Offender Search";
    case ResultSearchType.Sanctions:
      return "Sanctions Search";
    case ResultSearchType.Youth:
      return "Youth Search";
    case ResultSearchType.Natcrim:
      return "National Criminal Search";
    case ResultSearchType.SsnAlert:
      return "SSN ID Search";
    default:
      return "";
  }
};

export const formatToOrderTypeEnum = (type = "") => {
  switch (type) {
    case "Rapid Request":
      return OrderDeliveryMethod.RapidRequest;
    case "Standard":
      return OrderDeliveryMethod.Standard;
    case "vID App":
      return OrderDeliveryMethod.VidScreen;
    default:
      return OrderDeliveryMethod.Standard;
  }
};

export const capitalizeFirstLetter = (string?: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const maskSSN = (ssn: string) => {
  if (!ssn) return "";

  const lastFour = ssn.slice(-4);
  return `xxx-xx-${lastFour}`;
};

export const maskAndFormatDOB = (dob: string) => {
  if (!dob) return "";
  let maskedDob = "";
  const dobIndexesToMask = [6, 7, 8, 9];
  const dobIndexesToHyphenate = [2, 5];
  for (let i = 0; i < dob.length; i++) {
    if (dobIndexesToMask.includes(i)) {
      maskedDob = maskedDob + "x";
    } else if (dobIndexesToHyphenate.includes(i)) {
      //More efficient to do this here than to use the string.replace method
      maskedDob = maskedDob + "-";
    } else {
      maskedDob = maskedDob + dob[i];
    }
  }
  return maskedDob;
};

export const pullSearchTerms = (searchTags: Array<FilterType>) => {
  const terms: SearchTermsObject = {};
  for (let i = 0; i < searchTags.length; i++) {
    const tagObject = searchTags[i];
    const searchType: keyof SearchTermsObject = tagObject.searchType;
    if (searchType && !tagObject.disabled) {
      terms[searchType] = tagObject.label;
    }
  }
  return terms;
};
