import { pressedEnter, Avatar, BodySSemiBold, BoxedButton, ButtonColorFamilyEnum, CaretDown, colors, SubOrg, Truncate, useMeasureWatchResize, User, UserCardModal, type UserCardOptionsType, type UserCardType } from "@cerebruminc/cerebellum";
import { useAtom, useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import React, { type FC, type KeyboardEvent, useCallback, useEffect, useState, useMemo } from "react";
import { styled } from "styled-components";
import { currentOrgIdAtom, organizationsAtom, windowSizeAtom } from "src/atom";
import { allowedUserPaths, paths } from "src/const";
import { useUpdateSelectedOrganizationId, useWhoAmI } from "src/hooks";
import { UserControlsBase, AvatarBox, CaretBox } from "./UserControlsStyles";
import type { UserControlsType } from "./types";
import { hasSuperAdminRole } from "src/helpers";
import { useHandleLogout } from "src/hooks/useHandleLogout";
const ALL_ORGS_OPTION_ID = "ALL_ORGS_OPTION_ID";
const OrgHeaderLogo = styled.img`
  height: 16px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
`;
const OrgButtonLogo = styled.img`
  height: auto;
  width: 20px;
  display: block;
`;
const OrgButtonLogoBlank = styled.div`
  height: auto;
  width: 20px;
  display: block;
`;
const Name = styled(BodySSemiBold)`
  max-width: 400px;
  @media all and (max-width: 900px) {
    max-width: 300px;
  }
  @media all and (max-width: 800px) {
    max-width: 200px;
  }
`;
export const UserControls: FC<UserControlsType> = ({
  white
}) => {
  const {
    neuronWebProfileSettings
  } = useFlags();

  // UserCard
  const [showUserCard, setShowUserCard] = useState(false);
  const [avatarRef, avatarMeasure] = useMeasureWatchResize();
  const {
    width
  } = useAtomValue(windowSizeAtom);
  const modifiedAvatarMeasure = avatarMeasure;
  modifiedAvatarMeasure.left = width - 30;
  modifiedAvatarMeasure.right = width - 30;
  const {
    data: userData
  } = useWhoAmI();
  const {
    email,
    firstname,
    lastname
  } = userData || {};
  const organizations = useAtomValue(organizationsAtom);
  const {
    mutateAsync: updateSelectedOrganizationId
  } = useUpdateSelectedOrganizationId();
  const [currentOrgId, setCurrentOrgId] = useAtom(currentOrgIdAtom);
  const handleLogout = useHandleLogout();
  const router = useRouter();
  const userName = `${firstname || ""} ${lastname || ""}`;
  const lastInitial = lastname?.charAt(0) || "";
  const shortUserName = `${firstname || ""} ${!firstname ? lastname || "" : lastInitial ? `${lastInitial}.` : ""}`;
  useEffect(() => {
    avatarMeasure.top = avatarMeasure.top + 20;
  }, [avatarMeasure]);
  const handleOrgChange = useCallback(async (orgId: string | null) => {
    setShowUserCard(false);
    setCurrentOrgId(orgId);
    if (userData?.id) {
      try {
        const response = await updateSelectedOrganizationId({
          id: userData.id,
          selectedOrganizationId: orgId
        });

        // Super admins get access to everything, but don't always have a currentRole listed
        const currentRole = response.roleAssignment?.find((item: any) => item.organizationId === orgId)?.roleV2?.name;
        const isSuperAdmin = hasSuperAdminRole(userData);
        const isBaseUser = (!currentRole || currentRole === "USER") && !isSuperAdmin;
        const restrictedRoute = !allowedUserPaths.has(router.pathname);
        const isAccountPath = router.pathname === `${paths.organizations}/[id]`;

        // redirect users to a usable path
        if (restrictedRoute && isBaseUser) {
          router.push(paths.home);
        } else if (isAccountPath) {
          // Reload the account page to display the new org
          router.push(`${paths.organizations}/${orgId}`);
        }
      } catch (error) {
        console.error("Failed to update selected organization id", error);
      }
    }
  }, [router, userData, updateSelectedOrganizationId, setCurrentOrgId]);
  const activeOrg = organizations?.find(org => org.id === currentOrgId);
  const orgOptions: UserCardType["organizations"] = useMemo(() => {
    const options: UserCardType["organizations"] = organizations?.map(org => ({
      id: org.id,
      Icon: () => org.logoUrl ? <OrgButtonLogo src={org.logoUrl} alt={`${org.name} logo`} /> : <OrgButtonLogoBlank />,
      iconSize: 20,
      text: org.name,
      onClick: () => {
        handleOrgChange(org.id);
      }
    })) || [];

    // Only show the "All Organizations" option if there are multiple organizations
    if (organizations && organizations.length > 1) {
      options.unshift({
        id: ALL_ORGS_OPTION_ID,
        Icon: () => <OrgButtonLogoBlank />,
        iconSize: 20,
        text: "All Organizations",
        onClick: _item => handleOrgChange(null)
      });
    }
    return options;
  }, [organizations, handleOrgChange]);
  const userCardOptions: UserCardOptionsType[] = [{
    text: "Profile Settings",
    Icon: User,
    onClick: () => neuronWebProfileSettings ? router.push(paths.profileSettings) : router.push(paths.settings)
  }, {
    text: "Your Organizations",
    Icon: SubOrg,
    onClick: () => router.push(paths.organizations)
  }];
  return <>
      <UserControlsBase $white={white} onClick={() => setShowUserCard(true)} onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
      if (pressedEnter(event) || event.key === " ") {
        setShowUserCard(true);
        event.preventDefault(); // prevent scroll on spacebar
      }
    }} $clickable={!showUserCard} tabIndex={!showUserCard ? 0 : -1} role="button" aria-label="Open user settings panel">
        {!!userData && <div className="hidden sm:flex">
            <BoxedButton buttonHeight={30} clickable={false} colorFamily={ButtonColorFamilyEnum.CoolGrey} text={activeOrg ? <>
                    {activeOrg?.logoUrl && <OrgHeaderLogo src={activeOrg.logoUrl} alt={`${activeOrg.name} logo`} />}
                    <span>{activeOrg?.name ?? " "}</span>
                  </> : <span>All Organizations</span>} white={!white} />
          </div>}
        <AvatarBox ref={avatarRef} $white={white}>
          <Avatar placeholderInitials={userName} imageUrl={!userName && activeOrg?.logoUrl ? activeOrg.logoUrl : undefined} backgroundGradient={!userName && activeOrg?.logoUrl ? "transparent" : white ? undefined : "linear-gradient(180deg, rgba(255,255,255,0.78) 0%, rgba(255,255,255,0) 80%)"} themeOverride={{
          size: 50
        }} />
        </AvatarBox>
        {userData && <Name $textColor={white ? colors.COOL_GREY_100 : colors.WHITE}>
            <Truncate>{shortUserName}</Truncate>
          </Name>}
        <CaretBox $white={white}>
          <CaretDown fill={colors.COOL_GREY_30} />
        </CaretBox>
      </UserControlsBase>

      <UserCardModal onClickout={() => setShowUserCard(false)} showUserCard={showUserCard} targetMeasure={modifiedAvatarMeasure} onSignOut={handleLogout} userEmail={email} userName={userName} topOffset={avatarMeasure.top + avatarMeasure.height} options={userCardOptions} organizations={orgOptions} activeOrg={activeOrg ? activeOrg?.id : ALL_ORGS_OPTION_ID} themeOverride={{
      width: 315
    }} />
    </>;
};