import mixpanel, { type OverridedMixpanel, type Dict } from "mixpanel-browser";
import { env } from "src/environment";

class Analytics {
  mixpanel: OverridedMixpanel;

  constructor() {
    this.mixpanel = mixpanel;
    if (typeof window !== "undefined") {
      this.mixpanel.init(env.MIXPANEL_TOKEN as string, {
        debug: true,
        track_pageview: "url-with-path",
        persistence: "localStorage",
      });
    }
  }

  identify(voucherId: string) {
    this.mixpanel.identify(voucherId);
    this.mixpanel.people.set("name", voucherId);
  }

  track(eventName: string, eventData?: Dict) {
    this.mixpanel.track(eventName, eventData);
  }

  registerSuperProperties(properties: Dict) {
    this.mixpanel.register(properties);
  }
}

export const analytics = new Analytics();
