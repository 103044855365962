import { colors } from "@cerebruminc/cerebellum";
import { env } from "src/environment";
import { styled } from "styled-components";
const OuterLinkWrappingDiv = styled.div`
	align-items: center;
	color: ${colors.COOL_GREY_85};
	display: flex;
	font-size: 15px;
	justify-content: center;
	padding-top: 40px;
`;
const InnerLinkAElement = styled.a`
	text-decoration-line: underline;
	opacity: 0.8;
	color: ${colors.BLUE_70};
	&:hover {
		opacity: 1;
	}
`;
const InnerLinkSeparator = styled.div`
	align-items: center;
	color: ${colors.BLUE_50};
	display: flex;
	font-weight: 400;
	justify-content: center;
	width: 32px;
`;
export function AuthBoxFooter() {
  return <OuterLinkWrappingDiv id="footer">
      <InnerLinkAElement href={env.PRIVACY_POLICY_URL} target="_blank" rel="noreferrer noopener">
        Privacy Policy
      </InnerLinkAElement>
      <InnerLinkSeparator>|</InnerLinkSeparator>
      <InnerLinkAElement href={env.TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer noopener">
        Terms & Conditions
      </InnerLinkAElement>
    </OuterLinkWrappingDiv>;
}