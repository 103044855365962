import { type FormFieldType, FormFieldTypeEnum } from "@cerebruminc/cerebellum";
import * as Yup from "yup";

export const CURRENT_PASSWORD_ID = "current_password";
export const NEW_PASSWORD_ID = "new_password";

const alwaysTrueValidation: Yup.StringSchema = Yup.string().test(
  "always-valid",
  "This field is always valid",
  () => true,
);

export const changePasswordFields: Array<FormFieldType> = [
  {
    fieldLabel: "Current Password",
    name: CURRENT_PASSWORD_ID,
    required: true,
    requiredMessage: "Please enter your current password",
    type: FormFieldTypeEnum.Password,
    validationFunction: alwaysTrueValidation,
    options: {
      hideMeter: true,
      placeholder: "",
    },
  },
  {
    fieldLabel: "New Password",
    name: NEW_PASSWORD_ID,
    required: true,
    requiredMessage: "Please enter a new password",
    type: FormFieldTypeEnum.Password,
    options: {
      placeholder: "",
    },
  },
  {
    fieldLabel: "Re-Type New Password",
    name: "password_match",
    required: true,
    type: FormFieldTypeEnum.Password,
    validationFunction: Yup.string()
      .required("Please enter your new password")
      .test(
        "passwords-match", // name of the test
        "Passwords must match", // error message if the test fails
        function (value) {
          return this.parent[NEW_PASSWORD_ID] === value;
        },
      ),
    options: {
      hideMeter: true,
      placeholder: "",
    },
  },
];
