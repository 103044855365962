export const getSexData = (sex?: string): string => {
  let value: string;
  switch (sex) {
    case "MALE":
    case "Male":
    case "male":
    case "M":
    case "m":
      value = "M";
      break;
    case "FEMALE":
    case "Female":
    case "female":
    case "F":
    case "f":
      value = "F";
      break;
    default:
      value = "";
  }

  return value;
};
