import set from "lodash/set";
import { getGroupNodes } from "./getGroupNodes";
import { UiNode, UiNodeInputAttributes } from "@ory/kratos-client";
import { GroupType } from "./types";
import { TOS_NODE_TYPE, ENABLE_2FA_TOGGLE } from "src/const";

// Returns initial values based on flow nodes
export const getInitialValues = (nodes: UiNode[] = [], renderOnlyGroup?: GroupType): { [key: string]: string } => {
  let values = {};

  const groupNodes = getGroupNodes(nodes, renderOnlyGroup);
  for (const node of groupNodes) {
    const attributes = node.attributes as UiNodeInputAttributes;
    if (attributes.node_type === "input") {
      // for attributes.type === "button" & attributes.type === "submit" also we are setting values
      // for e.g. in case of button/submit value will be method = "link" / "password" / "totp" etc
      values = set(values, attributes.name, attributes.value || "");
    } else if (attributes.node_type === TOS_NODE_TYPE) {
      values = set(values, attributes.name, attributes.value || false);
    } else if (attributes.node_type === ENABLE_2FA_TOGGLE) {
      values = set(values, attributes.name, attributes.value || false);
    }
  }

  return values;
};
