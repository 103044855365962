import { useRouter } from "next/router";
import { useEffect } from "react";

export const useRouteChange = (onRouteChange: () => void): void => {
  const router = useRouter();

  useEffect(() => {
    if (!router.events) {
      return;
    }
    // This function gets called on every route change
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        onRouteChange(); // Call the user-defined function
      }
    };

    // Subscribe to route changes
    router.events.on("routeChangeStart", handleRouteChange);

    // Cleanup function to unsubscribe from the event
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, onRouteChange]);
};
