import { Atom, WritableAtom } from "jotai";
import { createStore } from "jotai/vanilla";

export const seedStore = <ValueType>(
  store: ReturnType<typeof createStore>,
  atom: WritableAtom<ValueType, [ValueType], void>,
  value: ValueType,
) => {
  store.set(atom, value);
};
