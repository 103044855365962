import { FormikProps } from "formik";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { RefObject } from "react";

import type { DeletedApplicantsProps } from "../AddOrderView/types";
import type { ApplicantData } from "./types";

export const selectedPackageIdAtom = atomWithStorage("invitePackage", "");
export const selectedPassTypeIdAtom = atomWithStorage<string | undefined>("passType", undefined);
export const invitePlatformTypeAtom = atomWithStorage("invitePlatformType", "");

export const inviteApplicantsAtom = atomWithStorage<ApplicantData[]>("inviteApplicants", []);
export const deletedInviteApplicantsAtom = atom<DeletedApplicantsProps>({});
export const deletedAllInvitedApplicantsAtom = atom<ApplicantData[]>([]);
export const editingInvitedApplicantAtom = atom<ApplicantData | undefined>(undefined);
export const showEditInvitedApplicantModalAtom = atom(false);
export const createInvitePageLoadingAtom = atom(true);

export const applicantInfoFormRefAtom = atom<RefObject<FormikProps<any>> | null>(null);

// CSV uploads
export const uploadedInvitesAtom = atom<{ fileName: string; original: string }[]>([]);
