import { BodySSemiBold, type ComponentWrapperType } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
export const TruncateName = styled(BodySSemiBold)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 23.5px;
`;
export const NameTransform = ({
  row
}: ComponentWrapperType) => {
  const {
    firstName,
    middleName,
    lastName
  } = row;
  const name = (firstName ? `${firstName} ` : "") + (middleName ? `${middleName} ` : "") + (lastName ? `${lastName}` : "");
  return <TruncateName title={name}>{name}</TruncateName>;
};