import { HeadingH1 } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  padding: 39px 20px 0;
`;
export const Title = styled(HeadingH1)`
  display: inline-block;
  margin-right: 17px;
  vertical-align: middle;
`;
export const AddButtonBox = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
export const IconShim = styled.div`
  display: inline-block;
  height: 15px;
  width: 15px;
`;
export const OptionLabelNudge = styled.div`
  padding: 5px 0 5px 20px;
`;
export const RightControls = styled.div`
  display: flex;
  gap: 17px;
`;