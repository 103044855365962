import { ButtonColorFamilyEnum, ConfirmModal, Input } from "@cerebruminc/cerebellum";
import { useEffect, useState } from "react";
import { useDeleteOrganizationMutation } from "src/hooks";
import { showError, showSuccess } from "src/helpers";
import { ConfirmationMessage, Content, DangerText } from "./DeleteOrganizationModalStyles";
import { DeleteOrganizationModalProps } from "./types";
import { paths } from "src/const";
import { useRouter } from "next/router";
export const DeleteOrganizationModal = (props: DeleteOrganizationModalProps) => {
  const {
    onClose,
    onSuccess,
    show,
    organization
  } = props;
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const {
    mutateAsync: deleteOrganization,
    isLoading
  } = useDeleteOrganizationMutation();
  const router = useRouter();

  // If the modal is closed, reset the deleteConfirmText
  useEffect(() => {
    if (show === false) {
      setDeleteConfirmText("");
    }
  }, [show]);
  const handleDeleteOrganization = async () => {
    if (!organization) {
      return;
    }
    try {
      await deleteOrganization(organization.id).then(() => {
        setDeleteConfirmText("");
        showSuccess("Organization deleted successfully");
        onSuccess();
        router.push(paths.organizations);
      });
    } catch (error: any) {
      showError(error?.response?.data?.message || error?.message);
    }
  };
  return <ConfirmModal header={`Delete ${organization?.name}?`} onPrimaryClick={() => handleDeleteOrganization()} onSecondaryClick={onClose} onXClick={onClose} primaryButtonDisabled={deleteConfirmText !== organization?.shortName} primaryButtonLoading={isLoading} primaryButtonText="Delete Organization" primaryColorFamily={ButtonColorFamilyEnum.Red} secondaryAsText secondaryButtonText="Cancel" show={show} text="This cannot be undone.">
      <Content>
        <ConfirmationMessage>
          To confirm, type <DangerText>{organization?.shortName}</DangerText> in the box below.
        </ConfirmationMessage>
        <Input value={deleteConfirmText} onChange={e => setDeleteConfirmText(e.target.value)} />
      </Content>
    </ConfirmModal>;
};