import type { ButtonColorFamilyEnum } from "@cerebruminc/cerebellum";
export interface UserTableType {
  /** If present, will return a user with the matching email, or an empty table */
  findByEmail?: string;
}
export interface ThemeOverride {
  activeHeaderColor?: string;
  bottomPadding?: number;
  headerUnderlineColor?: string;
  highlightBoxShadow?: string;
  highlightRowColor?: string;
  inactiveHeaderColor?: string;
  noResultsButtonColorFamily?: ButtonColorFamilyEnum;
  noResultsTextColor?: string;
  rowBackgroundColor?: string;
  rowBackgroundColorAlternating?: string;
  rowBorderRadius?: number;
  sortIndicatorColor?: string;
  topPadding?: number;
}
export enum UserTableSortEnum {
  ID = "id",
  EMAIL = "email",
  FIRST_NAME = "firstname",
  LAST_NAME = "lastname",
  MIDDLE_NAME = "middlename",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  DELETED_AT = "deletedAt",
}
export enum UserTableFilterIds {
  Role = "roles",
  Organization = "organization",
}