import { UiText } from "@ory/kratos-client";
import { ORYMessageCode, ORY_MESSAGE_MAPPING, ORY_MESSAGE_TRANSFORM_MAPPING } from "src/const";

function isValidORYMessageCode(code: number): code is ORYMessageCode {
  return Object.values(ORY_MESSAGE_MAPPING).includes(code as any);
}

export const getMappedMessage = (message: UiText) => {
  const reason = (message.context as any)?.reason as string;
  // if error contains reason then id will contains multiple reasons mapping, for e.g. error id 4000005
  // or else it will be simple string mapping
  if (message.id && isValidORYMessageCode(message.id) && reason) {
    const messageObj = ORY_MESSAGE_TRANSFORM_MAPPING[message.id];

    if (typeof messageObj === "object") {
      if (messageObj[reason]) {
        // if we have exact reason match, then return it
        return messageObj[reason];
      }
      const reasonObj = messageObj || {};
      // try to find key that kind of matches with the reason, doesn't need to match exactly
      // useful incase of messages that has little dynamic content
      // for e.g.
      // "reason" is the message we get from ORY api
      // error id `4000005` contains different kind of messages, out of those messages, there is message set like
      // "password length must be at least 8 characters but only got 1", "password length must be at least 8 characters but only got 2", etc
      // here "1", "2", "3" upto "7" will be dynamic content in "reason"
      // in below line, "reasonObj" will be object from `4000005`
      // now we try to find look-a-like key from "reasonObj" that matches with "reason"
      // in our case it will be "password length must be at least 8 characters but only got"
      const key = Object.keys(reasonObj).find((key) => reason.includes(key));
      if (key) {
        return reasonObj?.[key];
      }
      return message?.text;
    }
    if (typeof messageObj === "string") {
      // Direct string mapping found
      return messageObj;
    }
  }

  // Fallback to message.text if no specific mapping found
  return message.text;
};
