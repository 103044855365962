import { atom } from "jotai";
import { RESET, atomWithReset, atomWithStorage } from "jotai/utils";
import {
  DisclosureAddress,
  Order,
  type BeamListQuery,
  type Organization,
  type VidVoucherQuery,
} from "src/generated/graphql";
import type { StringBooleanType } from "src/types";
import { VidIdTypeEnum, VidStepsEnum, vidSteps } from "./const";
import type { AdditionalPersonalInformation, ScanResult } from "./views/types";
import { StepType } from "@cerebruminc/cerebellum";

// this tracks the number of times user has tried to upload an image
export const successfulScanCountAtom = atom<number>(0);
export const rescanIdClickAtom = atom<boolean>(false);
// tracks if the uploaded image is a bad image or if the scanning failed. After
// max failed attempts, the user will be prompted to enter the information manually
// save this in the local storage to avoid issues when user reload the page
export const failedUploadCountAtom = atomWithStorage<number>("failedUploadCount", 0); // tracking this in Jotai to maintain state between pages
// after successful scan, but with wrong information, user can request that the
// id should be manually reviewed.
export const requestManualSearchAtom = atom<boolean>(false);

function blobToArrayBuffer(blob: any): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      resolve(arrayBuffer);
    };

    reader.onerror = () => {
      reject(new Error("Failed to read blob as array buffer."));
    };

    reader.readAsArrayBuffer(blob);
  });
}

function getImageFromObjectStore(objectStore: IDBObjectStore, key: any, fileType: File["type"]): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const request = objectStore.get(key);

    request.onerror = (event) => {
      console.error("Error getting image.", event);
      reject(new Error("Error getting image."));
    };

    request.onsuccess = () => {
      if (request.result) {
        const blob = new Blob([request.result], { type: fileType });
        resolve(blob);
      } else {
        reject(new Error("Image not found."));
      }
    };
  });
}

function blobToFile(blob: Blob, fileName: string) {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
}

export const clearIndexedDB = () => {
  const dbOpenRequest = window.indexedDB.open("vid", 1);
  dbOpenRequest.onerror = (event) => {
    // handle these errors!, e.g. When no DB is available, fallback to
    // localStorage?
    console.error("Error opening database.", event);
  };
  dbOpenRequest.onupgradeneeded = () => {
    const db = dbOpenRequest.result;
    db.createObjectStore("images");
  };
  dbOpenRequest.onsuccess = () => {
    const db = dbOpenRequest.result;
    const transaction = db.transaction("images", "readwrite");
    const objectStore = transaction.objectStore("images");
    objectStore.clear();
  };
};

export const atomWithFileStorage = <File>(key: string, initialValue: File) => {
  const fileDetailsKey = `${key}Details`;
  const fileBlobKey = `${key}Blob`;
  const baseAtom = atomWithReset(initialValue);
  let db: IDBDatabase | null = null;
  baseAtom.onMount = (setValue) => {
    (async () => {
      const dbOpenRequest = window.indexedDB.open("vid", 1);
      dbOpenRequest.onerror = (event) => {
        // handle these errors!, e.g. When no DB is available, fallback to localStorage?
        console.error("Error opening database.", event);
      };
      dbOpenRequest.onupgradeneeded = () => {
        db = dbOpenRequest.result;
        db.createObjectStore("images");
      };
      dbOpenRequest.onsuccess = () => {
        db = dbOpenRequest.result;
        const transaction = db.transaction("images", "readonly");
        const objectStore = transaction.objectStore("images");
        const request = objectStore.get(fileDetailsKey);
        request.onerror = (event) => {
          console.error("Error getting image.", event);
        };
        request.onsuccess = () => {
          if (request.result) {
            getImageFromObjectStore(objectStore, fileBlobKey, request.result.type).then((blob) => {
              const file = blobToFile(blob, request.result.name);
              setValue(file as any);
            });
          }
        };
      };
    })();
  };
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: File | typeof RESET) => {
      set(baseAtom, RESET === update ? initialValue : update);
      (async () => {
        if (update === RESET) {
          db?.transaction("images", "readwrite").objectStore("images").delete(fileDetailsKey);
          db?.transaction("images", "readwrite").objectStore("images").delete(fileBlobKey);
        } else {
          const nextValue = await blobToArrayBuffer(update);
          const { name, type } = update as any;
          db?.transaction("images", "readwrite").objectStore("images").put({ name, type }, fileDetailsKey);
          db?.transaction("images", "readwrite").objectStore("images").put(nextValue, fileBlobKey);
        }
      })();
    },
  );
  return derivedAtom;
};

export const uploadedFrontIdAtom = atomWithStorage<{
  url: string;
  name: string;
  type: string;
} | null>("uploadedFrontId", null);
export const uploadedBackIdAtom = atomWithStorage<{
  url: string;
  name: string;
  type: string;
} | null>("uploadedBackId", null);
export const uploadedSelfieAtom = atomWithStorage<{
  url: string;
  name: string;
  type: string;
} | null>("uploadedSelfie", null);

export const idFrontAtom = atomWithFileStorage<File | null>("idFront", null);
export const idBackAtom = atomWithFileStorage<File | null>("idBack", null);
export const selfieAtom = atomWithFileStorage<File | null>("selfie", null);
export const extractedPhotoAtom = atomWithStorage<string | null>("extractedPhoto", null);
// used for in browser scanning
export const extractedPhotoFileAtom = atomWithFileStorage<File | null>("extractedPhotoFile", null);
// to check the state of uploadImage when manualEntry is true
export const filesUploadStateAtom = atom<"success" | "error" | "idle">("idle");

export const vidPersonalInformationAtom = atomWithStorage<AdditionalPersonalInformation>("vidPersonalInformation", {
  aliases: [],
  addresses: [],
});

export const vidScanResultsAtom = atomWithStorage<ScanResult | null>("vidScanResults", null);

export const vidOrganizationAtom = atomWithStorage<Organization | null>("vidOrganization", null);
// export const vidVoucherAtom = atomWithStorage<VidVoucher | null>("vidVoucher", null);
export const vidVoucherAtom = atomWithStorage<VidVoucherQuery["vIDVoucher"] | null>("vidVoucher", null);
export const voucherIdAtom = atomWithStorage<string>("voucherId", "");
export const voucherEmailAtom = atomWithStorage<string>("voucherEmail", "");
// Sets a loading spinner while the voucher is checked.
export const processingVoucherAtom = atom<boolean>(true);

export const vidSSNAtom = atomWithStorage<string>("vidSSN", "");

export const vidIdTypeAtom = atomWithStorage<string>("vidIdType", VidIdTypeEnum.StateId);

export const completedVidStepsAtom = atomWithStorage<StringBooleanType>("completedVidSteps", {
  [VidStepsEnum.One]: false,
  [VidStepsEnum.Two]: false,
  [VidStepsEnum.Three]: false,
  [VidStepsEnum.Four]: false,
  // [key: string]: boolean,
});

export const ssnInfoModalVisibleAtom = atom<boolean>(false);
// Note: we save this value in the LocalStorage to avoid showing the modal when we already accepted the invite
export const acceptInviteModalVisibleAtom = atomWithStorage<boolean>("acceptInviteModalVisible", false);
export const noInvitationModalVisibleAtom = atom<boolean>(false);
export const noOrgDataModalVisibleAtom = atom<boolean>(false);

// ------ UI configuration -------
// Skip the SSN collection step or not
export const collectSSNAtom = atom<boolean>(false);
// Skip the Disclosure step or not
export const showDisclosuresAtom = atom<boolean>(false);
// Shows an "if required in your state" message in the sign disclosures step. True when the package has disclosures, but none are present without a location.
export const possibleStateDisclosuresAtom = atom<boolean>(false);
// The applicant's current address, which is used to determine Disclosures. Defaults to their scanned address, but can be changed in the personal-information view.
export const currentAddressAtom = atomWithStorage<DisclosureAddress | undefined>("currentAddress", undefined);

// Prevent loading of sensitive saved data after logout with this atom
export const hideSensitiveDataAtom = atom<boolean>(false);
// Allow a sample ID to be used for testing
export const allowSampleIdAtom = atom<boolean>(false);

// Stores the order created by vid-web
export const vidWebOrderAtom = atom<Pick<Order, "id" | "fileNumber"> | null>(null);

// Changes the UI for a better UX for one and done users
export const isOneAndDoneUserAtom = atomWithStorage<boolean>("isOneAndDoneUser", false);
export const disclosureAcceptanceIdAtom = atomWithStorage<string | undefined>("disclosureAcceptanceId", "");

// ------ Databeam -------
export type BeamData = {
  // we use any here as beam data can be of any form
  [id: string]: any;
};
export const passTypeBeamsDataAtom = atomWithStorage<BeamListQuery["beams"] | undefined>(
  "passTypeBeamsData",
  undefined,
);
export const beamInputFormDataAtom = atomWithStorage<BeamData | undefined>("beamInputFormData", undefined);
export const vidStepsAtom = atomWithStorage<StepType[]>("vidSteps", vidSteps);
