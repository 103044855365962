import { CheckboxGroup } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { availableFiltersAtom, filterModalFiltersAtom } from "src/components/FiltersModal/atom";
import { UserTableFilterIds } from "../types";
import { contentWidth } from "src/components/FiltersModal/FiltersModal";
import { TAB_PADDING_LEFT, TAB_PADDING_RIGHT } from "src/components/FiltersModal/FiltersModalStyles";
import { useUpdateFilterModalFilters } from "src/components/FiltersModal/hooks/useUpdateFilterModalFilters";
import { TabFormContainer } from "src/components/FiltersModal/TabFormContainer";
import React from "react";
const checkboxAndGap = 27;
const scrollbarAndGutter = 14;
const TAB_ID = UserTableFilterIds.Role;
export function RoleForm({
  allFilters
}: {
  allFilters: any;
}) {
  const availableFilters = useAtomValue(availableFiltersAtom);
  const draftFilters = useAtomValue(filterModalFiltersAtom);
  const updateFilterModalFilters = useUpdateFilterModalFilters();
  const maxLabelWidth = contentWidth - scrollbarAndGutter - TAB_PADDING_LEFT - TAB_PADDING_RIGHT - checkboxAndGap;
  const typeCheckedFilters = Array.isArray(draftFilters[TAB_ID]) ? draftFilters[TAB_ID] : [];
  const noActiveFilters = typeCheckedFilters?.length === 0;
  return <TabFormContainer tabId={TAB_ID} allFilters={allFilters} title="Role" noActiveFilters={noActiveFilters}>
      <CheckboxGroup activeValues={typeCheckedFilters} checkboxes={availableFilters?.roles?.map(item => ({
      label: item.label,
      value: item.value
    }))} label=" " maxLabelWidth={maxLabelWidth} name={TAB_ID} onClick={event => {
      const newValue = event.target.value;
      updateFilterModalFilters({
        filterIdArray: typeCheckedFilters,
        newValue,
        tabId: TAB_ID
      });
    }} />
    </TabFormContainer>;
}