// ref: https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
// caniuse: https://caniuse.com/?search=structuredClone
// structuredClone is faster than JSON.parse(JSON.stringify(obj)), but current
// support is only around 93%.
export function deepCloneObject<Type>(obj: Type): Type {
  if (typeof structuredClone === "function") {
    return structuredClone(obj);
  }
  return JSON.parse(JSON.stringify(obj));
}
