import { colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import { USER_CONTROLS_HEIGHT } from "src/const";
import { CARD_WIDTH, CardBox as OriginalCardBox, PAGE_GUTTER_CENTER, PAGE_GUTTER_LEFT, PAGE_GUTTER_RIGHT, RightSide } from "src/styles/PagesStyles";
interface BackgroundProps {
  $centerTopMargin: number;
  $headerHeight: number;
  $stackBreakpoint: number;
}
interface HeaderBaseProps {
  $stackedLayout: boolean;
}
interface StickyHeaderProps {
  $centerTopMargin: number;
  $excessMargin: number;
  $headerHeight: number;
  $show: boolean;
  $stackBreakpoint: number;
}
const backgroundTopPadding = 32;
export const stackAdjudication = 600;
export const TABS_BOX_HEIGHT = 59;
export const Background = styled.div<BackgroundProps>`
  background: ${colors.COOL_GREY_4};
  background: linear-gradient(
    180deg,
    ${colors.WHITE}
      ${({
  $centerTopMargin,
  $headerHeight
}) => $headerHeight + backgroundTopPadding + $centerTopMargin + TABS_BOX_HEIGHT + 20}px,
    ${colors.COOL_GREY_4}
      ${({
  $centerTopMargin,
  $headerHeight
}) => $headerHeight + backgroundTopPadding + $centerTopMargin + TABS_BOX_HEIGHT + 20}px
  );
  min-height: calc(100vh - ${USER_CONTROLS_HEIGHT}px);
  padding-top: ${backgroundTopPadding}px;
  position: relative;
  @media all and (max-width: ${({
  $stackBreakpoint
}) => $stackBreakpoint - 1}px) {
    background: linear-gradient(
      180deg,
      ${colors.WHITE}
        ${({
  $centerTopMargin,
  $headerHeight
}) => $headerHeight + backgroundTopPadding + $centerTopMargin + TABS_BOX_HEIGHT + 8}px,
      ${colors.COOL_GREY_4}
        ${({
  $centerTopMargin,
  $headerHeight
}) => $headerHeight + backgroundTopPadding + $centerTopMargin + TABS_BOX_HEIGHT + 8}px
    );
  }

  @media print {
    display: none;
  }
`;
export const HeaderBase = styled.div<HeaderBaseProps>`
  margin: ${({
  $stackedLayout
}) => $stackedLayout ? "5px 4% 0" : 0};
  @media print {
    margin: 5px 4% 0;
  }
`;
export const CardBox = styled(OriginalCardBox)<{
  $stackBreakpoint: number;
}>`
  display: none;
  z-index: 3;
  @media all and (min-width: ${({
  $stackBreakpoint
}) => $stackBreakpoint}px) {
    display: block;
  }
`;
export const StackedCardBox = styled.div<{
  $stackBreakpoint: number;
}>`
  display: none;
  position: relative;
  margin: 30px 4% 21px;
  z-index: 2;
  @media all and (max-width: ${({
  $stackBreakpoint
}) => $stackBreakpoint - 1}px), print {
    display: block;
  }
`;
export const StickyHeader = styled(RightSide)<StickyHeaderProps>`
  background-color: ${colors.WHITE};
  grid-row-start: 1;
  grid-row-end: 2;
  position: sticky;
  margin-bottom: -${({
  $headerHeight
}) => $headerHeight + USER_CONTROLS_HEIGHT + backgroundTopPadding + 10}px;
  top: ${USER_CONTROLS_HEIGHT + backgroundTopPadding}px;
  visibility: ${({
  $show
}) => $show ? "visible" : "hidden"};
  transform: ${({
  $show
}) => $show ? "translate3d(0,0,0)" : "translate3d(0,-400px,0)"};
  transition: transform 150ms ease-out;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: ${colors.WHITE};
    left: -${({
  $excessMargin
}) => $excessMargin + CARD_WIDTH + PAGE_GUTTER_CENTER + PAGE_GUTTER_LEFT}px;
    right: -${({
  $excessMargin
}) => $excessMargin + PAGE_GUTTER_RIGHT}px;
    top: -9999px; // extends above the top of the page, so as to never leave a gap under the navbar
  }
  @media all and (max-width: ${({
  $stackBreakpoint
}) => $stackBreakpoint - 1}px), print {
		display: none;
	}
`;
export const HeaderBox = styled.div`
  position: relative;
`;
export const RightSideOverlap = styled.div`
	grid-column-start: tabs-start;
  grid-column-end: tabs-end;
  grid-row-start: 1;
  grid-row-end: 2;
  padding-bottom: 20px;
`;