import { Avatar, BodySEmphasis, colors, type ComponentWrapperType } from "@cerebruminc/cerebellum";
import React, { type FC } from "react";
import { styled } from "styled-components";
import { useGetUserById } from "src/hooks";
export const NameBase = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
`;
export const NameCell: FC<ComponentWrapperType> = ({
  row
}) => {
  const userId = row.user;
  // getting user
  const {
    data
  } = useGetUserById((userId as string));
  return <NameBase>
      <div>
        <Avatar backgroundGradient="linear-gradient(180deg, #5377EC 0%, #C0AAFF 56.31%, #FFFFFF 100%)" imageUrl={row.avatarUrl} placeholderInitials={`${data?.firstname?.slice(0, 1)}${data?.lastname?.slice(0, 1)}`} themeOverride={{
        size: 50
      }} />
      </div>
      <div>
        <BodySEmphasis $textColor={colors.COOL_GREY_100}>{data?.email}</BodySEmphasis>
      </div>
    </NameBase>;
};