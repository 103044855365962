import type { StepType } from "@cerebruminc/cerebellum";

export const MAX_FAILED_UPLOAD_COUNT = 2;

export const fieldNames = {
  idFront: "idFront",
  idBack: "idBack",
  selfie: "selfie",
};

export enum VidStepsEnum {
  One = "vid/selfie",
  Two = "vid/id-upload",
  Three = "vid/ssn",
  Four = "vid/sign-disclosures",
  // conditional step
  Five = "vid/additional-information",
}

export enum VidIdTypeEnum {
  StateId = "State-Issued ID",
  AlternateId = "Alternate ID",
}

// Default vid steps
export const vidSteps: StepType[] = [
  {
    id: VidStepsEnum.One,
    label: "Liveness Detection",
  },
  {
    id: VidStepsEnum.Two,
    label: "ID Scan and Review",
  },
  {
    id: VidStepsEnum.Three,
    label: "SSN",
  },
  {
    id: VidStepsEnum.Four,
    label: "Disclosures",
  },
];
