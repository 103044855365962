import { DialogHandler, SnackTypeEnum, ConnotationColorEnum } from "@cerebruminc/cerebellum";
import type { AxiosError } from "axios";
import type { NextRouter } from "next/router";
import queryString from "query-string";
import type { Dispatch, SetStateAction } from "react";
import { paths } from "src/const";
import { createRedirectionWithParams, validateReturnToUrl } from "./helpers";
export interface AxiosErrorType {
  error: {
    id?: string;
    code?: number;
  };
  redirect_browser_to: string;
}

// A small function to help us deal with errors coming from fetching a flow.
export function handleGetFlowError<S>(router: NextRouter, flowType: string, resetFlow: Dispatch<SetStateAction<S | undefined>>) {
  return (err: AxiosError<AxiosErrorType>) => {
    // getting return_to
    const {
      query: params
    } = queryString.parseUrl(window.location.href);
    const returnTo = validateReturnToUrl((params?.return_to as string)) ? (params?.return_to as string) : undefined;
    if (err.response?.data?.error?.code === 401) {
      router.push(paths.login);
      return;
    }
    switch (err.response?.data?.error?.id) {
      case "session_aal2_required":
        {
          // 2FA is enabled and enforced, but user did not perform 2fa yet!
          window.location.replace(err.response.data?.redirect_browser_to);
          return;
        }
      case "session_already_available":
        // User is already signed in, let's redirect them to returnTo param or home!
        if (returnTo) {
          const finalUrl = createRedirectionWithParams(returnTo);
          window.location.href = (finalUrl as string);
        } else {
          router.push(paths.home);
        }
        return;
      case "session_refresh_required":
        // We need to re-authenticate to perform this action
        window.location.href = err.response?.data?.redirect_browser_to;
        return;
      case "self_service_flow_return_to_forbidden":
        // The flow expired, let's request a new one.
        DialogHandler.show({
          snackType: SnackTypeEnum.Toast,
          text: "The return_to address is not allowed.",
          colorFamily: ConnotationColorEnum.Negative
        });
        resetFlow(undefined);
        window.location.href = flowType;
        return;
      case "self_service_flow_expired":
        // The flow expired, let's request a new one.
        DialogHandler.show({
          snackType: SnackTypeEnum.Toast,
          text: "Your interaction expired, please fill out the form again.",
          colorFamily: ConnotationColorEnum.Negative
        });
        resetFlow(undefined);
        router.push(flowType);
        return;
      case "security_csrf_violation":
        // A CSRF violation occurred. Best to just refresh the flow!
        DialogHandler.show({
          snackType: SnackTypeEnum.Toast,
          text: "A security violation was detected, please fill out the form again.",
          colorFamily: ConnotationColorEnum.Negative
        });
        resetFlow(undefined);
        router.push(flowType);
        return;
      case "security_identity_mismatch":
        // The requested item was intended for someone else. Let's request a new flow...
        resetFlow(undefined);
        router.push(flowType);
        return;
      case "browser_location_change_required":
        // Ory Kratos asked us to point the user to this URL.
        window.location.replace(err.response.data?.redirect_browser_to);
        return;
      case "session_inactive":
        router.push(paths.login);
        return;
    }
    switch (err.response?.status) {
      case 410:
        // The flow expired, let's request a new one.
        resetFlow(undefined);
        router.push("/" + flowType);
        return;
    }

    // We are not able to handle the error? Return it.
    return err;
  };
}

// A small function to help us deal with errors coming from initializing a flow.
export const handleFlowError = handleGetFlowError;