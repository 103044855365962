import { useAtomValue, useSetAtom } from "jotai";
import { useState, useRef, useCallback, useEffect } from "react";
import { DisclosureAddress, useCheckVoucherValidityQuery, useRetrieveDisclosuresQuery } from "src/generated/graphql";

import { VidStepsEnum } from "./../../const";
import {
  vidVoucherAtom,
  showDisclosuresAtom,
  voucherIdAtom,
  vidStepsAtom,
  passTypeBeamsDataAtom,
  completedVidStepsAtom,
} from "./../../state";
import { sanitizeAddresses, getVidSteps } from "./../helpers";

export const useCheckForDisclosures = () => {
  const vIdVoucher = useAtomValue(vidVoucherAtom);
  const setShowDisclosures = useSetAtom(showDisclosuresAtom);
  const voucherId = useAtomValue(voucherIdAtom);
  const setVidSteps = useSetAtom(vidStepsAtom);
  const beams = useAtomValue(passTypeBeamsDataAtom);
  const setCompletedSteps = useSetAtom(completedVidStepsAtom);

  const [addresses, setAddresses] = useState<DisclosureAddress[] | undefined>([]);
  const [shouldCheckDisclosures, setShouldCheckDisclosures] = useState(false);
  const resolveRef = useRef<() => void>();

  const additionalSteps =
    beams?.map((beam) => ({
      id: `${VidStepsEnum.Five}/${beam.id}`,
      label: beam.name,
    })) ?? [];

  const { data: voucherValidity, refetch: refetchVoucherValidity } = useCheckVoucherValidityQuery(
    { voucherId },
    { refetchOnWindowFocus: false, enabled: !!voucherId },
  );

  const sanitizedAddresses = sanitizeAddresses(addresses);
  const { refetch: refetchDisclosures } = useRetrieveDisclosuresQuery(
    {
      packageId: vIdVoucher?.packageSetId ?? "",
      addresses: sanitizedAddresses ?? [],
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vIdVoucher?.packageSetId && addresses && addresses.length > 0,
      select(data) {
        const stateDisclosureArray = data.retrieveDisclosures ?? [];
        return stateDisclosureArray;
      },
    },
  );

  const checkForDisclosures = useCallback(
    (newAddresses?: DisclosureAddress[]) =>
      new Promise<void>((resolve) => {
        setAddresses(newAddresses);
        setShouldCheckDisclosures(true);
        resolveRef.current = resolve;
      }),
    [],
  );

  useEffect(() => {
    if (!shouldCheckDisclosures) return;

    const executeChecks = async () => {
      await refetchVoucherValidity();
      const disclosuresRes = await refetchDisclosures();

      const sanitizedAddresses = sanitizeAddresses(addresses);
      const usState = sanitizedAddresses?.[0]?.state;
      const latestDisclosures = disclosuresRes?.data || [];
      const showDisclosuresStep = Boolean(voucherValidity?.checkVoucherValidity?.packageSet?.showDisclosures);
      setShowDisclosures(Boolean(showDisclosuresStep));

      const noDisclosures = !(latestDisclosures?.length > 0);
      setCompletedSteps((prev) => {
        const updatedSteps = { ...prev };
        updatedSteps[VidStepsEnum.Four] = noDisclosures && showDisclosuresStep;
        return updatedSteps;
      });

      const updatedSteps = getVidSteps({
        additionalSteps,
        collectSSN: Boolean(voucherValidity?.checkVoucherValidity?.packageSet.collectSSN),
        showDisclosuresStep,
        noStateDisclosures: noDisclosures,
        usState,
      });
      setVidSteps(updatedSteps);

      setShouldCheckDisclosures(false);
      resolveRef.current?.();
    };

    executeChecks();
  }, [
    shouldCheckDisclosures,
    refetchVoucherValidity,
    refetchDisclosures,
    voucherValidity,
    additionalSteps,
    setCompletedSteps,
    setShowDisclosures,
    setVidSteps,
    addresses,
  ]);

  return checkForDisclosures;
};
