import { DialogHandler, ConnotationColorEnum, SnackTypeEnum } from "@cerebruminc/cerebellum";
import { useAtom, useSetAtom, useAtomValue } from "jotai";
import router from "next/router";
import { useState, ReactNode, useRef, useEffect, useCallback, useMemo } from "react";
import { useAcceptInviteMutation, useCheckVoucherValidityQuery, useGetInviteQuery } from "src/generated/graphql";
import { analytics, neuronSdk } from "src/helpers";
import { acceptInviteModalVisibleAtom, voucherIdAtom, vidVoucherAtom, isOneAndDoneUserAtom } from "./../../state";
import { useVidGetBeams } from "./useVidGetBeams";
import { useEmailVerification } from "./useEmailVerification";
import { useCheckForDisclosuresAndSsn } from "./useCheckForDisclosuresAndSsn";
import { useVerifyInviteAndEmail } from "./useVerifyInviteAndEmail";
export const useVidInviteAuthorization = () => {
  const [modalVisible, setModalVisible] = useAtom(acceptInviteModalVisibleAtom);
  const voucherIdFromState = useAtomValue(voucherIdAtom);
  const vIdVoucher = useAtomValue(vidVoucherAtom);
  const isOneAndDoneUser = useAtomValue(isOneAndDoneUserAtom);
  const [helperText, setHelperText] = useState<ReactNode | null>();
  const emailVerified = useEmailVerification({
    setHelperText
  });
  const {
    refetchBeamQuery
  } = useVidGetBeams();
  const {
    mutateAsync: acceptInviteAsync,
    isLoading: processingInvite
  } = useAcceptInviteMutation({
    onError: error => {
      analytics.track("Accept Invite Error", {
        error
      });
      DialogHandler.show({
        buttonClick: () => window.open("mailto:support@cerebrum.com", "_blank"),
        buttonText: "Contact Support",
        colorFamily: ConnotationColorEnum.Negative,
        snackType: SnackTypeEnum.Dialog,
        title: "Oh no!",
        text: "There was an error with your request"
      });
    },
    onSuccess: async () => {
      // NOTE: we are refeching this Query to get PackageSet data
      // in the first request we are not in the organization (GUEST role)
      // when we accept the invite our role is USER and we have permission to read PackageSet data in the own organization
      await refetchVoucherValidty();

      // Note: this is to request Beams when user has USER role
      await refetchBeamQuery();
      analytics.track("Accept Invite Success");
    }
  });
  // prefer voucher id from url over local voucher id
  const voucherId = (router.query.voucherId as string) ?? voucherIdFromState;
  useEffect(() => {
    analytics.identify(voucherId);
  }, [voucherId]);
  useEffect(() => {
    // One and done users are already verified and part of the org.
    if (isOneAndDoneUser) {
      setModalVisible(false);
    }
  });
  const {
    data: voucherValidity,
    isLoading: isValidatingVoucher,
    refetch: refetchVoucherValidty
  } = useCheckVoucherValidityQuery({
    voucherId
  }, {
    refetchOnWindowFocus: false,
    enabled: !!voucherId
  });

  // We are explicitly checking for invite validity here instead of
  // directly checking if the user is already a part of the org via
  // userDataAtom because both these queries are not sequenced. That is if
  // the whoAmI called in withAuth takes longer than
  // checkVoucherValidity query above, the user will be shown the Modal.
  const {
    data: inviteValidity,
    isLoading: isValidatingInvite,
    error: errorValidatingInvite
  } = useGetInviteQuery({
    inviteId: voucherValidity?.checkVoucherValidity?.inviteId ?? ""
  }, {
    refetchOnWindowFocus: false,
    // Get Invite will always fail if the email is not verified
    enabled: Boolean(emailVerified)
    // TODO: make the below line work instead of above. Right now this hangs the call, but it should resolve. Enabling this without inviteId causes a 400 error to return.
    // enabled: Boolean(emailVerified) && Boolean(voucherValidity?.checkVoucherValidity?.inviteId),
  });

  // Verify the invite & email once the voucher is verfied
  const verifyInviteAndEmail = useVerifyInviteAndEmail({
    emailVerified,
    errorValidatingInvite,
    inviteValidity,
    isValidatingInvite,
    setHelperText,
    voucherId,
    voucherValidity
  });
  useEffect(() => {
    if (!isValidatingVoucher) {
      verifyInviteAndEmail();
    }
  }, [verifyInviteAndEmail, isValidatingVoucher]);

  // Check the package for disclosures and SSN, and update the UI
  useCheckForDisclosuresAndSsn({
    vIdVoucher,
    voucherValidity
  });
  const acceptInvite = useCallback(async () => {
    if (emailVerified && vIdVoucher?.inviteId) {
      analytics.track("Attempt Accept Invite");
      await acceptInviteAsync({
        inviteId: vIdVoucher.inviteId
      });
    }
    setModalVisible(false);
  }, [acceptInviteAsync, emailVerified, setModalVisible, vIdVoucher]);
  const isButtonEnabled = Boolean(emailVerified && vIdVoucher?.inviteId);
  const handlers = useMemo(() => ({
    acceptInvite,
    processingInvite,
    emailVerified,
    modalVisible,
    helperText,
    isButtonEnabled
  }), [acceptInvite, processingInvite, emailVerified, modalVisible, helperText, isButtonEnabled]);
  return handlers;
};