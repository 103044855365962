import React from "react";
import { Monospace, NarrowMono } from "./FormattedFileNumberStyles";
import type { FormattedFileNumberProps } from "./types";
export const FormattedFileNumber = ({
  fileNumber,
  underline
}: FormattedFileNumberProps) => {
  const letters = fileNumber ? fileNumber.slice(0, 3) : "";
  const numbers = fileNumber ? fileNumber.slice(3) : "--";
  return <Monospace className="endToEndTesting_orderTableFileNumber" $underline={underline}>
      <NarrowMono $underline={underline}>{letters}</NarrowMono>
      {numbers}
    </Monospace>;
};
export default FormattedFileNumber;