export const convertStringIntoSVGBase64 = (sign: string) => {
  const svg = `
  <?xml version="1.0" encoding="UTF-8"?>
  <svg  viewBox="0 0 ${sign.length ? sign.length * 10 : 500} 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <text x="10" y="15" fill="black">${sign}</text>
  </svg>
  `;
  const svgBase64 = `data:image/svg+xml;base64,${btoa(svg)}`;

  return svgBase64;
};
