import { BodyLEmphasis, BodyMEmphasis, BodyMPrimary, BodySTertiary, type ButtonType, HeadingH1, ShortScroller, SubheadingSEmphasis, colors, loadingPseudo } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import { HEADER_HEIGHT } from "./../components/VidHeader";
import Link from "next/link";

// NOTE: Decrease this once the stepper is installed
const NO_STEPPER_BREAKPOINT = 900;

// -------- General --------
export const VidBase = styled.div`
  display: grid;
  grid-template-columns: [stepper-start] 330px [content-start] 1fr [content-end];
  gap: 63px;
  margin: 0 auto;
  max-width: 1350px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  padding: 80px 20px;
  width: 100%;
  @media all and (max-width: ${NO_STEPPER_BREAKPOINT}px) {
    display: flex;
  }
  @media all and (max-width: 700px) {
    padding: 30px 5%;
  }
`;
export const VidCenterBase = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  padding: 57px 0;
  @media all and (max-width: 700px) {
    padding: 57px 8%;
  }
`;
export const StepperBox = styled.div`
  grid-column-start: stepper-start;
  grid-column-end: content-start;
  height: 367px;
  position: sticky;
  top: 50px;
  width: 330px;
  @media all and (max-width: ${NO_STEPPER_BREAKPOINT}px) {
    display: none;
  }
`;
export const MainContents = styled.div<{
  $maxWidth?: number;
}>`
  max-width: ${({
  $maxWidth
}) => $maxWidth || 780}px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  grid-column-start: content-start;
  grid-column-end: content-end;
  margin-top: 21px;
  @media all and (max-width: ${NO_STEPPER_BREAKPOINT}px) {
    margin: 0 auto;
  }
`;
export const ForceWidth = styled.div<{
  $width?: number;
}>`
  max-width: 100%;
  width: ${({
  $width
}) => $width || 780}px;
`;
export const Title = styled(HeadingH1)`
  margin: 20px 0 14px;
`;
export const FormText = styled(BodyMPrimary)`
  margin-bottom: 25px;
  max-width: 100%;
`;

// -------- Landing --------

export const LandingContainer = styled.div`
  background: linear-gradient(180.71deg, #5377EC 7.06%, #C0AAFF 50.18%, #FFFFFF 94.53%);
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
`;
export const LandingLeftSection = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  width: 50%;

  @media all and (max-width: 900px) {
    width: 100%;
  }
`;
export const LandingLogo = styled.img`
  margin-bottom: 20px;
  width: 120px;
`;
export const LandingHeading = styled.h1`
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
  margin-bottom: 50px;
  margin-top: 30px;

  @media all and (max-width: 900px) {
    font-size: 40px;
    line-height: 50px;
  }
`;
export const LandingSubheading = styled.h2`
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 5px;
`;
export const LandingInstruction = styled.p`
  font-size: 16px;
`;
export const LandingQRCode = styled.img`
  height: 150px;
  margin-bottom: 20px;
  width: 150px;
`;
export const LandingBrowserLink = styled.p`
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;
export const LandingButtonBox = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  color: #000;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 16px;
  width: 100%;
  text-align: left;
`;
export const LandingOptOutLink = styled(Link)`
  color: ${colors.COOL_GREY_65};
  font-size: 16px;
  line-height: 30px;
  text-decoration: underline;
`;
export const LandingRightSection = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 50%;

  @media all and (max-width: 900px) {
    display: none;
  }
`;
export const LandingPhoneMockup = styled.img`
  height: auto;
  width: 80%;
`;

// ----- Landing Mobile -----

export const LandingMobileContainer = styled.div`
  background: linear-gradient(168.6deg, #5377EC -16.62%, #C0AAFF 41.24%, #FFFFFF 100.77%);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 110px 20px 40px 20px;
`;
export const LandingMobileHeadingBox = styled.div`
  padding: 0 16px;
`;
export const LandingMobileLogo = styled.img`
  margin-bottom: 30px;
  margin-top: 40px;
  width: 84px;
`;
export const LandingMobileHeading = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 40px;
  margin-top: 15px;
`;
export const LandingMobileActions = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 40px;
  padding: 16px;
  width: 100%;
`;
export const LandingMobileActionItemBox = styled.div`
`;
export const LandingMobileActionItem = styled.div`
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
`;
export const LandingMobileAppInfo = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;
export const LandingMobileAppLogo = styled.img`
  height: 30px;
  margin-right: 10px;
  width: 30px;
`;
export const LandingMobileButton = styled.button`
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
`;
export const LandingMobileOptOutLink = styled.a`
  color: ${colors.COOL_GREY_65};
  display: block;
  font-size: 16px;
  line-height: 30px;
  margin-top: 0 auto;
  text-align: center;
  text-decoration: underline;
`;

// -------- Welcome --------
export const WelcomeLogoBox = styled.div`
  height: 88px;
  width: 88px;
  overflow: hidden;
`;
export const WelcomeTitle = styled.h1`
  color: ${colors.BLACK};
  font-size: 40px;
  margin: 18px 0 24px;
  text-align: center;
`;
export const TimerGroup = styled.div`
  align-items: center;
  background-color: ${colors.COOL_GREY_4};
  border-radius: 17px;
  display: flex;
  gap: 12px;
  justify-content: center;
  height: 34px;
  margin-bottom: 24px;
  width: 162px;
`;
export const TimerIconBox = styled.div`
  height: 19px;
  width: 19px;
`;
export const InstructionsHeader = styled(BodyLEmphasis)<{
  $loading?: boolean;
}>`
  text-align: center;
  line-height: 28px;

  ${({
  $loading
}) => $loading && loadingPseudo({
  topOffset: 9,
  bgColor: colors.COOL_GREY_4,
  loadingColor: colors.COOL_GREY_15,
  borderRadius: 6
})}
`;
export const InstructionsGroup = styled.div`
  margin: 38px 0 60px;
  max-width: 535px;
  width: 90%;
`;
export const InstructionBox = styled.div`
  align-items: center;
  display: flex;
  gap: 25px;
  min-height: 91px;
  @media screen and (max-width: 400px) {
    gap: 20px;
  }
`;
export const TextBox = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.COOL_GREY_20};
  display: flex;
  justify-content: space-between;
  min-height: 91px;
  padding: 25px 0;
  flex: 1 0;
  gap: 5px;
  & * > {
    flex: 1 0;
  }
`;
export const TextBoxStacked = styled(TextBox)<{
  $stacked: boolean;
}>`
	align-items: ${({
  $stacked
}) => $stacked ? "flex-start" : "center"};
  flex-direction: ${({
  $stacked
}) => $stacked ? "column" : "row"};
  gap: 2px;
`;
export const InstructionText = styled(BodyLEmphasis)`
  line-height: 24px;
  flex: 1 1;
`;
export const InstructionHelper = styled(BodySTertiary)`
	color: ${colors.COOL_GREY_60};
  letter-spacing: 0;
	font-weight: 600;
`;
export const Black = styled.span`
  color: ${colors.BLACK};
`;
export const Blue = styled.span`
  color: ${colors.BLUE_100};
`;

// -------- ID Upload --------
export const MessageBox = styled.div`
  border: 1px solid ${colors.BLUE_100};
  border-radius: 10px;
  background-color: ${colors.BLUE_5};
  padding: 25px 32px 35px;
  max-width: 530px;
  margin: 10px 0 30px 0;
`;
export const MessageTitle = styled(BodyMEmphasis)`
  color: ${colors.BLUE_100};
`;
export const ListBox = styled.div`
  padding-left: 17px;
  margin-top: 11px;
`;
export const List = styled.ul`
  list-style-type: disc;
  margin: 0;
  padding: 0;

  li {
    line-height: 24px;
    padding-left: 10px;
  }
`;
export const FormContainer = styled.div<{
  $narrow: boolean;
}>`
  max-width: ${({
  $narrow
}) => $narrow ? 550 : 1000}px;
`;
export const ToggleButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
`;

// -------- Personal Information --------
export const IdData = styled.div`
  margin-top: 29px;
  margin-bottom: 39px;
`;
export const IdDataSection = styled.div`
  background-color: ${colors.COOL_GREY_4};
  border-radius: 10px;
  padding: 20px 24px;
  margin-bottom: 7px;
`;
export const ReadOnlyLabel = styled(SubheadingSEmphasis)`
  margin-bottom: 6px;
`;
export const FormBox = styled.div`
  margin-top: 20px;
`;
export const RescanBox = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid ${colors.COOL_GREY_20};
  margin-bottom: 32px;
`;
export const RescanButtonBox = styled.div`
  margin-top: 15px;
`;
export const CheckboxWrapper = styled.div`
  margin-top: 28px;
`;

// -------- SSN ---------
export const SsnButtonWrapper = styled.div`
  display: block;
  margin-bottom: 50px;
  margin-top: -65px;
`;
export const NoSsnBox = styled.div`
  background-color: #f6f8fb;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 70px 0;
`;
export const NoSsnText = styled.div`
  text-align: left;
`;
export const SubHeading = styled(SubheadingSEmphasis)`
  margin-bottom: 7px;
`;
export const HiddenSubmit = styled.div<ButtonType>`
  display: none;
`;

// -------- Success --------
export const CenterBoxWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - 57 * 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`;
export const CenterBox = styled.div`
  text-align: center;
  padding-bottom: ${HEADER_HEIGHT}px;
`;
export const LogoBox = styled.div<{
  $size?: number;
}>`
  width: ${({
  $size
}) => $size ?? 65}px;
  height: ${({
  $size
}) => $size ?? 65}px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 26px;
`;
export const ButtonBox = styled.div`
  margin-top: 30px;
`;
export const SuccessTitle = styled(WelcomeTitle)`
  font-weight: 600;
`;
export const SuccessMessage = styled.div`
  align-items: center;
  color: ${colors.COOL_GREY_65};
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  margin: 85px auto 0;
  max-width: 430px;
`;

// -------- Disclosures --------
export const ContentWrapper = styled(MainContents)`
  width: 100%;
`;
export const DisclosureScroller = styled(ShortScroller)`
  overflow-y: scroll;
  max-height: 508px;
  width: 780px;
  max-width: 100%;
  border-radius: 15px;
  padding: 40px 45px;
  color: #00010d;
  font-family: Nunito Sans;
  font-size: 16px;
`;
export const DisclosurePara = styled.div`
  max-height: 508px;
  width: 780px;
  margin-top: 20px;
  max-width: 100%;
  border: 1px solid #c0c7d5;
  border-radius: 15px;
  overflow: hidden;

  h1 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 39px;
    @media all and (max-width: 400px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 9px;
  }
  p {
    color: #00010d;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
  }
  li {
    color: #00010d;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 52px;
    text-indent: -22px;
  }
  @media all and (max-width: ${NO_STEPPER_BREAKPOINT}px) {
    margin-top: 45px;
  }
`;
export const SignatureBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  background-color: ${colors.BLUE_5};
  width: 691px;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
`;
export const SignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TrashIconWrapper = styled.div`
  position: absolute;
  top: 33px;
  right: 37px;
`;
export const InputWrapper = styled.div`
  width: 691px;
  max-width: 100%;
`;
export const Spacer = styled.div<{
  $padding?: string;
  $margin?: string;
}>`
  padding: ${props => props.$padding ? props.$padding : "0px"};
  margin: ${props => props.$margin ? props.$margin : "0px"};
`;
export const Line = styled.div`
  width: calc(100% - 143px);
  position: absolute;
  left: 77px;
  bottom: 99px;
  height: 1px;
  background-color: ${colors.COOL_GREY_50};

  @media (max-width: 426px) {
    width: calc(100% - 100px);
    left: 50px;
    bottom: 80px;
  }
`;
export const SignatureCross = styled.div`
  position: absolute;
  left: 50px;
  bottom: 140px;
  width: 15px;
  height: 15px;

  @media (max-width: 426px) {
    bottom: 120px;
  }
`;
export const InfoSection = styled.div`
  background: ${colors.BLUE_5};
  border: 1px solid ${colors.BLUE_100};
  border-radius: 10px;
  padding: 26px 34px;
  margin-bottom: 36px;
  max-width: 529px;
`;
export const UL = styled.ul`
  list-style-type: disc;
  padding: 10px 16px;

  > li {
    padding: 5px 10px;
  }
`;
export const SelfieInputWrapper = styled.div`
  align-items: center;
  border-radius: 5px;
  border: 1px solid #cfd6e3;
  display: flex;
  flex-direction: column;
  height: 416px;
  justify-content: space-between;
  opacity: 1;
  padding-bottom: 32px;
  position: relative;
  width: 320px;

  @media (max-width: 400px) {
    width: 90%;
  }
`;
export const PreviewWrapper = styled.div`
  margin: 10px;
`;
export const Img = styled.img`
  border-radius: 5px;
  border: 1px solid #979797;
  max-height: 500px;
  max-width: 500px;
  object-fit: cover;
  width: 100%;
`;
export const ImagePickerLabel = styled.label`
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
`;