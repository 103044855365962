import { DisclosureAddress } from "src/generated/graphql";

export const sanitizeAddresses = (addresses?: any[]): DisclosureAddress[] | undefined => {
  if (!addresses || addresses.length === 0) {
    return undefined;
  }
  return addresses.map(({ addressLineOne, addressLineTwo, city, country, postalCode, state }) => {
    return { addressLineOne, addressLineTwo, city, country, postalCode, state };
  });
};
