type LoadPhotoProps = {
  name: string;
  fileType: string;
  url: string;
};

export async function loadPhoto({ name, fileType, url }: LoadPhotoProps) {
  const response = await fetch(url);

  if (!response.ok) {
    console.log({ response });
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const blob = await response.blob();
  const file = new File([blob], name, { type: fileType }); // use the appropriate filename and mimeType
  return file;
}
