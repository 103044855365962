import { BodyMEmphasis, type ComponentWrapperType } from "@cerebruminc/cerebellum";
import React, { type FC } from "react";
import { rolePrettyNameLib } from "../helpers";
import { useAtomValue } from "jotai";
import { currentOrgIdAtom } from "src/atom";
export const RoleCell: FC<ComponentWrapperType> = ({
  row
}) => {
  const currentOrgId = useAtomValue(currentOrgIdAtom);
  const allOrgsMode = currentOrgId === null;
  let name = "";
  if (allOrgsMode) {
    const allRoleNames: string[] = row?.roleAssignment?.map((item: any) => rolePrettyNameLib(item.roleV2.name));
    const uniqueRoleNames = allRoleNames.filter((value, index, array) => array.indexOf(value) === index);
    name = uniqueRoleNames.join(", ");
  } else {
    const match = row?.roleAssignment?.find((item: any) => item.organizationId === currentOrgId);
    name = rolePrettyNameLib(match?.roleV2.name);
  }
  return <BodyMEmphasis>{name}</BodyMEmphasis>;
};