import { useAtomValue } from "jotai";
import { draftFiltersChangedAtom } from "../atom";
import { AllFiltersType, GenericFilterModalFiltersType } from "../types";
import { useCallback } from "react";
import { selectAtom } from "jotai/utils";

export const useApplyButtonText = ({ allFilters }: { allFilters: AllFiltersType }) => {
  const hasDraftFiltersChanged = useAtomValue(
    selectAtom(
      draftFiltersChangedAtom,
      useCallback(
        (atoms) => {
          const filters = Object.values(allFilters);

          return !filters.every((filter) => {
            const draftFilter = atoms.draftState[filter as keyof GenericFilterModalFiltersType];
            const tableFilter = atoms.tableState[filter as keyof GenericFilterModalFiltersType];
            if (filter === "range") {
              return atoms.draftFromDate === atoms.tableFromDate && atoms.draftToDate === atoms.tableToDate;
            }

            if (Array.isArray(draftFilter) && Array.isArray(tableFilter)) {
              return JSON.stringify(draftFilter) === JSON.stringify(tableFilter);
            }
            return draftFilter === tableFilter;
          });
        },
        [allFilters],
      ),
    ),
  );

  return hasDraftFiltersChanged ? "Apply" : "Close";
};
