// Note: this only uses middleName if both firstName and lastName are provided.
export const formatUserName = ({
  firstName,
  fullLastName,
  lastName,
  middleName,
}: {
  firstName?: string | null;
  fullLastName?: boolean;
  lastName?: string | null;
  middleName?: string | null;
}) => {
  if (firstName && lastName) {
    const middleNameFormatted = middleName ? ` ${middleName} ` : " ";
    const lastNameFormatted = fullLastName ? lastName : lastName.slice(0, 1) + ".";
    return `${firstName}${middleNameFormatted}${lastNameFormatted}`;
  }

  return firstName || lastName || "";
};
