import { Truncate, colors, loadingPseudo } from "@cerebruminc/cerebellum";
import { useAtom } from "jotai";
import { css, styled } from "styled-components";
import { userObjectAtom } from "src/atom";
import { VidLogo } from "src/components";
import { vidOrganizationAtom } from "./../state";
export const HEADER_HEIGHT = 94;
export const NO_ORG_LOGO_BREAKPOINT = 480;
const HeaderBase = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  box-shadow: 0 2px 20px 0 rgba(152, 158, 185, 0.13);
  display: flex;
  height: ${HEADER_HEIGHT}px;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 27px 0 42px;
  flex: 0 0 auto;
  gap: 12px;
  @media (max-width: ${NO_ORG_LOGO_BREAKPOINT}px) {
    gap: 3px;
    padding: 0 5%;
  }
`;
const LogoBox = styled.div`
  height: 34px;
  flex: 0 0 93px;
`;
const UserBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 25px;
  align-items: center;
  overflow: hidden;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.36px;
`;
const OrgLogo = styled.div<{
  $loading?: boolean;
}>`
  border-radius: 50%;
  flex-shrink: 0;
  height: 64px;
  overflow: hidden;
  width: 64px;

  @media (max-width: ${NO_ORG_LOGO_BREAKPOINT}px) {
    display: none;
  }
  ${({
  $loading
}) => $loading && css`
      ${loadingPseudo({
  borderRadius: 32,
  topOffset: 0,
  bgColor: colors.COOL_GREY_4,
  loadingColor: colors.COOL_GREY_15
})}
    `}
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
export const VidHeader = () => {
  const [userData] = useAtom(userObjectAtom);
  const [vidOrganization] = useAtom(vidOrganizationAtom);
  return <HeaderBase>
      <LogoBox>
        <VidLogo />
      </LogoBox>

      <UserBox>
        <Truncate>{userData?.email}</Truncate>

        <OrgLogo $loading={!vidOrganization?.logoUrl}>
          {vidOrganization?.logoUrl && <Img src={vidOrganization.logoUrl} />}
        </OrgLogo>
      </UserBox>
    </HeaderBase>;
};
export default VidHeader;