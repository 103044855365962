import { ConnotationColorEnum, DialogHandler, Form, SnackTypeEnum } from "@cerebruminc/cerebellum";
import { useRef, useState } from "react";
import { getInitialValues } from "src/helpers";
import { useLoginFlow, useWhoAmI, useSettingsFlow } from "src/hooks";
import { CURRENT_PASSWORD_ID, NEW_PASSWORD_ID, changePasswordFields } from "./changePasswordFields";
import type { FormikErrors, FormikHelpers, FormikProps } from "formik";
interface ChangePasswordFormProps {
  closeModal: () => void;
}
interface SpecificFormData {
  [CURRENT_PASSWORD_ID]: string;
  [NEW_PASSWORD_ID]: string;
  password_match: string;
}
export const ChangePasswordForm = ({
  closeModal
}: ChangePasswordFormProps) => {
  const {
    data: userData
  } = useWhoAmI();
  const {
    flow: settingsFlow,
    onSubmit,
    submitting
  } = useSettingsFlow(true);
  const {
    flow: loginFlow,
    onSubmit: loginSubmit
  } = useLoginFlow({
    noRedirect: true,
    forceRefresh: true
  });
  const loginInitialValues = getInitialValues(loginFlow?.ui?.nodes);
  const settingInitialValues = getInitialValues(settingsFlow?.ui?.nodes);

  // Manually managing loading state to give feedback while tryLogin is excecuting
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<any>>(null);
  const submitForReal = async (password: any) => {
    try {
      await onSubmit({
        password: password,
        method: "password",
        csrf_token: settingInitialValues.csrf_token
      });
      setLoading(false);
      DialogHandler.show({
        id: "password-change-success",
        snackType: SnackTypeEnum.Toast,
        text: "Password Updated",
        colorFamily: ConnotationColorEnum.Neutral
      });
      closeModal();
      // Clear form
      formRef.current?.resetForm();
    } catch (error) {
      DialogHandler.show({
        id: "password-change-error",
        snackType: SnackTypeEnum.Toast,
        text: "There was an error changing your password. Please try again!",
        colorFamily: ConnotationColorEnum.Negative
      });
      console.error("Error changing password", error);
      setLoading(false);
    }
  };
  const attemptSubmit = async (values: SpecificFormData, formikHelpers: FormikHelpers<SpecificFormData>) => {
    // NOTE: password match has already been validated by the Form
    setLoading(true);
    try {
      await loginSubmit({
        identifier: userData?.email || "",
        password: values[CURRENT_PASSWORD_ID],
        method: "password",
        csrf_token: loginInitialValues.csrf_token
      });
      await submitForReal(values[NEW_PASSWORD_ID]);
    } catch (error) {
      setLoading(false);
      // alert("Invalid current password - put this in the form");
      formikHelpers.setErrors({
        [CURRENT_PASSWORD_ID]: "Invalid current password"
      });
    }
  };
  return <Form fields={changePasswordFields} enableReinitialize innerRef={formRef} rowGap={24} submit={attemptSubmit} submitButtonAlign="center" submitButtonLoading={loading} submitButtonText="Change Password" themeOverride={{
    submitButtonGap: 46,
    submitButtonHeight: 46,
    titleGap: 35
  }} title="Change Password" />;
};