import { Modal } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { useEffect, useState } from "react";
interface ChangePasswordModalType {
  showModal: boolean;
  closeModal: () => void;
}
const ModalWrap = styled.div`
  position: relative;
  width: 419px;
  @media all and (max-width: 720px) {
    min-width: 390px;
    width: 100%;
  }
  @media all and (max-width: 550px) {
    min-width: inherit;
  }
`;
export const ChangePasswordModal = ({
  showModal,
  closeModal
}: ChangePasswordModalType) => {
  //  We want to mount ChangePasswordForm only once, and only if the modal is opened
  const [renderForm, setRenderForm] = useState(false);
  useEffect(() => {
    if (showModal) {
      setRenderForm(true);
    }
  }, [showModal]);
  return <Modal show={showModal} paddingString="46px 66px 14px" closeFunction={closeModal}>
      <ModalWrap>{renderForm && <ChangePasswordForm closeModal={closeModal} />}</ModalWrap>
    </Modal>;
};