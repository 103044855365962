// Example usage:
// 	const exampleAddress = "123 main street, unit 4b";
// 	console.log(toTitleCase(exampleAddress)); // Outputs: "123 Main Street, Unit 4B"
export function toTitleCase(str?: string): string {
  if (!str) {
    return "";
  }

  return (
    str
      .toLowerCase()
      // Capitalize the first letter of each word
      .replace(/(^|\s)[a-z]/g, (match) => match.toUpperCase())
      // Special handling to maintain the casing of the first alphabetic character immediately following a number
      .replace(
        /(\d+[a-z])([a-z]*)/gi,
        (match, firstPart, rest) => firstPart.charAt(0) + firstPart.slice(1).toUpperCase() + rest.toLowerCase(),
      )
  );
}
