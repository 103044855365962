import type { AxiosError } from "axios";
import { useRouter } from "next/router";
import { type DependencyList, useEffect, useState } from "react";
import { paths } from "src/const";
import ory from "src/helpers/ory/sdk";
import { useGetReturnUrl } from "./useGetReturnUrl";

// Returns a function which will log the user out
export function useLogoutFlow(deps: DependencyList = []) {
  const [logoutToken, setLogoutToken] = useState<string>("");
  const returnUrl = useGetReturnUrl();
  const router = useRouter();

  // biome-ignore lint/correctness/useExhaustiveDependencies: deps are dynamic
  useEffect(() => {
    ory.createBrowserLogoutFlow().then(({
      data
    }) => {
      setLogoutToken(data.logout_token);
    }).catch((err: AxiosError) => {
      switch (err.response?.status) {
        case 401:
          router.push(paths.login);
          // do nothing, the user is not logged in
          return;
      }

      // Something else happened!
      return Promise.reject(err);
    });
  }, deps);
  const logoutFn = async () => {
    if (logoutToken) {
      try {
        await ory.updateLogoutFlow({
          token: logoutToken
        });
        if (returnUrl) {
          window.location.href = returnUrl;
        } else {
          router.push(paths.login);
        }
      } catch (error) {
        router.push(paths.login);
      }
    }
  };
  const regenerateAndLogoutFn = async () => {
    ory.createBrowserLogoutFlow().then(async ({
      data
    }) => {
      await ory.updateLogoutFlow({
        token: data.logout_token
      });
    }).then(() => {
      router.push(paths.login);
    }).catch(error => {
      console.log(error);
      router.push(paths.login);
    });
  };
  return ([logoutToken, logoutFn, regenerateAndLogoutFn] as [string, () => Promise<void>, () => Promise<void>]);
}