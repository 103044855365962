import { AlertCount, ButtonColorFamilyEnum, ConfirmModal, Delete, Edit, type RowType, SearchInput, Table, TableOptionLabel, TextLink, TextStyleEnum, TextVariantEnum, Typography, colors } from "@cerebruminc/cerebellum";
import { type Dispatch, type FC, type SetStateAction, useEffect, useMemo, useState } from "react";
import { NameTransform } from "src/components/TableComponentWrappers";
import { applicantHasId } from "src/views/AddOrderView/AddApplicantsView/types";
import type { ApplicantFormDataWithIdType, StandardFormDataWithIdType } from "src/views/AddOrderView/types";
import { AddedApplicantsBase, AlertBox, ApplicantBox, EmptyGraphicBox, EmptyTable, EmptyText, Header, SearchBox, TableBox, TableWrapper, Title } from "./ApplicantsTableStyles";
import EmptyGraphic from "./EmptyGraphic";
const ADD_APPLICANTS_TABLE_VERTICAL_PADDING = 96;
export const NO_APPLICANTS_TEXT = "No applicants added yet.";
export interface ApplicantsTableProps {
  activeFilters: {
    id: [string];
  }; // Stored in an array for Table, but we only expect one active filter at a time.
  applicants: Array<StandardFormDataWithIdType | ApplicantFormDataWithIdType>;
  applicantsTableHeight?: number;
  deleteAllLabel?: string;
  deleteApplicant: (applicant: StandardFormDataWithIdType | ApplicantFormDataWithIdType) => void;
  deleteAllApplicants: () => void;
  deleteFilteredApplicants: () => void;
  editable?: boolean;
  filterApplicants: Array<StandardFormDataWithIdType | ApplicantFormDataWithIdType>;
  openApplicantEditModal: (row: RowType) => void;
  setActiveFilters: Dispatch<SetStateAction<{
    id: [string];
  }>>;
  title: string;
  uploadFiles: Array<{
    fileName: string;
    original: string;
  }>;
}
export const ApplicantsTable: FC<ApplicantsTableProps> = ({
  activeFilters,
  applicants,
  applicantsTableHeight,
  deleteAllLabel = "Delete",
  deleteApplicant,
  deleteAllApplicants,
  deleteFilteredApplicants,
  editable = true,
  filterApplicants,
  openApplicantEditModal,
  setActiveFilters,
  title,
  uploadFiles
}) => {
  const [deleteAllFlag, setDeleteAllFlag] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState<string | undefined>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchFilterApplicants, setSearchFilterApplicants] = useState(filterApplicants);
  const haveApplicants = !!applicants?.length;
  const handleDeleteAll = () => {
    setModalOpen(true);
    setDeleteAllFlag(true);
  };
  const handleDeleteFilter = () => {
    setModalOpen(true);
    setDeleteAllFlag(false);
    setDeleteFileName(filterApplicants[0].source);
  };
  const applicantsColumns = [{
    cellId: "id",
    ComponentWrapper: NameTransform,
    label: "Name",
    width: 1,
    filters: uploadFiles.length === 0 ? [] : [{
      id: "All",
      label: "All Applicants"
    }, ...uploadFiles.map(item => ({
      id: item.fileName,
      label: item.fileName
    }))],
    radioFilters: true,
    removeFilterPanelClearButton: true
  }];
  const applicantFilteredBySearch = useMemo(() => {
    return filterApplicants.filter(applicant => {
      const fullName = `${applicant.firstName} ${applicant.lastName}`.toLowerCase();
      const searchInputLower = searchInput.toLowerCase();
      return applicant.firstName?.toLowerCase()?.includes(searchInputLower) || applicant.lastName?.toLowerCase()?.includes(searchInputLower) || fullName.includes(searchInputLower);
    });
  }, [searchInput, filterApplicants]);
  useEffect(() => {
    setSearchFilterApplicants(applicantFilteredBySearch);
  }, [applicantFilteredBySearch]);
  const editOption = {
    id: "edit",
    label: <TableOptionLabel Icon={Edit} label="Edit" />
  };
  const tableOptions = [...(editable ? [editOption] : []), {
    id: "delete",
    label: <TableOptionLabel Icon={Delete} label="Delete" />
  }];
  return <AddedApplicantsBase>
      <Header>
        <Title>
          <Typography variant={TextVariantEnum.HeadingH3}>{title}</Typography>
          {!!applicants?.length && <AlertBox>
              <AlertCount colorFamily={ButtonColorFamilyEnum.Purple} count={filterApplicants.length} />
            </AlertBox>}
        </Title>
        {haveApplicants && <TextLink fontSize={12} onClick={activeFilters?.id[0] === "All" ? handleDeleteAll : handleDeleteFilter} text={activeFilters?.id[0] === "All" ? `${deleteAllLabel} All` : `${deleteAllLabel} Filtered`} textColorOverride={colors.BLUE_70} textColorHoverOverride={colors.BLUE_100} textStyle={TextStyleEnum.Emphasis} />}
      </Header>
      <TableBox $heightLimit={applicantsTableHeight ? applicantsTableHeight - ADD_APPLICANTS_TABLE_VERTICAL_PADDING : undefined}>
        {haveApplicants ? <ApplicantBox>
            <SearchBox>
              <SearchInput clearButton={true} expandable={true} onValueChange={value => setSearchInput(value)} value={searchInput} inputHeight={33} />
            </SearchBox>
            <TableWrapper>
              <Table clickRow={row => openApplicantEditModal(row)} clickOption={({
            row,
            option
          }) => {
            if (option.id === "edit") {
              openApplicantEditModal(row);
            } else if (applicantHasId(row)) {
              deleteApplicant(row);
            }
          }} columns={applicantsColumns} activeFilters={activeFilters} filterFunction={({
            columnCellId,
            activeFilterIds
          }) => {
            const clonedFilters = JSON.parse(JSON.stringify(activeFilters));
            clonedFilters[columnCellId] = activeFilterIds;
            setActiveFilters(clonedFilters);
          }} options={tableOptions} rows={searchFilterApplicants} topPadding={0} />
            </TableWrapper>
          </ApplicantBox> : <EmptyTable>
            <EmptyGraphicBox>
              <EmptyGraphic />
            </EmptyGraphicBox>
            <EmptyText>{NO_APPLICANTS_TEXT}</EmptyText>
          </EmptyTable>}
      </TableBox>

      <ConfirmModal header={deleteAllFlag ? "Delete all added applicants?" : "Delete filtered applicants?"} show={modalOpen} onPrimaryClick={() => {
      setModalOpen(false);
      if (deleteAllFlag) {
        deleteAllApplicants();
      } else {
        deleteFilteredApplicants();
      }
    }} onSecondaryClick={() => {
      setModalOpen(false);
    }} onXClick={() => {
      setModalOpen(false);
    }} primaryColorFamily={ButtonColorFamilyEnum.Red} primaryButtonText={deleteAllFlag ? "Yes, delete all." : "Yes, delete filtered."} secondaryButtonText={"No, keep them."} secondaryColorFamily={ButtonColorFamilyEnum.CoolGrey} text={deleteAllFlag ? "You are about to delete all added applicants.  This can not be undone. " : <span>
              You are about to delete all applicants from the file <b>{deleteFileName}</b>. This can not be undone.
            </span>} />
    </AddedApplicantsBase>;
};