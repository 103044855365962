export const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
        // Clean up
        img.onload = null;
        img.onerror = null;
      };
      img.onerror = (error) => {
        reject(error);
        // Clean up
        img.onload = null;
        img.onerror = null;
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
