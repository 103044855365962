import styled from "styled-components";
export const NavBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 30px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;

  > div:only-child {
    margin-left: auto;
  }
`;