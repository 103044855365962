// This email regex is from: https://github.com/cerebruminc/cognition/pull/86#pullrequestreview-1139422735 - thanks @TulioMitrePerdigao!
const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@_"]+\.{1})+([^<>().,;:\s@_"]{2,})|(\[[^<>().,;\s@_"]+\]))$/;

export interface ValidateEmailProps {
  email: string;
  required?: boolean;
}

export type ValidateEmailResult = { email: string; emailError?: string };

export const validateEmail = ({ email, required }: ValidateEmailProps): ValidateEmailResult => {
  let emailError: string | undefined;
  const isInvalid = !emailRegex.test(email);

  if (!email && required) {
    emailError = "Email is required";
  } else if (isInvalid) {
    emailError = "Invalid Email";
  }
  return { email, emailError };
};
