import type { UpdateVerificationFlowBody, VerificationFlow } from "@ory/kratos-client";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { paths } from "src/const";
import { AxiosErrorType, createLocalReturnToWithParams, getInitialValues, handleFlowError, noop } from "src/helpers";
import ory from "src/helpers/ory/sdk";
import { useGetReturnUrl } from "./useGetReturnUrl";

/**
 * this hook initialize and manage verification flow state
 * @returns { loading, flow, submitting, onSubmit, initalValues, csrfToken }
 */
export const useVerificationFlow = () => {
  const router = useRouter();
  const {
    flow: flowId
  } = router.query;
  const returnTo = useGetReturnUrl();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [flow, setFlow] = useState<VerificationFlow>();

  // initialize flow
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    if (!router.isReady || flow) {
      return;
    }

    // initialize verification flow or get existing flow
    const init = async (innerFlowId = "") => {
      if (innerFlowId) {
        try {
          setLoading(true);
          const {
            data
          } = await ory.getVerificationFlow({
            id: String(innerFlowId)
          });
          setFlow(data);
        } catch (error) {
          // if flow returns error then retry
          init().then(noop, noop);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const {
            data
          } = await ory.createBrowserVerificationFlow({
            returnTo: returnTo ? String(returnTo) : undefined
          });
          const redirectUrl = createLocalReturnToWithParams(paths.verification, {
            flow: data?.id,
            return_to: returnTo
          });
          router.push(redirectUrl);
          setFlow(data);
        } catch (error) {
          handleFlowError(router, paths.verification, setFlow)((error as AxiosError<AxiosErrorType>));
        } finally {
          setLoading(false);
        }
      }
    };
    init((flowId as string)).then(noop, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, router.isReady, returnTo]);

  // call this function when form gets submitted
  const onSubmit = useCallback(async (values: UpdateVerificationFlowBody) => {
    try {
      setSubmitting(true);
      // do verification api request
      const newFlowRes = await ory.updateVerificationFlow({
        flow: String(flow?.id),
        updateVerificationFlowBody: values
      });
      setFlow(newFlowRes.data);
      setSubmitting(false);
      return newFlowRes.data;
    } catch (error) {
      setSubmitting(false);
      if (error instanceof AxiosError) {
        handleFlowError(router, paths.verification, setFlow)((error as AxiosError<AxiosErrorType>));
        if (error.response?.status === 400) {
          setFlow(error.response?.data);
          throw error.response?.data;
        }
      }
      throw error;
    }
  }, [flow, router]);
  const initialValues = getInitialValues(flow?.ui?.nodes);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  return useMemo(() => ({
    loading,
    flow,
    submitting,
    onSubmit,
    csrfToken: initialValues?.csrf_token
  }), [loading, flow, submitting, onSubmit]);
};