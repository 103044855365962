import { ConnotationColorEnum, DialogHandler, SnackTypeEnum } from "@cerebruminc/cerebellum";

/**
 * method to show error toast message
 * @param text message string
 */
export const showError = (text: string) => {
  DialogHandler.show({
    id: `success-toast-${new Date().getTime()}`,
    colorFamily: ConnotationColorEnum.Negative,
    text,
    snackType: SnackTypeEnum.Toast,
  });
};
