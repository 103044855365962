import { BoxedButton, ButtonColorFamilyEnum, Form, FormFieldTypeEnum, Modal, PrimaryButton, type SwitchMenuOptionsType, UploadDocument, type SingleOrMultipleFieldType } from "@cerebruminc/cerebellum";
import type { InviteUserDto, UpdateUserDto } from "@cerebruminc/neuron-sdk";
import { useAtomValue } from "jotai";
import React, { type FC, useCallback, useEffect, useRef, useState } from "react";
import { currentOrgIdAtom } from "src/atom";
import { useInviteUserMutation, useUpdateUserMutation, useUserOrgRole, useWhoAmI } from "src/hooks";
import { useGetAllRoles } from "src/hooks/roles";
import { FormBox, Title, TitleBox } from "./InviteOrEditModalStyles";
import { getRoleDetails } from "./helpers";
import type { InviteOrEditModalType } from "./types";
import { FormikProps } from "formik";
import { useGetOrganizationsQuery } from "src/helpers";
interface InviteUser {
  values: InviteUserDto;
  orgId: string;
}
interface UpdateUser {
  values: UpdateUserDto;
  orgId: string;
}
export const InviteOrEditModal: FC<InviteOrEditModalType> = ({
  onButtonClick,
  ButtonIcon = UploadDocument,
  buttonText = "Batch Upload",
  editTitle = "Edit User",
  onSuccess,
  show,
  onError,
  submitText = "Send Invite",
  title = "Invite User",
  user,
  onXClick
}) => {
  const isEditMode = !!user;
  const userId = user?.id;
  const {
    mutateAsync: inviteUserMutation,
    isLoading: inviting
  } = useInviteUserMutation();
  const {
    mutateAsync: updateUserMutation,
    isLoading: updating
  } = useUpdateUserMutation();
  const {
    data: organizations
  } = useGetOrganizationsQuery();
  const {
    data: roles
  } = useGetAllRoles();
  const currentOrgId = useAtomValue(currentOrgIdAtom);
  const [onlyRoleAvailable, setOnlyRoleAvailable] = useState("");
  const [roleMenuOptions, setRoleMenuOptions] = useState<SwitchMenuOptionsType[]>([]);
  const modalRef = useRef(null);

  // Reset the Form when the modal opens
  const formikRef = useRef<FormikProps<any>>(null);
  useEffect(() => {
    if (show) {
      formikRef.current?.resetForm();
    }
  }, [show]);
  const userRole = useUserOrgRole();
  const {
    data: loggedInUser
  } = useWhoAmI();

  // Determine if the user has excluded roles, INTEGRATION_ADMIN and USER roles is excluded
  const hasExcludedRoles = ["USER", "INTEGRATION_ADMIN"].includes(userRole || "");

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    if (roles) {
      const roleOptions = getRoleDetails(roles);
      setRoleMenuOptions(roleOptions);
      if (roleOptions?.length === 1) {
        setOnlyRoleAvailable((roleOptions[0].value as string));
      }
    }
  }, [roles, setRoleMenuOptions]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const inviteUser = useCallback(async ({
    values,
    orgId
  }: InviteUser) => {
    try {
      const role = roles?.find(role => role.name === "USER")?.id;
      await inviteUserMutation({
        email: values.email,
        firstName: values.firstName,
        middleName: values.middleName || "",
        lastName: values.lastName,
        organizationId: orgId,
        // Invited users always start with the USER role
        rolesV2: role ? [role] : []
      });
      onSuccess?.();
    } catch (error: any) {
      onError?.(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }, [inviteUserMutation, currentOrgId, onSuccess, onError]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  const updateUser = useCallback(async ({
    values,
    orgId
  }: UpdateUser) => {
    try {
      await updateUserMutation({
        id: (userId as string),
        firstName: values.firstName,
        middleName: values.middleName || "",
        lastName: values.lastName,
        roles: values.roles,
        organizationId: orgId
      });
      onSuccess?.();
    } catch (error: any) {
      onError?.(error?.message);
    }
  }, [updateUserMutation, currentOrgId, onSuccess, userId, onError, roles]);
  const onSubmit = useCallback((values: any) => {
    const noOrg = !currentOrgId && !values.organizationId;
    if (noOrg) {
      onError?.("Current organization ID is undefined.");
    } else {
      values.roles = roles?.map(role => role.name === values.roles ? {
        name: role.name,
        organizationId: values.organizationId || currentOrgId
      } : undefined).filter(role => role);
      if (isEditMode) {
        const typedValues = ((values as unknown) as UpdateUserDto);
        updateUser({
          values: typedValues,
          orgId: values.organizationId || currentOrgId
        });
      } else {
        const typedValues = ((values as unknown) as InviteUserDto);
        inviteUser({
          values: typedValues,
          orgId: values.organizationId || currentOrgId
        });
      }
    }
  }, [updateUser, inviteUser, isEditMode, roles, currentOrgId, onError]);
  const nameField = {
    type: FormFieldTypeEnum.SplitLine,
    inlineFields: [{
      fieldLabel: "First Name",
      initialValue: user?.firstname || "",
      name: "firstName",
      required: true,
      splitMinWidth: 180,
      type: FormFieldTypeEnum.Text
    }, {
      fieldLabel: "Last Name",
      initialValue: user?.lastname || "",
      name: "lastName",
      required: true,
      splitMinWidth: 180,
      type: FormFieldTypeEnum.Text
    }]
  };
  const roleField = {
    fieldLabel: "Role",
    initialValue: isEditMode ? user?.roleAssignment?.[0]?.roleV2.name ?? "USER" : roleMenuOptions?.length === 1 ? onlyRoleAvailable : "USER",
    name: "roles",
    type: FormFieldTypeEnum.DescriptiveDropdownInput,
    required: true,
    disabled: roleMenuOptions?.length === 1 || user?.id === loggedInUser?.id,
    options: {
      maxMenuHeight: 400,
      menuOptions: roleMenuOptions || []
    }
  };
  const emailField = {
    fieldLabel: "Email",
    name: "email",
    required: true,
    initialValue: user?.email || ""
  };
  const separator = {
    separator: true,
    type: FormFieldTypeEnum.SectionHeader,
    separatorMarginString: "29px 0 0"
  };
  const organizationField = {
    fieldLabel: "Organization",
    name: "organizationId",
    type: FormFieldTypeEnum.Select,
    initialValue: user?.organizations?.[0]?.id || "",
    disabled: organizations?.length === 1,
    required: true,
    options: {
      selectOptions: (organizations || [])?.map(item => ({
        label: item.name,
        value: item.id
      }))
    }
  };
  const fields = ([nameField] as SingleOrMultipleFieldType[]);
  // Only show role field when in edit mode and if there are role options
  // Invited users are always a USER role to start
  if (isEditMode && roleMenuOptions && roleMenuOptions?.length > 0) {
    fields.push(roleField);
  }
  if (!isEditMode) {
    fields.push(emailField);
  }

  // Do not show the organization field if user has USER or INTEGRATION_ADMIN role
  if (!hasExcludedRoles) {
    fields.push(organizationField);
  }
  fields.push(separator);
  return <Modal modalBoxRef={modalRef} show={show} closeFunction={onXClick} paddingString="47px 74px 7px">
      <TitleBox>
        <Title>{isEditMode ? editTitle : title}</Title>
        {onButtonClick && <BoxedButton colorFamily={ButtonColorFamilyEnum.Purple} Icon={ButtonIcon} onClick={onButtonClick} text={buttonText || ""} />}
      </TitleBox>
      <FormBox>
        <Form enableReinitialize fields={fields} innerRef={formikRef} observeScrollRef={modalRef} resetOnSubmit={!isEditMode} rowGap={17} submit={onSubmit} submitButtonAlign="center" submitButtonLoading={inviting || updating} submitButtonText={submitText} themeOverride={{
        submitButtonGap: 37,
        SubmitButton: props => <PrimaryButton {...props} buttonWidth={180} />
      }} />
      </FormBox>
    </Modal>;
};
export default InviteOrEditModal;