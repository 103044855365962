export interface ValidateStringProps {
  string?: string;
  required?: boolean;
  type: string;
}

export interface ValidateStringResult {
  string?: string;
  error?: string;
}

export const validateString = ({ string, required, type }: ValidateStringProps): ValidateStringResult => {
  let error: string | undefined;

  if (required && !string) {
    error = `${type} is required`;
  }

  return { string, error };
};
