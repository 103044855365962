import { useSetAtom } from "jotai";
import { currentOrgIdAtom } from "src/atom";
import { useUpdateSelectedOrganizationId, useWhoAmI } from "./users";

export const useSwitchToAllOrgs = () => {
  const { mutateAsync: updateSelectedOrganizationId } = useUpdateSelectedOrganizationId();
  const { data: userData } = useWhoAmI();
  const setCurrentOrgId = useSetAtom(currentOrgIdAtom);

  const switchToAllOrgs = async () => {
    try {
      await updateSelectedOrganizationId({
        id: userData?.id || "",
        selectedOrganizationId: null,
      });
      setCurrentOrgId(null);
    } catch (e) {
      console.log(e);
    }
  };

  return switchToAllOrgs;
};
