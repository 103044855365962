import {
  CreateOrganizationDto,
  FindAllOrganizationsDto,
  FindOneOrganizationDto,
  Organization,
  UpdateOrganizationDto,
} from "@cerebruminc/neuron-sdk";
import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";

export interface OrganizationWithIncludedData extends Organization {
  subOrganizations?: Organization[];
}

export const useGetOrganizationsQuery = (
  data: FindAllOrganizationsDto = {
    orderBy: { createdAt: "desc" },
    include: {
      logo: true,
    },
  },
) => {
  return useQuery({
    queryKey: ["organizations"],
    async queryFn() {
      const res = await neuronSdk.findAllOrganizations(data);
      return res.data as OrganizationWithIncludedData[];
    },
  });
};

export const useGetOrganizationQuery = (
  id: string,
  data: FindOneOrganizationDto = {},
  options?: Omit<UseQueryOptions<Organization, unknown, Organization, string[]>, "queryKey" | "queryFn"> | undefined,
) => {
  return useQuery({
    queryKey: ["organization", id],
    async queryFn() {
      const res = await neuronSdk.findOneOrganization(id, {
        include: {
          affiliateOrgTo: true,
          primaryOrgFor: true,
          address: true,
          logo: true,
        },
        ...data,
      });
      return res.data;
    },
    ...options,
  });
};

export const useCreateOrganizationQuery = () => {
  return useMutation({
    async mutationFn(data: CreateOrganizationDto) {
      const res = await neuronSdk.createOrganization(data);
      return res.data;
    },
  });
};

export const useUpdateOrganizationQuery = () => {
  return useMutation({
    async mutationFn(data: UpdateOrganizationDto & { id: string }) {
      const { id, ...rest } = data;
      const res = await neuronSdk.updateOrganization(id, rest);
      return res.data;
    },
  });
};

export const useDeleteOrganizationMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const res = await neuronSdk.deleteOrganization(id);
      return res.data;
    },
  });
};

export const useGetSubOrganizationsQuery = (id: string) => {
  return useQuery({
    queryKey: ["sub-organizations", id],
    async queryFn() {
      const res = await neuronSdk.getSubOrganizations(id);
      return res.data as OrganizationWithIncludedData[];
    },
  });
};
