const NoCamera = () => <svg width="118px" height="118px" viewBox="0 0 118 118" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>No Camera</title>
    <defs>
      <polygon id="path-1" points="-1.90400665e-15 0 116.995713 0 116.995713 117 -1.90400665e-15 117" />
    </defs>
    <g id="3-Selfie" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Take-Selfie-No-Camera" transform="translate(-661, -310)">
        <g id="no-camera" transform="translate(661.8329, 310.75)">
          <g id="Group-3" transform="translate(0.0026, 0)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path d="M9.07651435,1.55634138 C7.0013925,-0.518780461 3.63146323,-0.518780461 1.55634138,1.55634138 C-0.518780461,3.63146323 -0.518780461,7.0013925 1.55634138,9.07651435 L107.919198,115.439371 C109.99432,117.523068 113.364249,117.523068 115.439371,115.439371 C117.514493,113.364249 117.514493,109.99432 115.439371,107.919198 L9.07651435,1.55634138 Z" id="Fill-1" fill="#D1004B" mask="url(#mask-2)" />
          </g>
          <path d="M108.837568,31.9079996 C107.22549,31.9079996 105.647712,32.388193 104.31003,33.2799809 L90.4101433,42.5408553 L90.4101433,31.9079996 C90.4101433,23.0930192 83.2672652,15.9501411 74.4522848,15.9501411 L44.9375353,15.9501411 C42.5708674,15.9501411 41.3789586,18.8141522 43.0596358,20.4948294 L90.4101433,67.8453369 L106.642398,84.0775917 C107.234065,84.6692587 107.99723,85.089428 108.837568,85.089428 C113.347957,85.089428 117.000857,81.4365275 117.000857,76.9261387 L117.000857,40.0712888 C117.000857,35.5609 113.347957,31.9079996 108.837568,31.9079996" id="Fill-4" fillOpacity="0.238363199" fill="#FFFFFF" />
          <path d="M0,31.9079996 L0,85.089428 C0,93.9044084 7.14287808,101.047286 15.9578585,101.047286 L74.4557148,101.047286 C79.6520943,101.047286 84.2739565,98.5605702 87.189417,94.7104474 L9.70676829,17.2277987 C3.99589578,19.6544908 0,25.313914 0,31.9079996" id="Fill-6" fillOpacity="0.238363199" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </svg>;
export default NoCamera;