import { OrderDeliveryMethod, OrderScoreEnum, OrderStatusEnum } from "src/generated/graphql";
import { adjudicationOptions } from "./adjudicationOptions";

export interface mockedOrderData {
  date: number;
  id: string;
  fullName: string;
  package: string;
  type: string;
  score?: string;
  status: string;
  alert: boolean;
}

export interface mockedOrdersViewData {
  orders: Array<mockedOrderData>;
}

export const ordersViewData: mockedOrdersViewData = {
  orders: [
    {
      date: 1660237474,
      id: "1234567",
      fullName: "Tom Hanks",
      package: "New Coach",
      type: "Standard",
      score: undefined,
      status: OrderStatusEnum.Pending,
      alert: true,
    },
    {
      date: 1660100000,
      id: "2345678",
      fullName: "James Vogel",
      package: "Employee Screen",
      type: "vID",
      score: OrderScoreEnum.PreAdverse,
      status: OrderStatusEnum.Pending,
      alert: false,
    },
    {
      date: 1651539100,
      id: "3456789",
      fullName: "Lola Vincent",
      package: "Employee Screen",
      type: "vID",
      score: undefined,
      status: OrderStatusEnum.Pending,
      alert: false,
    },
    {
      date: 1617324700,
      id: "4567890",
      fullName: "Patrick McDonald",
      package: "LC COVID-19 Serology",
      type: "Rapid",
      score: undefined,
      status: OrderStatusEnum.Pending,
      alert: false,
    },
    {
      date: 1583113900,
      id: "5678901",
      fullName: "Michelle Williamson",
      package: "Employee Screen",
      type: "Rapid",
      score: OrderScoreEnum.NoAlerts,
      status: OrderStatusEnum.Complete,
      alert: false,
    },
  ],
};

export const TODAY = "Today";
export const LAST_30_DAYS = "Last 30 days";
export const THIS_YEAR = "This year";
export const CUSTOM_RANGE = "Custom Range";

export const dateFiltersIds = [TODAY, LAST_30_DAYS, THIS_YEAR, CUSTOM_RANGE];

// ------- Filters ------
const dateFilters = [
  { id: TODAY, label: TODAY, value: TODAY },
  { id: LAST_30_DAYS, label: LAST_30_DAYS, value: LAST_30_DAYS },
  { id: THIS_YEAR, label: THIS_YEAR, value: THIS_YEAR },
  { id: CUSTOM_RANGE, label: CUSTOM_RANGE, value: CUSTOM_RANGE },
];

const packageFilters: never[] = [];

const statusFilters = [
  // {
  //   id: "status_1",
  //   label: "In Review",
  //   value: OrderStatusEnum.In_Review,
  // },
  {
    id: OrderStatusEnum.Pending,
    label: "Pending",
    value: OrderStatusEnum.Pending,
  },
  {
    id: OrderStatusEnum.Complete,
    label: "Complete",
    value: OrderStatusEnum.Complete,
  },
  // {
  //   id: "status_4",
  //   label: "Canceled",
  //   value: OrderStatusEnum.Canceled,
  // },
  // {
  //   id: "status_5",
  //   label: "Disputed",
  //   value: OrderStatusEnum.Disputed,
  // },
];

const typeFilters = [
  { id: OrderDeliveryMethod.VidScreen, label: "vID", value: OrderDeliveryMethod.VidScreen },
  { id: OrderDeliveryMethod.RapidRequest, label: "Rapid", value: OrderDeliveryMethod.RapidRequest },
  { id: OrderDeliveryMethod.Standard, label: "Standard", value: OrderDeliveryMethod.Standard },
];

export const mockOrdersViewFilters = {
  range: dateFilters,
  package: packageFilters,
  score: adjudicationOptions,
  status: statusFilters,
  type: typeFilters,
};
