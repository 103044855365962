import { HeadingH3, TextButton } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { windowSizeAtom } from "./atom";
import { mobileMenuNavAndPadding, modalHeight, smallModalHeight } from "./FiltersModal";
import { Header, HeaderButton, TabContent, TabForm } from "./FiltersModalStyles";
import { getTabContentHeight } from "./helpers";
import { useClearTabFilters } from "./hooks/useClearTabFilters";
import React from "react";
import { PrimaryButton } from "@cerebruminc/cerebellum";
import { ButtonBox, ButtonGroup } from "./FiltersModalStyles";
import { useApplyModalFilters } from "./hooks/useApplyModalFilters";
import { useApplyButtonText } from "./hooks/useApplyButtonText";
import { AllFiltersType } from "./types";
type TabFormContainerProps = {
  children: React.ReactNode;
  filterFormAction?: () => void;
  noActiveFilters: boolean;
  radioType?: boolean;
  tabId: string;
  title: string;
  allFilters: AllFiltersType;
};
export function TabFormContainer({
  children,
  filterFormAction,
  noActiveFilters,
  tabId,
  title,
  allFilters
}: TabFormContainerProps) {
  const {
    height,
    width
  } = useAtomValue(windowSizeAtom);
  const applyButtonText = useApplyButtonText({
    allFilters
  });
  const applyModalFilters = useApplyModalFilters({
    allFilters
  });
  const handleOnClick = () => {
    if (filterFormAction) {
      filterFormAction();
    } else {
      applyModalFilters();
    }
  };
  const clearTabFilters = useClearTabFilters();
  const horizontalMenu = width <= 600;
  return <>
      <TabForm $horizontalMenu={horizontalMenu} $menuHeight={horizontalMenu ? `calc(100vh - ${mobileMenuNavAndPadding + 75}px)` : `${height > 620 ? modalHeight - 10 : smallModalHeight - 10}px`} $minHeight={290}>
        <Header>
          <HeadingH3>{title}</HeadingH3>
          <HeaderButton>
            <TextButton disabled={noActiveFilters} onClick={() => {
            clearTabFilters({
              tabId: tabId
            });
          }} text="Clear All" />
          </HeaderButton>
        </Header>
        <TabContent $menuHeight={horizontalMenu ? `calc(100vh - ${mobileMenuNavAndPadding + 115}px)` : `${getTabContentHeight(height)}px`}>
          {children}
        </TabContent>
      </TabForm>
      <ButtonGroup $horizontalMenu={horizontalMenu} $outsideSleeve={true}>
        <ButtonBox>
          <PrimaryButton buttonHeight={45} buttonWidth={139} onClick={handleOnClick} shadow={true} text={applyButtonText} />
        </ButtonBox>
      </ButtonGroup>
    </>;
}