import { getNeuronSdk } from "./getNeuronSdk";

/**
 * this method will be used directly as a getServerSideProps.
 * this is used to check whether user is logged in or not
 * if not then redirect user to login page
 * @param context next js request context
 * @returns
 * @example
 * ```ts
 *  export async function getServerSideProps(ctx) {
      return protectRouteHelper(ctx);
    }
 * ```
 */
export async function protectRouteHelper(context: any) {
  try {
    const apiKey = context.req.headers["x-api-key"];
    const sdk = getNeuronSdk(undefined, context.req.headers.cookie, apiKey);
    await sdk.getSession();
    return {
      props: {},
    };
  } catch (error) {
    return {
      redirect: {
        destination: "/login",
        permanent: false,
      },
    };
  }
}
