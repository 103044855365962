import styled from "styled-components";
import type { MonospaceProps } from "./types";
export const Monospace = styled.span<MonospaceProps>`
  letter-spacing: 0.1em;
  font-family: "Cousine", monospace;
  text-decoration: ${({
  $underline
}) => $underline ? "underline" : "none"};
  text-decoration-style: dotted;
`;
export const NarrowMono = styled.span<MonospaceProps>`
  display: inline-block;
  letter-spacing: 0.02em;
  margin-right: 1px;
  text-decoration: ${({
  $underline
}) => $underline ? "underline" : "none"};
  text-decoration-style: dotted;
`;