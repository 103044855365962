import { useQuery } from "@tanstack/react-query";
import { neuronSdk } from "../helpers/neuron";

/**
 * hook to fetch all roles
 * @returns list of all roles
 */
export const useGetAllRoles = () => {
  return useQuery({
    queryKey: ["all-roles"],
    async queryFn() {
      const res = await neuronSdk.getAllRoles();
      return res.data;
    },
  });
};
