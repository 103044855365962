import { colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import type { CenteredMaxProps, TabsBoxProps } from "./types";
export const CARD_WIDTH = 309;
export const CENTER_MAX_WIDTH = 1600;
export const PAGE_GUTTER_CENTER = 54;
export const PAGE_GUTTER_LEFT = 83;
export const PAGE_GUTTER_RIGHT = 50;
export const CenteredMax = styled.div<CenteredMaxProps>`
  align-items: flex-start;
  display: ${({
  $stackedLayout
}) => $stackedLayout ? "block" : "grid"};
  grid-template-columns: [left-gutter] ${PAGE_GUTTER_LEFT}px [card-start] ${CARD_WIDTH}px [card-end] ${PAGE_GUTTER_CENTER}px [tabs-start] auto [tabs-end] ${PAGE_GUTTER_RIGHT}px [right-gutter];
  max-width: ${CENTER_MAX_WIDTH}px;
  margin: ${({
  $marginTop,
  $stackedLayout
}) => $stackedLayout ? "0 auto" : `${typeof $marginTop !== "undefined" ? $marginTop : 17}px auto 0`};
  @media print {
    display: block;
    margin: 0 auto;
  }
`;
export const CardBox = styled.div`
  grid-column-start: card-start;
  grid-column-end: card-end;
  position: sticky;
  top: 110px;
  width: 309px;
  z-index: 2;
`;
export const GreyPageWrap = styled.section`
  background: ${colors.COOL_GREY_4};
`;
export const RightSide = styled.div`
  grid-column-start: tabs-start;
  grid-column-end: tabs-end;
  grid-row-start: auto;
  grid-row-end: auto;
`;
export const TabsBox = styled.div<TabsBoxProps>`
  margin: ${({
  $stackedLayout
}) => $stackedLayout ? "0 4%" : 0};
  position: relative;
  z-index: 1;
  @media print {
    margin: 0 4%;
  }
`;