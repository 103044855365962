import { useAtom, useSetAtom } from "jotai";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { authCheckAtom, currentOrgIdAtom, userObjectAtom } from "src/atom";
import { WholePageLoader } from "./Spinner";
import ory from "src/helpers/ory/sdk";
import { createLocalReturnToWithParams, getNeuronLoginUrl, neuronSdk } from "src/helpers";
import { paths } from "src/const";
const HOC = (Page: NextPage) => {
  const Wrapper = () => {
    const router = useRouter();
    const [authCheckComplete, setAuthCheckComplete] = useAtom(authCheckAtom);
    const setUser = useSetAtom(userObjectAtom);
    const setCurrentOrgId = useSetAtom(currentOrgIdAtom);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (!router.isReady) return;
      const run = async () => {
        if (!authCheckComplete) {
          try {
            const {
              data: user
            } = await neuronSdk.whoAmI();
            const {
              data: session
            } = await ory.toSession();
            if (!user) {
              throw new Error("User is not logged in");
            }

            // check whether primary email is verified
            const emailObject = session?.identity?.verifiable_addresses?.find(item => item.via === "email");
            const email = emailObject?.value;
            const isEmailVerified = emailObject?.verified;
            if (!isEmailVerified) {
              // if email is not verified then force redirect user to verification page
              const returnToUrl = createLocalReturnToWithParams(router.asPath);
              // redirect to verification page with return to url as current page
              router.push({
                pathname: paths.verification,
                query: {
                  return_to: returnToUrl,
                  email
                }
              });
              return;
            }
            setUser(user);
            setAuthCheckComplete(true);

            // If the user has only one organization, set it as the current organization
            if (user.organizations?.length === 1) {
              setCurrentOrgId(user.organizations[0].id);
            } else {
              setCurrentOrgId(user.selectedOrganizationId);
            }
          } catch (error) {
            setUser(null);
            setCurrentOrgId(null);
            const currentUrl = router.asPath;
            // Does this still cause a redirect issue between products and mfa? add refresh=true in getNeuronLoginUrl if so
            // https://github.com/cerebruminc/neuron-web/pull/395/files?diff=split&w=1
            router.push(`${paths.login}${getNeuronLoginUrl(currentUrl)}`);
          }
        }
      };
      run();
    }, [authCheckComplete, router]);
    if (!authCheckComplete) {
      return <WholePageLoader />;
    }
    return <Page />;
  };
  return Wrapper;
};
export default HOC;