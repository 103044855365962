import { useRouter } from "next/router";
import { useEffect } from "react";
import { validateReturnToUrl } from "src/helpers";
import { useReturnToStore } from "src/helpers/store";
export const useGetReturnUrl = () => {
  const router = useRouter();
  const {
    return_to: returnTo
  } = router.query;
  const {
    returnTo: returnToFromStore,
    setReturnTo
  } = useReturnToStore();

  /**
   * get return to url from window location and store it to return to store
   */
  useEffect(() => {
    const searchQuery = window?.location?.search;
    const params = new URLSearchParams(searchQuery);
    const returnToUrl = params.get("return_to");
    if (returnToUrl) {
      setReturnTo(returnToUrl);
    }
  }, [setReturnTo]);
  let finalReturnTo = "";
  if (returnTo && validateReturnToUrl((returnTo as string))) {
    finalReturnTo = (returnTo as string);
  } else if (returnToFromStore) {
    finalReturnTo = returnToFromStore;
  }

  // give priority to url return to, if it doesn't found get it from store
  return (finalReturnTo as string);
};