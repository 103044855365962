import { BodySPrimary, BodyXXSSecondary, type ComponentWrapperType, colors } from "@cerebruminc/cerebellum";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import type { FC } from "react";
import { styled } from "styled-components";
dayjs.extend(duration);
const timeSinceThen = (date: string) => {
  const then = dayjs(date);
  const now = dayjs();
  const daysAgo = dayjs.duration(now.diff(then)).asDays();
  if (daysAgo > 2) {
    const flatDays = Math.floor(daysAgo);
    return `${flatDays} days ago`;
  }
  if (daysAgo > 1) {
    return "Yesterday";
  }
  return "Today";
};
const SubText = styled(BodyXXSSecondary)`
  display: block;
`;
export const LegibleDate: FC<ComponentWrapperType> = ({
  row
}) => {
  const orderDate = row?.updatedAt ?? row?.createdAt;
  const humanDate = dayjs(orderDate).format("MM/DD/YYYY");
  const errorMode = !!row?.error;
  return <p>
      <BodySPrimary as="span">{humanDate}</BodySPrimary>
      <SubText as="span" $textColor={errorMode ? colors.RED_100 : colors.COOL_GREY_65}>
        {errorMode ? "Could not send." : timeSinceThen(humanDate)}
      </SubText>
    </p>;
};