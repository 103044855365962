import { BoxedButton, Email, Gear, Key, LogoSidebar, Reporting, User } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import router from "next/router";
import { useEffect, useState } from "react";
import { currentOrgIdAtom, userObjectAtom } from "src/atom";
import { NavBase, UserControls } from "src/components";
import { VoucherStatusEnum, useGetInviteCountQuery } from "src/generated/graphql";
import { ChildrenContainer, ContentContainer, MainPageContainer, Sidebar } from "./PageLayoutStyles";
import type { PageLayoutProps } from "./types";
import { paths } from "src/const";
import { isBrowser } from "src/helpers";
import { useGetOrganizationQuery, useUserOrgRole } from "src/hooks";
export function PageLayout({
  children,
  padding
}: PageLayoutProps) {
  const {
    cogInvites
  } = useFlags();
  const [activeLink, setActiveLink] = useState("");
  const currentUser = useAtomValue(userObjectAtom);
  const organizationId = useAtomValue(currentOrgIdAtom);
  const pathname = isBrowser ? router?.pathname : "";
  const {
    data: orgData
  } = useGetOrganizationQuery((organizationId as string), {}, {
    enabled: !!organizationId
  });
  const {
    data
  } = useGetInviteCountQuery({
    where: {
      AND: [{
        status: {
          equals: VoucherStatusEnum.Pending
        },
        organizationId: organizationId ? {
          equals: organizationId
        } : undefined
      }]
    }
  });
  const links = [...(cogInvites ? [{
    alert: data?.aggregateVIDVoucher._count?._all || 0,
    Icon: Email,
    id: paths.invites,
    linkClick: () => router.push(paths.invites),
    label: "Invites"
  }] : []), {
    Icon: User,
    iconSize: 14,
    id: paths.users,
    linkClick: () => router.push(paths.users),
    label: "Users"
  }];
  const role = useUserOrgRole();
  const isUserRole = !role || role === "USER";
  if (!isUserRole) {
    links.push({
      Icon: Key,
      iconSize: 16,
      id: paths.apiKeys,
      linkClick: () => router.push(paths.apiKeys),
      label: "API Keys"
    });
    links.push({
      Icon: Reporting,
      iconSize: 14,
      id: paths.auditLog,
      linkClick: () => router.push(paths.auditLog),
      label: "Audit Log"
    });
    // You can only access the account page if you have an org selected
    if (organizationId !== null) {
      links.push({
        Icon: Gear,
        iconSize: 16,
        id: `${paths.organizations}/[id]`,
        linkClick: () => router.push(`${paths.organizations}/${organizationId}`),
        label: "Account"
      });
    }
  }
  useEffect(() => {
    setActiveLink(pathname);
  }, [pathname]);

  // Default to the vID logo if the user is not in an org
  const logoUrl = orgData ? orgData.logoUrl : "/static/img/logo-vid.svg";
  return <MainPageContainer>
      <Sidebar>
        <LogoSidebar activeLink={activeLink} links={links} logo={logoUrl} noLogoLetter={currentUser?.organizations?.[0]?.legalName} squareAvatar />
      </Sidebar>
      <ContentContainer>
        <NavBase>
          <UserControls white />
        </NavBase>

        <ChildrenContainer $addPadding={padding}>{children}</ChildrenContainer>
      </ContentContainer>
    </MainPageContainer>;
}