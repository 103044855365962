import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import { useEffect } from "react";
import {
  passTypeBeamsDataAtom,
  completedVidStepsAtom,
  currentAddressAtom,
  extractedPhotoAtom,
  hideSensitiveDataAtom,
  idBackAtom,
  idFrontAtom,
  vidPersonalInformationAtom,
  vidScanResultsAtom,
  selfieAtom,
  vidSSNAtom,
  vidStepsAtom,
  vidVoucherAtom,
  clearIndexedDB,
} from "./../../state";

export const useClearVidData = () => {
  // this hook is not cleaning the data
  const setBeams = useSetAtom(passTypeBeamsDataAtom);
  const setCompletedVidSteps = useSetAtom(completedVidStepsAtom);
  const setCurrentAddress = useSetAtom(currentAddressAtom);
  const setExtractedPhoto = useSetAtom(extractedPhotoAtom);
  const setHideSensitiveData = useSetAtom(hideSensitiveDataAtom);
  const setIdBack = useSetAtom(idBackAtom);
  const setIdFront = useSetAtom(idFrontAtom);
  const setPersonalInfo = useSetAtom(vidPersonalInformationAtom);
  const setScanResults = useSetAtom(vidScanResultsAtom);
  const setSelfie = useSetAtom(selfieAtom);
  const setSSN = useSetAtom(vidSSNAtom);
  const setVidSteps = useSetAtom(vidStepsAtom);
  const setVIDVoucher = useSetAtom(vidVoucherAtom);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // calling clearIndexedDB() first, as it is faster than clearing individual
    // entries, we still need to reset the atoms as they still hold the values
    // in memory
    clearIndexedDB();
    setBeams(RESET);
    setCompletedVidSteps(RESET);
    setCurrentAddress(undefined);
    setExtractedPhoto(RESET);
    setIdBack(RESET);
    setIdFront(RESET);
    setPersonalInfo(RESET);
    setScanResults(RESET);
    setSelfie(RESET);
    setSSN("");
    setVidSteps(RESET);
    setVIDVoucher(RESET);
    setHideSensitiveData(true);
    localStorage.clear();
  }, []);

  return null;
};
