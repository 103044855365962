import { addOrRemoveString, getObjectById } from "@cerebruminc/cerebellum";

import { getValuesFromIds } from "src/views/filterHelpers";
import { modalHeight, smallModalHeight } from "./FiltersModal";
import type { GetIdsProps, RevertFilterModalFiltersProps, UpdateFilterStateProps } from "./types";
import { deepCloneObject } from "src/helpers";
import { GenericFilterModalFiltersType } from "./types";

export const getIdsFromValues = ({ filterValues, filters }: GetIdsProps) => {
  const activeLabels = [];

  for (let i = 0; i < filterValues.length; i++) {
    const value = filterValues[i];
    const activeObj = getObjectById({
      array: filters,
      id: value,
      idKey: "value",
    });
    if (activeObj) {
      activeLabels.push(activeObj.id);
    }
  }
  return activeLabels;
};

export const revertFilterModalFilters = ({ availableFilters, tableFilters, tabs }: RevertFilterModalFiltersProps) => {
  const newModalFilters: GenericFilterModalFiltersType = {};

  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    const isRadio = tab.radioType;
    const valueArray = getValuesFromIds({
      filterIds: tableFilters[tab.id] || [],
      filters: availableFilters[tab.id],
    });
    newModalFilters[tab.id] = isRadio ? valueArray[0] : valueArray;
  }

  return newModalFilters;
};

export const updateFilterModalFilters = ({
  filterModalFilters,
  filterIdArray,
  newValue,
  setFilterModalFilters,
  tabId,
}: UpdateFilterStateProps) => {
  const newActiveValues = filterIdArray
    ? addOrRemoveString({
        array: filterIdArray,
        string: newValue,
      })
    : newValue;

  const clonedFilterModalFilters = deepCloneObject(filterModalFilters) || {};
  clonedFilterModalFilters[tabId] = newActiveValues;
  if (newActiveValues.length === 0) {
    delete clonedFilterModalFilters[tabId];
  }
  setFilterModalFilters(clonedFilterModalFilters);
};

export const getModalHeightMode = (height: number) => {
  return height > 620 ? modalHeight : smallModalHeight;
};

export const getTabContentHeight = (height: number) => {
  return modalHeight - (height > 620 ? 212 : 316);
};
