interface LookupProps {
  [key: string]: string;
}

export const suffixLookup: LookupProps = {
  jr: "Jr",
  sr: "Sr",
  i: "I",
  ii: "II",
  iii: "III",
  iv: "IV",
  v: "V",
};
