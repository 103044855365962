import { BoxedButton } from "@cerebruminc/cerebellum";
import router from "next/router";
import type { ReactNode } from "react";
import { NavBase, UserControls } from "src/components";
import { ChildrenContainer, MainPageContainer } from "src/components/Layouts/PageLayout/PageLayoutStyles";
interface FullPageLayoutProps {
  backButton?: boolean;
  backButtonText?: string;
  backRoute?: string;
  onBackButtonClick?: () => void;
  children: ReactNode;
}
export function FullPageLayout({
  backButton,
  backButtonText = "Back",
  backRoute = "/",
  children,
  onBackButtonClick
}: FullPageLayoutProps) {
  return <MainPageContainer>
      <NavBase>
        {backButton && <BoxedButton text={backButtonText} onClick={() => onBackButtonClick ? onBackButtonClick() : router.push(backRoute)} backButton />}
        <UserControls white />
      </NavBase>
      <ChildrenContainer>{children}</ChildrenContainer>
    </MainPageContainer>;
}