import type { ActiveFiltersType } from "@cerebruminc/cerebellum";
import { useAtomValue, useSetAtom } from "jotai";
import { formatFilters } from "src/views/filterHelpers";
import { FilterTabIdsEnum } from "../../../views/OrdersView/types";
import {
  activeFiltersAtom,
  activeTableFiltersAtom,
  availableFiltersAtom,
  defaultToDateTabAtom,
  draftFromDateAtom,
  draftToDateAtom,
  filterModalCustomFromAtom,
  filterModalCustomToAtom,
  filterModalFiltersAtom,
  filtersModalOpenAtom,
  orderTableCurrentPageAtom,
} from "../atom";
import { getIdsFromValues } from "../helpers";
import { AllFiltersType } from "../types";

export const useApplyModalFilters = ({ allFilters }: { allFilters: AllFiltersType }) => {
  const setFilters = useSetAtom(activeFiltersAtom);
  const availableFilters = useAtomValue(availableFiltersAtom);
  const setDefaultToDateTab = useSetAtom(defaultToDateTabAtom);
  const setCustomFrom = useSetAtom(filterModalCustomFromAtom);
  const setCustomTo = useSetAtom(filterModalCustomToAtom);
  const draftFrom = useAtomValue(draftFromDateAtom);
  const draftTo = useAtomValue(draftToDateAtom);
  const filterModalFilters = useAtomValue(filterModalFiltersAtom);
  const setFiltersModalOpen = useSetAtom(filtersModalOpenAtom);
  const setTableFilters = useSetAtom(activeTableFiltersAtom);
  const setCurrentPage = useSetAtom(orderTableCurrentPageAtom);

  return () => {
    setCurrentPage(0);
    setFiltersModalOpen(false);
    setDefaultToDateTab(false);
    const tabIds = Object.values(allFilters);
    const newFilters: ActiveFiltersType = {};

    for (let i = 0; i < tabIds.length; i++) {
      const id = tabIds[i];
      const filterIdArray = filterModalFilters?.[id] || [];
      const activeIds = getIdsFromValues({
        filterValues: typeof filterIdArray === "string" ? [filterIdArray] : filterIdArray,
        filters: availableFilters[id],
      });
      if (typeof filterIdArray === "string") {
        newFilters[id] = activeIds[0];
      } else {
        newFilters[id] = activeIds;
      }
      if (newFilters[id].length === 0) {
        delete newFilters[id];
      }
    }

    const formattedFilters = formatFilters({
      availableFilters,
      filtersObject: newFilters,
      fromDate: draftFrom || undefined,
      toDate: draftTo || undefined,
    });

    setFilters(formattedFilters); // [filterObjects]
    setTableFilters(newFilters); // { [id]: ["ids"] || "id"}
    setCustomFrom(draftFrom);
    setCustomTo(draftTo);
  };
};
