function extractFileNameFromBase64(base64String: string) {
  const regex = /name=([^;]+)/;
  const match = base64String.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}

export function base64ToFile(base64String: string) {
  const fileName = extractFileNameFromBase64(base64String) as string;
  const base64Content = base64String.split(";base64,").pop() || "";
  const byteCharacters = atob(base64Content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], fileName, { type: "image/png" });
}
