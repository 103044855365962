import { useAtom, useSetAtom } from "jotai";
import {
  activeFiltersAtom,
  activeTableFiltersAtom,
  filterModalCustomFromAtom,
  filterModalCustomToAtom,
  filterModalFiltersAtom,
  orderTableCurrentPageAtom,
  searchTagsAtom,
  showClearAllToastAtom,
  stashedFiltersAtom,
  stashedSearchTagsAtom,
  tempFilterModalCustomFromAtom,
  tempFilterModalCustomToAtom,
} from "../atom";

export const useClearAllFilters = () => {
  const setActiveFilters = useSetAtom(activeFiltersAtom);
  const [tableFilters, setTableFilters] = useAtom(activeTableFiltersAtom);
  const [from, setFilterModalCustomFrom] = useAtom(filterModalCustomFromAtom);
  const setTempFilterModalCustomFrom = useSetAtom(tempFilterModalCustomFromAtom);
  const [to, setFilterModalCustomTo] = useAtom(filterModalCustomToAtom);
  const setTempFilterModalCustomTo = useSetAtom(tempFilterModalCustomToAtom);
  const setFilterModalFilters = useSetAtom(filterModalFiltersAtom);
  const setCurrentPage = useSetAtom(orderTableCurrentPageAtom);
  const [searchTags, setSearchTags] = useAtom(searchTagsAtom);
  const setShowClearAllToast = useSetAtom(showClearAllToastAtom);
  const setStashedFilters = useSetAtom(stashedFiltersAtom);
  const setStashedSearchTags = useSetAtom(stashedSearchTagsAtom);

  function clearAllFilters() {
    setActiveFilters([]);
    setCurrentPage(0);
    setFilterModalFilters({});
    setSearchTags([]);
    setStashedSearchTags(searchTags);
    setStashedFilters(tableFilters);
    setShowClearAllToast(true);
    setTableFilters({});
    setTempFilterModalCustomFrom(from);
    setTempFilterModalCustomTo(to);
    setFilterModalCustomFrom(undefined);
    setFilterModalCustomTo(undefined);
  }

  return clearAllFilters;
};
