import type { SingleOrMultipleFieldType } from "@cerebruminc/cerebellum";
import type { Address as ApiAddress } from "src/generated/graphql";

export type KeyStringType = {
  [key: string]: string;
};

export type Alias = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
};

export interface Address extends Omit<ApiAddress, "createdAt" | "updatedAt" | "userId" | "id"> {
  useAsCurrent?: any;
}

export type AdditionalPersonalInformation = {
  aliases: Alias[];
  addresses: Address[];
};

export interface AdditionalFieldsType {
  [key: string]: SingleOrMultipleFieldType[];
}
export enum DocumentTypeEnum {
  PASSPORT = "PASSPORT",
  PASSPORT_CARD = "PASSPORT_CARD",
  DRIVERS_LICENSE = "DRIVERS_LICENSE",
  IDENTITY_CARD = "IDENTITY_CARD",
  STATE_ID = "STATE_ID",
  OTHER = "OTHER",
  UNKNOWN = "UNKNOWN",
}

export type ScanResult = {
  documentId?: string;
  documentType?: DocumentTypeEnum;
  gender: string;
  number?: string;
  passportNumber?: string;
  placeOfBirth?: string;
  nationality?: string;
  dateOfBirth: string;
  issueDate: string;
  expiryDate: string;
  name: {
    firstName: string;
    middleName?: string; // middleName can be undefined
    lastName: string;
  };
  address?: {
    id?: string;
    addressLineOne?: string;
    addressLineTwo?: string | null;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
};
