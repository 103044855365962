import type { FindAllOrganizationsDto, Organization } from "@cerebruminc/neuron-sdk";
import { useQuery } from "@tanstack/react-query";
import { neuronSdk } from "./getNeuronSdk";

interface OrganizationWithIncludedData extends Organization {
  subOrganizations?: Organization[];
}

export const useGetOrganizationsQuery = (
  data: FindAllOrganizationsDto = {
    orderBy: { createdAt: "desc" },
    include: { logo: true },
  },
) => {
  return useQuery({
    queryKey: ["neuronOrganizations"],
    async queryFn() {
      const res = await neuronSdk.findAllOrganizations(data);
      return res.data as OrganizationWithIncludedData[];
    },
  });
};
