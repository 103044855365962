import type {
  ApplicantFormDataType,
  ApplicantFormDataWithIdType,
  StandardFormDataType,
  StandardFormDataWithIdType,
} from "./../types";

export interface RightSidebarProps {
  $heightLimit: number;
}
export interface FormValues {
  [key: string]: any;
}

export const applicantHasId = (
  applicant: StandardFormDataType | ApplicantFormDataType,
): applicant is StandardFormDataWithIdType | ApplicantFormDataWithIdType => {
  return !!applicant?.id;
};

// ------- CSV Parsing --------

export interface LookupProps {
  [key: string]: string;
}
