// From (modified) https://www.fabrizioduroni.it/2022/01/02/react-detect-scroll-direction/

import { useEffect, useState } from "react";
export enum ScrollDirectionEnum {
  Up = "UP",
  Down = "DOWN",
}
export const useScrollDirection = () => {
  const threshold = 5;
  const [scrollDir, setScrollDir] = useState(ScrollDirectionEnum.Up);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scrollSpeed, setScrollSpeed] = useState(0);
  useEffect(() => {
    let previousScrollYPosition = window.scrollY || 0;
    const scrolledMoreThanThreshold = (currentScrollYPosition: number) => Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;
    const isScrollingUp = (currentScrollYPosition: number) => currentScrollYPosition > previousScrollYPosition && !(previousScrollYPosition > 0 && currentScrollYPosition === 0) && !(currentScrollYPosition > 0 && previousScrollYPosition === 0);
    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY;
      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirectionEnum.Down : ScrollDirectionEnum.Up;
        setScrollDir(newScrollDirection);
        setScrollOffset(currentScrollYPosition);
        setScrollSpeed(Math.abs(currentScrollYPosition - previousScrollYPosition));
        previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
      }
    };
    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);
    window.addEventListener("scroll", onScroll);
    return () => window?.removeEventListener("scroll", onScroll);
  }, []);
  return [scrollDir, scrollOffset, scrollSpeed];
};