import { colors, focusAfter } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import type { AvatarBoxProps, UserControlsBaseProps } from "./types";
interface AbsoluteAfterProps {
  offset?: number;
}
interface FocusAfterProps {
  borderRadius?: number;
  focusColor: string;
  offset?: number;
}
export const UserControlsBase = styled.div<UserControlsBaseProps>`
  align-items: center;
  background: ${({
  $white
}) => $white ? colors.WHITE : "linear-gradient(180deg, #5377ec 0%, #c0aaff 100%)"};
  display: flex;
  gap: 22px;
  height: 100%;
  padding: 0 28px 0 30px;
  pointer-events: ${({
  $clickable
}) => $clickable ? "inherit" : "none"};
  &:focus-visible {
    outline: none;
  }
`;
export const AvatarBox = styled.div<AvatarBoxProps>`
  background-color: transparent;
  height: 50px;
  padding: 0;
  position: relative;
  width: 50px;
`;
export const CaretBox = styled.div<{
  $white?: boolean;
}>`
  border-radius: 50%;
  height: 20px;
  position: relative;
  width: 20px;
  ${({
  $white
}) => focusAfter({
  borderRadius: 50,
  focusColor: $white ? colors.BLUE_70 : colors.WHITE,
  offset: -10
})}

  &:after {
    top: -11px;
    bottom: -9px;
  }
  ${UserControlsBase}:focus-visible & {
    outline: none;
    &:after {
      border: 1px solid
        ${({
  $white
}) => $white ? colors.BLUE_70 : colors.WHITE};
    }
  }
`;