import { CheckboxItem, colors } from "@cerebruminc/cerebellum";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { styled } from "styled-components";
import { TOS_NODE_TYPE } from "src/const";
import { env } from "src/environment";
const ParagraphWrapper = styled.p`
	font-size: 15px;
	letter-spacing: 0.025em;
	line-height: 20px;
`;
const LinkWrapper = styled.a`
	color: ${colors.BLUE_50};
	text-decoration-line: underline;
	font-style: italic;
`;
const CheckboxWrapper = styled.div`
	margin: 8px 0 22px;
`;
function PolicyAcceptanceText() {
  return <ParagraphWrapper>
      I accept and agree to the{" "}
      <LinkWrapper href={env.TERMS_AND_CONDITIONS_URL} rel="noopener noreferrer" target="_blank">
        Terms and Conditions
      </LinkWrapper>{" "}
      and{" "}
      <LinkWrapper href={env.PRIVACY_POLICY_URL} rel="noopener noreferrer" target="_blank">
        Privacy Policy
      </LinkWrapper>
      .<br />
    </ParagraphWrapper>;
}
interface PolicyAcceptanceCheckboxProps {
  control: Control<{
    [key: string]: boolean;
  }, Record<string, unknown>>;
}

// TODO: replace any with a real type. PolicyAcceptanceCheckboxProps doesn't match the type of the control prop.
export function PolicyAcceptanceCheckbox({
  control
}: any) {
  return <Controller name={TOS_NODE_TYPE} control={control} render={({
    field,
    formState
  }) => {
    const isSubmitted = formState.isSubmitted;
    const hasError = Boolean(isSubmitted && field.value === false);
    function getInactiveColor(hasError: boolean) {
      if (hasError) {
        return `${colors.RED_100}`;
      }
      return `${colors.COOL_GREY_50}`;
    }
    const inactiveColor = getInactiveColor(hasError);
    return <CheckboxWrapper>
            <CheckboxItem active={field.value || false} label={<PolicyAcceptanceText />} onClick={() => {
        field.onChange(!field.value);
      }} value="Policy Acceptance Checkbox" labelColor={colors.RED_10} inactiveColor={inactiveColor} />
          </CheckboxWrapper>;
  }} />;
}