import { useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import router from "next/router";
import { paths } from "src/const";
import { inviteApplicantsAtom, selectedPackageIdAtom, selectedPassTypeIdAtom } from "src/views/CreateInviteView/atom";

export const useHandleLogout = () => {
  const queryClient = useQueryClient();
  const setInviteApplicants = useSetAtom(inviteApplicantsAtom);
  const setInvitePackageId = useSetAtom(selectedPackageIdAtom);
  const setInvitePassTypeId = useSetAtom(selectedPassTypeIdAtom);

  const handleLogout = () => {
    // bust the query cache
    queryClient.resetQueries();

    setInviteApplicants(RESET);
    setInvitePackageId(RESET);
    setInvitePassTypeId(RESET);
    localStorage.removeItem("order");

    router.push(paths.logout);
  };

  return handleLogout;
};
