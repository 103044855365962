import { DialogHandler, ConnotationColorEnum, SnackTypeEnum } from "@cerebruminc/cerebellum";
import * as Sentry from "@sentry/nextjs";

export const handleOrderCreationError = (error: unknown) => {
  DialogHandler.show({
    buttonClick: () => window.open("mailto:support@cerebrum.com", "_blank"),
    buttonText: "Contact Support",
    colorFamily: ConnotationColorEnum.Negative,
    snackType: SnackTypeEnum.Dialog,
    title: "Oh no!",
    text: "There was an error creating your order. Please try again.",
    id: "error_creating_order",
  });
  Sentry.captureException(error);
  console.error("Error creating order:", error);
};
