import { BodyMEmphasis, BodyMPrimary, BodySPrimary, HeadingH2, HeadingH3, colors } from "@cerebruminc/cerebellum";
import styled from "styled-components";

// ------- CsvErrorDetailsModal --------
export const ErrorDetailsTitle = styled(HeadingH2)`
  margin-bottom: 20px;
`;
export const ErrorMessage = styled(BodyMPrimary)`
  background-color: ${colors.WHITE};
  border-radius: 6px;
  color: ${colors.RED_100};
  line-height: 27px;
  margin: 5px 0 5px;
  padding: 10px 20px;
  &:nth-child(even) {
    background-color: ${colors.COOL_GREY_4};
  }
`;
export const ErrorName = styled(BodyMEmphasis)`
  color: ${colors.COOL_GREY_100};
  display: inline-block;
  margin-right: 6px;
`;
export const InfoSection = styled.div`
  background-color: ${colors.PURPLE_5};
  border-radius: 10px;
  margin-top: 20px;
  padding: 35px 50px 25px 20px;
`;
export const InfoHeader = styled.div`
  margin-bottom: 40px;
`;
export const IconBox = styled.div`
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 20px;
`;
export const InfoTitle = styled(HeadingH3)`
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
`;
export const Issue = styled.div`
  padding: 0 0 35px 35px;
  line-height: 24px;
`;
export const IssueHeader = styled(BodyMEmphasis)`
  padding-bottom: 8px;
  line-height: 22px;
`;
export const IssueText = styled(BodySPrimary)`
  /* padding-bottom: 15px;
  line-height: 24px; */
`;