import { suffixLookup } from "src/const/suffixLookup";

export type ValidateSuffixResult = { suffix: string; suffixError?: string };

// Returns a sanitized suffix value
export const validateSuffix = (suffix: string): ValidateSuffixResult => {
  if (!suffix) {
    return { suffix, suffixError: undefined };
  }
  let suffixError: any;
  const lowerCaseSuffix = suffix.toLowerCase();
  const cleanSuffix = lowerCaseSuffix.replace(/\.$/, ""); // trim trailing "."
  const validSuffix = suffixLookup[cleanSuffix];
  if (!validSuffix) {
    suffixError = "Invalid Suffix";
  }
  return { suffix: validSuffix, suffixError };
};
