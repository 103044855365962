import { useMeasure } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { windowSizeAtom } from "src/atom";
import { ScrollDirectionEnum, useScrollDirection } from "src/hooks";
import { CENTER_MAX_WIDTH, CenteredMax, TabsBox } from "src/styles/PagesStyles";
import { Background, CardBox, HeaderBase, HeaderBox, RightSideOverlap, StackedCardBox, StickyHeader } from "./ItemLayoutStyles";
const CENTER_MAX_TOP_MARGIN = 17;
export interface CardProps {
  horizontal?: boolean;
}
interface ItemLayoutProps {
  Card: FC<CardProps>;
  Header: FC;
  Tabs: ReactElement;
  children?: ReactNode;
  stackBreakpoint?: number;
}
export const ItemLayout = ({
  Card,
  Header,
  Tabs,
  children,
  stackBreakpoint = 1215
}: ItemLayoutProps) => {
  const {
    width: pageWidth
  } = useAtomValue(windowSizeAtom);
  const [headerRef, headerMeasure] = useMeasure();
  const [showStickyHeader, setShowStickyHeader] = useState(false);

  // screen size variables
  const [contentMeasureRef, contentMeasure] = useMeasure();
  const [scrollDirection, scrollOffset, scrollSpeed] = useScrollDirection();
  const scrollOffsetNumber = Number(scrollOffset);
  const scrollSpeedNumber = Number(scrollSpeed);
  // pageSmallEnough disables scrollUpFast when the page is too tall, which causes some jankiness.
  const pageSmallEnough = contentMeasure?.height && contentMeasure?.height < 4000;
  const scrollUpFast = scrollSpeedNumber > 250 && scrollDirection === ScrollDirectionEnum.Up && pageSmallEnough;
  const stackedLayout = pageWidth < stackBreakpoint;
  useEffect(() => {
    if (scrollDirection === ScrollDirectionEnum.Up && scrollOffsetNumber > 300 && !scrollUpFast) {
      setShowStickyHeader(true);
    } else {
      setShowStickyHeader(false);
    }
  }, [scrollDirection, scrollOffsetNumber, scrollUpFast]);
  return <Background $centerTopMargin={CENTER_MAX_TOP_MARGIN} $headerHeight={headerMeasure?.height || 0} $stackBreakpoint={stackBreakpoint}>
      <CenteredMax $bottomPadding={80} $marginTop={CENTER_MAX_TOP_MARGIN} $stackedLayout={stackedLayout}>
        <CardBox $stackBreakpoint={stackBreakpoint}>
          <Card />
        </CardBox>

        <StickyHeader $centerTopMargin={CENTER_MAX_TOP_MARGIN} $excessMargin={pageWidth > CENTER_MAX_WIDTH ? (pageWidth - CENTER_MAX_WIDTH) / 2 : 0} $headerHeight={headerMeasure?.height || 0} $show={showStickyHeader} $stackBreakpoint={stackBreakpoint}>
          <HeaderBox>
            <HeaderBase $stackedLayout={Boolean(stackedLayout)}>
              <Header />
            </HeaderBase>
          </HeaderBox>
          <TabsBox $stackedLayout={stackedLayout}>
            {React.isValidElement(Tabs) ? React.cloneElement(Tabs) : Tabs}
          </TabsBox>
        </StickyHeader>

        <RightSideOverlap>
          <HeaderBox ref={headerRef}>
            <HeaderBase $stackedLayout={Boolean(stackedLayout)}>
              <Header />
            </HeaderBase>
            <StackedCardBox $stackBreakpoint={stackBreakpoint}>
              <Card horizontal />
            </StackedCardBox>
          </HeaderBox>

          <TabsBox $stackedLayout={stackedLayout}>
            {React.isValidElement(Tabs) ? React.cloneElement(Tabs) : Tabs}
          </TabsBox>

          <div ref={contentMeasureRef}>{children}</div>
        </RightSideOverlap>
      </CenteredMax>
    </Background>;
};