import type { SwitchMenuOptionsType } from "@cerebruminc/cerebellum";
import { roleData } from "src/const";
import { isSwitchType, type RoleType } from "./types";

const roleDetails = {
  SUPER_ADMIN: {
    description: roleData.superAdmin.description,
    name: roleData.superAdmin.name,
  },
  ORGANIZATION_ADMIN: {
    description: roleData.admin.description,
    name: roleData.admin.name,
  },
  USER: {
    description: roleData.user.description,
    name: roleData.user.name,
  },
  INTEGRATION_ADMIN: {
    description: roleData.integrationAdmin.description,
    name: roleData.integrationAdmin.name,
  },
  PROCESSOR: {
    description: roleData.processor.description,
    name: roleData.processor.name,
  },
};

export const getRoleDetails = (baseRoles: Array<RoleType>): Array<SwitchMenuOptionsType> => {
  const detailedRoles = baseRoles
    .map((role) => {
      const details = roleDetails[role.name as keyof typeof roleDetails];
      if (details) {
        const detailedRole: SwitchMenuOptionsType = {
          ...role,
          value: role.name,
          name: details.name,
          description: details.description,
        };
        return detailedRole;
      }

      role.value = role.name;
      if (isSwitchType(role)) {
        return role;
      }
      return undefined;
    })
    .filter((role): role is SwitchMenuOptionsType => !!role);

  return detailedRoles;
};
