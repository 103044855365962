import { ConnotationColorEnum, DialogHandler, SnackTypeEnum, useMediaQuery } from "@cerebruminc/cerebellum";
import type HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import type { LoginFlow, RecoveryFlow, RegistrationFlow, SettingsFlow, VerificationFlow } from "@ory/kratos-client";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTurnstile } from "react-turnstile";
import { type GroupType, analytics, getFormElements, getInitialValues, getValidationSchema } from "src/helpers";
import { OryMessages } from "../OryMessages";
interface OryFormProps {
  // flow contains all the required information to render form and messages
  flow: RegistrationFlow | SettingsFlow | LoginFlow | RecoveryFlow | VerificationFlow;
  // this function will be called when user submit the form
  onSubmit: (values: any) => Promise<any>;
  // this prop is useful to indicate whether form is submitting or not
  submitting: boolean;
  // this prop is usefull to render only specific group inputs, for e.g. if we want to render only totp inputs or if we want to render only password fields etc
  renderOnlyGroup?: GroupType;
  // hide rendering of messages
  hideMessages?: boolean;
  // show only messages, dont render form
  showOnlyMessages?: boolean;
  // override value of isFormDirty variable.
  // this is helpful when ory kratos automatically fills the input and user just need to submit the form
  // one of the example is verification flow where kratos automatically fills verify code
  overrideIsFormDirtyValue?: boolean;
  // Displays a captcha under the submit button
  captcha?: boolean;
  // Display DigitalInput instead of Input when node_type === "text"
  renderDigitalInput?: boolean;
  // this boolean indicate whether to apply custom password validation while rendering the form
  // in sign up we want to apply validation but in login page user can enter any type of password
  // default value is true
  shouldApplyPasswordValidation?: boolean;
  // Display a "Forgot Password?" button below any password inputs
  forgotPasswordButton?: boolean;
  initialValues?: any;
}

/**
 * this component renders Ory flow form
 * it helps in rendering all kinds of form based on what flow we are passing.
 * also based on renderOnlyGroup it will render specific group inputs
 * it uses react-hook-form to render forms
 */
export function OryForm(props: OryFormProps) {
  const {
    captcha,
    flow,
    hideMessages,
    onSubmit,
    overrideIsFormDirtyValue,
    forgotPasswordButton,
    renderOnlyGroup,
    renderDigitalInput,
    shouldApplyPasswordValidation = true,
    showOnlyMessages = false,
    submitting
  } = props;
  const [initalValues, setInitialValues] = useState<{
    [key: string]: string;
  }>(props.initialValues || {});

  // get yup validation schema based on current nodes we are rendering
  const validationSchema = yupResolver(getValidationSchema({
    nodes: flow?.ui?.nodes,
    renderOnlyGroup,
    shouldApplyPasswordValidation
  }));

  // initialized form using useForm hook
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue
  } = useForm({
    defaultValues: initalValues,
    resolver: validationSchema
  });
  const formData = watch();

  // get initial values based on given nodes
  useEffect(() => {
    let values = getInitialValues(flow?.ui?.nodes, renderOnlyGroup);
    if (props.initialValues) {
      values = {
        ...values,
        ...(props.initialValues || {})
      };
    }
    setInitialValues(values);
  }, [flow, props.initialValues, renderOnlyGroup]);

  // here we are calling reset method of useForm whenever initialValues changes so that we can sync with form's initial values
  useEffect(() => {
    reset(initalValues);
  }, [initalValues, reset]);
  const isFormDirty = typeof overrideIsFormDirtyValue !== "undefined" ? overrideIsFormDirtyValue : !isEqual(formData, initalValues);

  // Captcha
  const {
    cogTurnstile,
    neuronWebCaptcha
  } = useFlags();
  const turnstile = useTurnstile();
  const captchaRef = useRef<HCaptcha>(null); // We need the ref to clear the captcha on submit
  const compactCaptcha = useMediaQuery(420);
  const onSubmitClearCaptcha = async (formData: any) => {
    await onSubmit(formData);
    if (neuronWebCaptcha) {
      if (cogTurnstile) {
        turnstile?.reset();
      } else {
        captchaRef?.current?.resetCaptcha();
      }
    }
    setValue("captchaResponse", "");
  };
  const onCaptchaFail = (error: string) => {
    analytics.track("Cloudflare Error");
    DialogHandler.show({
      buttonClick: () => window.open("https://cerebrum.com/contact", "_blank"),
      buttonText: "Contact Support",
      colorFamily: ConnotationColorEnum.Negative,
      snackType: SnackTypeEnum.Dialog,
      text: "Our system thinks you're a robot. Please check your browser extensions, try again in an incognito window, restart your computer, or contact support.",
      title: "Oh no!"
    });
  };

  /**
   * renders all types of messages whether its error message after form submission or information messages
   * @returns node | null
   */
  const renderMessages = () => {
    if (hideMessages) return null;
    return <div className="text-center" id="form-messages">
        {!submitting && <OryMessages messages={flow?.ui?.messages} />}
      </div>;
  };

  /**
   * render all types of nodes/inputs of form
   * @returns node | null
   */
  const renderForm = () => {
    if (showOnlyMessages) return null;
    return <div className="flex flex-col w-full mt-3">
        {getFormElements({
        captcha: neuronWebCaptcha && captcha,
        captchaRef,
        compactCaptcha,
        control,
        forgotPasswordButton,
        isFormDirty,
        nodes: flow?.ui?.nodes,
        onCaptchaFail,
        onCaptchaVerify: (token: string) => {
          setValue("captchaResponse", token);
        },
        renderDigitalInput,
        renderOnlyGroup,
        submitting,
        onSubmit: () => {
          onSubmit(formData);
        }
      })}
      </div>;
  };
  return <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmitClearCaptcha)}>
      {/* this renders all types of messages whether its error message after form submission or information messages */}
      {renderMessages()}
      {/* this will render all types of nodes/inputs of form */}
      {renderForm()}
    </form>;
}