import {
  ConnotationColorEnum,
  DialogHandler,
  type DialogHandlerPropsType,
  SnackTypeEnum,
} from "@cerebruminc/cerebellum";

export const showToast = ({
  colorFamily = ConnotationColorEnum.Neutral,
  snackType = SnackTypeEnum.Toast,
  text,
  title,
}: DialogHandlerPropsType) => {
  DialogHandler.show({
    colorFamily,
    snackType,
    text,
    title,
  });
};
