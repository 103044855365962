import type { IconType, SwitchMenuOptionsType } from "@cerebruminc/cerebellum";
import type { User as UserType } from "@cerebruminc/neuron-sdk";
export interface InviteOrEditModalType {
  /** A Cerebellum icon for the button */
  ButtonIcon?: IconType;
  /** Text for the button in the upper right */
  buttonText?: string;
  /** A title for the modal when in edit mode */
  editTitle?: string;
  /** Causes a button to appear in the upper right of the modal */
  onButtonClick?: () => void;
  /** Called when an invite is sent, or when a user it edited */
  onSuccess?: () => void;
  /** Determines if the modal is visible or not */
  show: boolean;
  /** Called when an error happens */
  onError?: (errorMessage: string) => void;
  /** Text for the submit button */
  submitText?: string;
  /** A title for the modal */
  title?: string;
  /** The user object. This puts the modal into edit user mode */
  user?: UserType;
  /** displays an X in the modal, and is called on click. Also called on a backgound click */
  onXClick?: () => void;
}
export interface RoleType {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
  value?: string;
}
export interface RoleSwitchType extends SwitchMenuOptionsType {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
}
export const isSwitchType = (obj: RoleType): obj is RoleSwitchType => {
  return !!obj.value;
};